/* CSS Document */

// @import url(https://fonts.googleapis.com/css?family=Open+Sans);
// @import url(https://fonts.googleapis.com/css?family=Bree+Serif);

// body {
// 	background: #212121;
// 	font-size:22px;
// 	line-height: 32px;
// 	color: #ffffff;
// 	word-wrap:break-word !important;
// 	font-family: 'Open Sans', sans-serif;
// 	}

// h1 {
//   font-size: 60px;
//   text-align: center;
//   color: #fff;
// }

// h3 {
//   font-size: 30px;
//   text-align: center;
//   color: #fff;
// }

// h3 a {
//   color: #fff;
// }

// a {
//   color: #fff;
//   display: block;
//   // padding: 0 10px;
//   color: #fff;
//   font-size: 16px;
//   line-height: 30px;
//   text-decoration: none;
// }



// #container {
//   margin: 0 auto;
// }

// p {
//   // text-align: center;
// }

// nav {
//   margin: 10px 0px;
//   //   background-color: #e64a19;
// }

nav ul {
  display: flex;
  grid-gap: 50px;
  // justify-content: center;

  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

// nav ul li {
//   // display: inline-block;
//   //   background-color: #e64a19;
// }

// nav a {
//   display: block;
//   // padding: 0 10px;
//   color: #fff;
//   font-size: 20px;
//   line-height: 30px;
//   text-decoration: none;
// }

// .bottom-border:hover {
//   border-bottom: 3px solid #bb1ccc;
//   color: white;
//   font-weight: bold;
//   // position: absolute;
//   // border-bottom: 2px solid #bb1ccc;
//   //   background-color: black;
//   //   width: 175px;
// }

// .border{
//   border-bottom: 3px solid #bb1ccc;
// }

/* Hide Dropdowns by Default */
// nav ul ul {
//   display: none;
//   position: absolute;
//   top: 60px; /* the height of the main nav */
// }

/* Display Dropdowns on Hover */
// nav ul li:hover > ul {
//   display: inherit;
// }

/* Fisrt Tier Dropdown */
// nav ul ul li {
//   width: auto;
//   float: none;
//   display: list-item;
//   position: relative;
//   background-color: black;
// }

/* Second, Third and more Tiers	*/
// nav ul ul ul li {
//   position: relative;
//   top: -60px;
//   left: 170px;
// }

/* Change this in order to change the Dropdown symbol */
// li > a:after {
//   content: " ";
// }
// li > a:only-child:after {
//   content: "";
// }



.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
  outline: 0;
  left: 0;
  background-color: #000000;
  width: 100%;
  position: absolute;
  color: white;
}

.aays-logo{
    width: auto;
    height: 50px;
}

.top-header{
    display: flex;
    justify-content: space-between;
}


.mouse-pointer{
  cursor: pointer;
}

// Link{
//   display: block;
//   // padding: 0 10px;
//   color: #fff;
//   font-size: 20px;
//   line-height: 30px;
//   text-decoration: none;
// }

@media only screen and (min-width: 1024px) {
  a:hover {
    color: #bb1ccc;
    font-weight: bold;
  }
}


