.portal-main{

    .portal-body{
        min-height: 90vh;
        padding-top: 5rem;
        background-color: black;
        
    }
}

.product-main{
    padding: 5rem 2rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;

   .main-product-box{
        height: 350px;
        position: relative;
        z-index: 1;

         .circle{
            position: absolute;
            height: 80px;
            width: 80px;
            background-color: #bb1ccc;
            border-radius: 50%;
            border: 5px solid rgb(202, 194, 194);
            left: 50%;
            top: -10%;
            transform: translateX(-50%);
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;

        }

     .product-body{
        height: 300px;
        width: 300px;
        background-color: white;
        border-radius: 30px;
        padding: 20px;
        border: 5px solid rgb(202, 194, 194);
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        

       .product-details{
        text-align: center;

        // padding: 10px 25px;

         .product-heading{
            font-size: 24px;
            color: rgb(109, 107, 107);

        }
        .product-content{
            font-size: 16px;
            color: gray;
            padding-top: 10px;
        }
        .view-button{
             a{
                    color: black;
                }
                a:hover{
                    font-weight: normal !important;
                }
            margin-top: 1rem;
            button{
                background-color: black;
                color: rgb(213, 200, 200);
                padding: 5px 25px;
                border: none;
                outline: none;
                font-weight: bold;
                font-size: 16px;
                border-radius: 5px;
                
               

                
            }
        }

        
       }

        .triangle{
            position: relative;
            height: 150px;
            width: 100px;
            background-color: #bb1ccc;
            transform: rotate(45deg);
            position: absolute;
            bottom: -20%;
            right: -42px;
        }

       
        
    }

   }
}
