@media only screen and (min-width: 280px) and (max-width: 767px) {
#keypoints{

    .top{
        .image{
            display: none;
        }
         .heading-line{
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            gap: 10px;

            .heading{
                font-size: 20px;
            }
            .line{
                width: 200px;
                height: 2px;
                background-color: #bb1ccc;
            }
        }
    }


    .bottom{
        font-size: 16px;
        padding: 10px 0 0 0;

    }
}




}