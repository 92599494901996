.Blog-reuseSectionBox {
  // background-color: pink;
  width: 280px;
  display: grid;
  img {
    height: 250px;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .Blog-content-box-section2 {
    background-color: grey;
    height: 200px;
    margin-top: 0 !important;

    span {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      font-weight: 600;
      font-size: 24px;
      padding: 12px 0;
      margin-top: 0 !important;
    }
  }

  .Blog-btn-read-more {
    display: grid;
    justify-content: center;
    padding-top: 2rem;
    margin-bottom: 1rem;
  }
  .Blog-btn-read-more > div {
    background-color: #bb1ccc;
    padding: 8px;
    border-radius: 6px;
    font-weight: 600;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.feature-box {
  color: white;
  text-align: center;
  justify-content: center;
  display: grid;
  flex-direction: column;
  gap: 20px;
  justify-items: center;
}

.blog-section1-main {
  background: #212121;
  position: relative;
  padding: 2rem 0rem 2rem;

  .blog-section1-body {
    display: flex;
    grid-gap: 10px;
    justify-content: center;
    position: relative;
    padding: 3.5rem 0;
    gap: 3rem;

    .icon-box {
      display: grid;
      justify-content: center;
      justify-items: center;
      gap: 20px;
      align-items: flex-start;
      align-content: baseline;

      .image-div {
        color: white;
        text-align: center;
        display: grid;
        justify-content: center;
        border-radius: 100%;
        align-items: center;
        align-content: center;
        width: 120px;
        height: 120px;
        border: 2px solid #eee;

        &:hover {
          background-color: white;
          cursor: pointer;
          transition: 2s;
        }

        .feature-icon {
          width: 70px;
          height: 70px;
        }
      }
      .text {
        color: white;
        text-align: center;
        display: grid;
        justify-content: center;
        font-size: 16px;
      }
    }
  }
}

.blog-success-section2 {
  background-color: black;
  color: white;
  padding: 3rem 0;

  .success-story-page-section-2-grid {
    grid-gap: 40px;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.success-story-page-section-3-grid-items:hover {
  .reuseBoxHover {
    background-color: #bb1ccc;
  }
}

#section5-blog-success-story .react-multi-carousel-list {
  height: auto !important;
  // background-color: #bb1ccc;
}

#bg-black {
  background-color: black;
}

.btn-read-more {
  #bg-black:hover {
    background-color: #000000;
  }
}
