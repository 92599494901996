.mainssss {
  background-color: #141414;
  width: 100%;
  position: relative;
  z-index: 1;
  color: white;
  display: flex;
  padding: 50px;
  
  justify-content: space-between;
  
  flex-wrap: wrap;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(128, 128, 128, 0.5);
}
.footer-details{
  font-weight: bolder;
  font-size: 16px;
  padding-bottom: 3px;
  
  border-bottom: 2px solid #F000F2;
  display: inline-block; 
}
.resour{
  margin-top: 26px;
  font-weight: bolder;
  font-size: 16px;
}
.insta{
  display: flex;
}
.list-no-style li{
  margin-top: 7px;
  list-style-type: none;
  font-size: 14px;

  
}
.list-no-style{
  padding: 0;
  margin: 0;
}
.folow{
  font-weight: bolder;
  margin-top: 26px;
}

.footer-section {
  flex: 1 1 17%;
  padding: 10px;
  box-sizing: border-box;
}

@media (max-width: 1248px) {
  .footer-section {
    flex: 1 1  48%;
  }
}

@media (max-width: 672px) {
  .footer-section {
    flex: 1 1 35%;
    
   
  }
}
@media (max-width: 576px) {
  .footer-section {
    flex: 1 1  100%;
    
   
  }
  .copyright{
    padding-left: 14px!important;
    padding-right: 15px!important;
  }
}
@media (max-width: 740px){
  .mainssss{
    padding: 20px;
  }
}



  .links{
   margin-bottom: 13px;
  }


 
 
 .logoss{
  margin-left: -16px;
  margin-top: -12px;
 }
 
 .cursor{
  cursor: pointer;
 }
 .copyright{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  gap: 20px;
  background-color: #000000;/* Optional: Add some padding */
  color: white;
  position: relative;
  z-index: 1;
}

.copyright p {
  margin: 0;
  font-size: 12px;
  padding-left: 10px 
}
.copyright{
  font-size: 12px;

}


.policys{
  font-size: 14px;
}
.innovation{
  font-size: 14px;
}


.first-imagesss {
  display: flex;
  align-items: flex-start;
  margin-left: -2px;
  padding-top: 8px;
}

//.imagesss img {//
  //height: auto;//
  //width: 94%;//
//}//

//@media (max-width: 340px) {//
  //.imagesss {//
    //flex-wrap: wrap;//
    //justify-content: center;//
  //}//

  //.imagesss img {//
    //margin-top: 10px;// 
  //}//
//}//
.insta-iconss{
  margin-right: 5px;
  width: 32px;
  height: 33px;
  margin-top: 8px;
}
.link-iconss{
  width: 26px;
  height: 26px;
  margin-top: 7px;

}
.high-growth{
  height: 61px;
  width: 229px;
   object-fit:contain;
   margin-top: 24px;
   padding-left: 4px;;

}
.second-imgs .best-firmss{
 height: 145px !important;
  width: 100px;
  
}
//.great-placess{//
  //height: 121px  !important;//
 // object-fit: contain;//
  //width: 87px;//
  //margin-top: 11px;//
//}//
.second-imgs{
  display: flex;
  margin-top: 15px;
}
.second-imgs .great-placess {
  height: 121px !important;  /* Override any inherited height */
  width: 87px;               /* Specify width if needed */
  margin-top: 13px;          /* Specify margin-top if needed */
}
