.openposition-main-box {
  background-color: black;

  .position-body {
    padding: 6rem 0;
    width: 70%;
    display: block;
    margin: auto;

    .heading1 {
      padding-bottom: 5rem;
      span {
        font-size: 30px;
        font-weight: bold;
        color: white;
        padding-bottom: 5rem;
      }
    }

    .flex-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      color: white;
      border-bottom: 3px solid #212121;
      padding-bottom: 1rem;

      .left {
        .heading {
          span {
            font-size: 30px;
            font-weight: bold;
            color: #bb1ccb;
            margin: 0;
          }
        }
        .experience {
          span {
            font-size: 18px;
            margin: 0;
            font-style: italic;
          }
        }
      }

      .right {
        button {
          outline: none;
          color: white;
          background-color: #bb1ccc;
          padding: 8px 10px;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
}

.openPosition-box {
  .position-main {
    background-color: black;

    padding: 3rem 0;
    color: white;
    .position-body {
      width: 80%;
      display: block;
      margin: auto;
      border: 1px solid #201d1d;
      //border-bottom: none; 
      .heading {
        padding-bottom: 1rem;
        span {
          b {
            font-size: 30px;
          }
        }
      }

      .subheading {
        font-size: 24px !important;
        text-decoration: underline;
      }

      .top {
        padding-bottom: 1rem;
        .heading {
          padding-bottom: 0.5rem;
          span {
            b {
              font-size: 30px;
              color: white;
            }
          }
        }

        .position,
        .desired {
          span {
            font-size: 20px;
            font-weight: bold;
            font-style: italic;
          }
        }
      }
      .heading1 {
        // padding: 1rem 0;
        .heading {
          padding-bottom: 1rem;
          span {
            b {
              font-size: 20px;
              color: white
            }
          }
        }

        .content {
          font-size: 14px;
          color: white;
          line-height: 1.5;
        }
      }
      .heading2-list {
        padding: 1rem 0;
        .heading {
          padding-bottom: 1rem;
          padding-top: 1rem;
          span {
            font-size: 20px;
            font-weight: bold;
          }
        }

        .list {
          ul {
            padding: 1;
            li {
              font-size: 14px;
              padding-bottom: 5px;
            }
          }
        }
      }

      .apply-now {
        padding: 20px 20px 20px 0px;
        button {
          border: none;
          outline: none;
          font-size: 16px;
          font-weight: bold;
          background-color: #bb1ccb;
          padding: 8px 10px;
          color: white;
        }
      }
    }
  }
}

// resposiveness of position pages -->
@media only screen and (min-width: 280px) and (max-width: 767px) {
  .position-box {
    .layoutGrid1 {
      background: url("../../assets/img/mobile-banner-automative.png") !important;
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
    }

    .section2Text {
      span {
        font-size: 18px !important;
      }
    }

    .heading-supply-chain {
      font-size: 20px !important;
    }
    .header-section > div {
      grid-template-columns: none !important;
      // background: #bb1ccc !important;
    }
    .header_section_1 {
      flex-direction: row !important;
      justify-content: flex-start !important;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      align-items: flex-end !important;

      .firstText {
        font-size: 22px !important;
        line-height: 32px;
      }
    }

    .position-main {
      .position-body {
        padding: 2rem 0;

        .heading1 {
          padding-bottom: 2rem;
        }

        .flex-container {
          flex-wrap: wrap !important;

          .left {
            .heading {
              span {
                font-size: 20px !important;
              }
            }
          }

          .right {
            padding-top: 1rem;
          }
        }
      }
    }
  }

  .openPosition-box {
    .layoutGrid1 {
      background: url("../../assets/img/mobile-banner-data-eng.webp") !important;
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
    }

    .back-button {
      .content{
        font-size: 18px !important;
      }
    }

    .section2Text {
      span {
        font-size: 18px !important;
      }
    }

    .heading-supply-chain {
      font-size: 20px !important;
    }
    .header-section > div {
      grid-template-columns: none !important;
      // background: #bb1ccc !important;
    }
    .header_section_1 {
      flex-direction: row !important;
      justify-content: flex-start !important;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      align-items: flex-end !important;

      .firstText {
        font-size: 22px !important;
        line-height: 32px;
      }
    }

    .position-main {
      .position-body {
        width: 90% !important;

        .heading {
          span {
            b {
              font-size: 20px;
            }
          }
        }

        .subheading {
          font-size: 18px !important;
        }

        .top {
          .heading {
            span {
              b {
                font-size: 20px;
              }
            }
          }

          .position,
          .desired {
            span {
              font-size: 16px;
            }
          }
        }

        .heading1 {
          padding: 0;
          .heading {
            padding: 0 !important;
            span {
              b {
                font-size: 20px;
              }
            }
          }
          .content {
            font-size: 16px;
            padding-top: 0.3rem !important;
          }
        }

        .heading2-list {
          padding: 0;
          .heading {
            span {
              font-size: 20px;
            }
          }

          .list {
            li {
              font-size: 16px !important;
            }
          }
        }
      }
    }

    // openPosiionBox css ends
  }

  //media query end bracket.
}

.desired{
  padding-top:1rem;
}
.list{
  line-height: 1.5
}
.new-box-seventy{
  width: 70%;
  display: block;
  margin: auto;
}
.para-global{
  font-size: 14px;
}
.locationss{
  font-size: 16px;
  font-weight: 500;
  padding-top: 6px;
}