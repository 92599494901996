#accolades{
    min-height: auto;
    background-color: #000;
    //position: relative;//
    padding-top: 4rem;

    .main{
        display: block;
        margin: auto;
        width: 90%;
        padding: 4rem 0;

        .heading-main{
            font-size: 30px;
            color: white;
            font-weight: 700;
            text-align: center;
            padding-bottom: 5rem;
        }
        .box{
            display: flex;
            justify-content: center;
            gap: 2rem;
            flex-wrap: wrap;
        }
       .accolades-center{
        display: flex;
        justify-content: center;
        align-items: center;

         .accolades-container{
            display: grid;
            gap: 2rem;
            // justify-content: center;
            grid-template-columns: repeat(4, 1fr);
        }
       }

    }

    .home-main{
        display: block;
        margin: auto;
        width: 80%;
        padding: 4rem 0;

        .heading-main{
            font-size: 30px;
            color: white;
            font-weight: 700;
            text-align: center;
            padding-bottom: 5rem;
        }
        .box{
            display: flex;
            justify-content: center;
            gap: 2rem;
            flex-wrap: wrap;
        }
       .accolades-center{
        display: flex;
        justify-content: center;
        align-items: center;

         .accolades-container{
            display: grid;
            gap: 2rem;
            // justify-content: center;
            grid-template-columns: repeat(4, 1fr);
        }
       }

    }
}

#accolades-box{
    display: grid;
    justify-items: center;
    text-align: center;

        width: 270px;
        height: 290px;
        color: white;
    .image{
        height: 200px;
        width: 250px;
        background-color: #181818;
    
        img{
            height: 100% !important;
            width: 100%;
            object-fit: contain;
            padding: 10px;
        }
    }
    .heading{
        font-size: 14px;
        text-align: center;
        // font-weight: 700;
        margin-top: 2px;
    }
    .description{
        font-size: 16px;
        text-align: center;
        margin-top: 16px;

    }
}

@media only screen and (min-width: 280px) and (max-width: 767px) {
    #accolades{
        min-height: auto;
        .main{
            .heading-main{
                font-size: 20px;
            }
        }
    }
    #accolades-box{
        .heading{
            font-size: 12px;
        }
    }
}
@media only screen and (min-width: 767px) and (max-width: 924px) {
      #accolades{
        min-height: auto;
        .main{
            .heading-main{
                font-size: 20px;
            }
        }
    }
    #accolades-box{
        .heading{
            font-size: 14px;
        }
    }
}

.custom-dot-list-styles {
    position: absolute;
    bottom: -1px; /* Move the dots below the carousel */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    z-index: 10; /* Ensure the dots are visible */
  }

  .carousel-container {
    position: relative;
    padding-bottom: 30px; /* Add space at the bottom if necessary to ensure dots are visible */
  }