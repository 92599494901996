.contact-padding {
  padding: 3rem 0;
  position: relative;
  .main-heading-text,
  h1 {
    padding-bottom: 3rem;
    font-weight: bold;
  }
}

.contactUs-section1 {
  //   background-color: pink;

  h1 {
    font-size: 30px;
    text-align: center;
    width: 80%;
    display: block;
    margin: auto;
  }

  .contact-flexContainer {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    padding: 3rem 0;

    .contact-flexLeft {
      flex: 35%;
      padding: 0px 10px 0px 10px;
      text-align: left;
      // border-right: 2px solid white;

      .icon {
        width: 100px;
        margin: auto;
      }

      .title {
        color: white;
        font-size: 18px;
        font-weight: bold;
        color: #bb1ccc;
      }

      .content {
        font-size: 14px;
        padding-top: 0px;
      }
    }

    .contact-flexRight {
      flex: 60%;

      .input-field-landing {
        width: 100%;
        padding: 14px;
        font-size: 15px;
        outline: none;
        border: 2px solid #bb1ccc;
        border-left: 5px solid #bb1ccc;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

   

      /* Set a style for the submit button */

      .btn-read-more {
        display: grid;
        margin: auto;
        // padding-top: 2rem;
      }
      .btn-read-more > button {
        background-color: #bb1ccc;
        padding: 8px;
        font-weight: 600;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 16px;
        font-family: Montserrat;
        cursor: pointer;
      }
    }
  }
}

.section2-main {
  .main-heading-text {
    text-align: center;
    font-size: 30px;
  }

  .background-box {
    padding-bottom: 5rem;

    .location-background {
      position: relative;
      margin-bottom: 50px;

      .locations {

        display: flex;
        justify-content: center;
        margin-top: 2rem;


        .locations-flexContainer {
          display: grid;
          grid-template-columns: repeat(4, 25%);
          justify-content: center;
          text-align: center;
          background-color: #bb1ccc;
          width: 69%;
          padding: 2rem 1rem;
          border-radius: 20px;
          position: absolute;
          // margin: auto;
          // display: flex;
          bottom: -90px;
          // left: 180px;

          .heading {
            font-size: 18px;
            margin-top: 0px;
            line-height: 18px;
            max-height: 20px;
            padding-bottom: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            font-weight: bold;
          }

          .content {
            font-size: 14px;
          }

          .container1 {
            padding: 0 24px;
         
            
          }
           .container4 {
            padding: 0 20px;
            border-right: 2px solid white;
          }
          .container2 {
            border-left: 2px solid white;
            border-right: 2px solid white;
            padding: 0 20px;
          }

          .container3 {
            padding: 0 20px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 280px) and (max-width: 767px) {
  .contactUs .section2-main .background-box .location-background .locations .locations-flexContainer .container4{
    border-bottom: 1px solid white;
    border-right: none;
    padding-bottom: 6px;

  }
  .section2-main .background-box .location-background .locations .locations-flexContainer .container1{
    padding: 15px 19px;

  }
  .section2-main .background-box .location-background .locations .locations-flexContainer .container2{
    padding: 15px 19px;

  }
  .section2-main .background-box .location-background .locations .locations-flexContainer .container3{
    padding: 15px 19px;

  }
}


@media (min-width: 767px) and (max-width: 950px) {
  .locations-flexContainer {
    width: 90% !important;
  }
}