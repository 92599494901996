@media only screen and (min-width: 280px) and (max-width: 767px) {
  #aadi-main {
    .aadi-section1 {
      background: url("../../assets/img//Aadi/banner-gif-firstSection.gif")
        no-repeat center;
      background-size: cover;

      .main {
        justify-content: flex-end !important;

        .flex-container {
          flex-direction: column-reverse;

          .left {
            width: 100%;
            position: relative;

            .contentBox {
              width: 300px;
              position: absolute;
              bottom: 50px;

              .text {
                .content {
                  .aadi-name {
                    line-height: 25px;
                    padding-bottom: 10px;
                    display: block;
                  }
                  font-size: 18px;
                  line-height: 20px;
                }
              }
              .buttons {
                width: auto;
                justify-content: flex-start;
                gap: 20px;

                button {
                  width: 130px;
                  height: 36px;
                }
              }
            }
          }
          .right {
            display: none;
          }
        }
      }
    }

    .aadi-section2 {
      height: auto;
      padding: 4rem 0;

      .main {
        .flex-container {
          .left {
            width: 100%;
            display: none;
          }
          .right {
            width: 100%;
            display: block;
            min-height: auto;

            .subHeading {
              font-size: 20px;
              line-height: 25px;
              margin-bottom: 10px;
            }
            .text {
              font-size: 14px;
            }
          }
        }
      }
    }
    .aadi-newSection {
      padding-bottom: 0;
      padding: 2rem 0;
      background-color: #212121;
      .main {
        width: 90%;
        .heading,
        .subHeading {
          font-size: 20px;
          line-height: 25px;
        }
        .text1 {
          font-size: 14px;
        }
        img {
          display: none;
        }
      }
    }

    .aadi-section3 {
      padding: 0;
      .main {
        padding: 2rem 0;
        width: 90%;
        .heading {
          font-size: 20px;
          text-align: left;
        }
        .text {
          width: 100%;
          font-size: 14px;
          line-height: 21px;
          text-align: left;
        }
        .flex-container {
          flex-direction: column-reverse;
          height: auto;
          .left {
            width: 100%;
            .accordian-file {
              width: 100%;

              .accordian-title {
                font-size: 14px;
              }
              .accordian-description {
                font-size: 12px;
              }
            }
          }
          .right {
            width: 100%;
            // border-radius: 0px;
            justify-content: left;
            height: auto !important;
            border-radius: 0px !important;

            img {
              width: 330px;
            }
          }
        }
      }
    }

    .aadi-section4 {
      background-color: black;
      background-image: none;
      padding: 0;
      .main {
        padding: 2rem 0;
        width: 90%;
        .heading {
          font-size: 20px;
          text-align: left;
        }
        .text {
          width: 100%;
          font-size: 14px;
          line-height: 21px;
          text-align: left;
        }
        .flex-container {
          flex-direction: column;
          height: auto;
          .left {
            width: 100%;
            .accordian-file {
              width: 100%;

              .accordian-title {
                font-size: 14px;
              }
              .accordian-description {
                font-size: 12px;
              }
            }
          }
          .right {
            width: 100%;
          }
        }
      }
    }

    .aadi-section5 {
      padding: 0;
      .main {
        padding: 2rem 0;
        width: 90%;
        .heading {
          font-size: 20px;
          text-align: left;
        }
        .text {
          width: 100%;
          font-size: 14px;
          line-height: 21px;
          text-align: left;
        }
        .flex-container {
          flex-direction: column-reverse;
          height: auto;
          .left {
            width: 100%;
            .accordian-file {
              width: 100%;

              .accordian-title {
                font-size: 14px;
              }
              .accordian-description {
                font-size: 12px;
              }
            }
          }
          .right {
            width: 100%;
            // border-radius: 0px;
            justify-content: left;
            height: auto !important;
            border-radius: 0px !important;

            img {
              width: 330px;
            }
          }
        }
      }
    }

    .aadi-section7 {
      position: relative;
      height: auto !important;
      padding: 1rem 0;
      background-color: #212121;
      background-image: none;
      z-index: 2;

      .main {
        padding: 0;

        .heading {
          font-size: 20px;
          font-weight: bold;
          padding-top: 0px;
        }

        .badge-box {
          width: 90%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          justify-items: center;
          text-align: center;
          padding: 1rem 0 0 0;
          .image {
            img {
              width: 130px;
            }
          }
          .badge-heading {
            font-size: 30px;
            font-weight: bold;
            padding: 0.5rem 0;
          }
          .content {
            padding-top: 0.5rem;
            position: relative;
            z-index: 1;
            font-size: 14px;
          }
          .read-btn {
            margin-top: 1rem;
            display: grid;
            justify-content: center;
            position: relative;
            z-index: 1;

            button {
              background-color: #bb1ccc;
              padding: 8px;
              font-weight: 600;
              border: none;
              font-size: 12px;
              padding: 8px 16px;
              color: #ffffff;
            }
          }
        }
      }
    }

    .aadi-section8 {
      padding-bottom: 0rem;
    }

    .aadi-section9 {
      min-height: auto;

      .main {
        padding: 2rem 0;

        .heading {
          font-size: 20px;
          padding: 1rem 0;
        }

        .flex-container {
          flex-direction: column;
          .left,
          .right {
            width: 100%;

            .accordian-file {
              .accordian-title {
                font-size: 14px;
              }
              .accordian-description {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

// for ipad
@media only screen and (min-width: 767px) and (max-width: 924px) {
  #aadi-main {
    .aadi-section1 {
      background: url("../../assets/img//Aadi/banner-gif-firstSection.gif")
        no-repeat center;
      background-size: cover;

      .main {
        justify-content: flex-end !important;

        .flex-container {
          flex-direction: column-reverse;

          .left {
            width: 100%;
            position: relative;

            .contentBox {
              width: 600px;
              position: absolute;
              bottom: 50px;

              .text {
                .content {
                  .aadi-name {
                    line-height: 25px;
                    padding-bottom: 10px;
                    display: block;
                  }
                  font-size: 18px;
                  line-height: 20px;
                }
              }
              .buttons {
                width: auto;
                justify-content: flex-start;
                gap: 20px;

                button {
                  width: 220px;
                  height: 55px;
                }
              }
            }
          }
          .right {
            display: none;
          }
        }
      }
    }

    .aadi-section2 {
      height: auto;
      padding: 4rem 0;

      .main {
        .flex-container {
          .left {
            width: 100%;
            display: none;
          }
          .right {
            width: 100%;
            display: block;
            min-height: auto;

            .subHeading {
              font-size: 20px;
              line-height: 25px;
              margin-bottom: 10px;
            }
            .text {
              font-size: 14px;
            }
          }
        }
      }
    }
    .aadi-newSection {
      padding-bottom: 0;
      padding: 2rem 0;
      background-color: #212121;
      .main {
        width: 90%;
        .heading,
        .subHeading {
          font-size: 20px;
          line-height: 25px;
        }
        .text1 {
          font-size: 14px;
        }
        img {
          display: block;
        }
      }
    }

    .aadi-section3 {
      padding: 0;
      .main {
        padding: 2rem 0;
        width: 90%;
        .heading {
          font-size: 20px;
          text-align: left;
        }
        .text {
          width: 100%;
          font-size: 14px;
          line-height: 21px;
          text-align: left;
        }
        .flex-container {
          flex-direction: column-reverse;
          height: auto;
          .left {
            width: 100%;
            .accordian-file {
              width: 100%;

              .accordian-title {
                font-size: 14px;
              }
              .accordian-description {
                font-size: 12px;
              }
            }
          }
          .right {
            width: 100%;
            // border-radius: 0px;
            justify-content: left;
            height: auto !important;
            border-radius: 0px !important;

            img {
              width: 330px;
            }
          }
        }
      }
    }

    .aadi-section4 {
      background-color: black;
      background-image: none;
      padding: 0;
      .main {
        padding: 2rem 0;
        width: 90%;
        .heading {
          font-size: 20px;
          text-align: left;
        }
        .text {
          width: 100%;
          font-size: 14px;
          line-height: 21px;
          text-align: left;
        }
        .flex-container {
          flex-direction: column;
          height: auto;
          .left {
            width: 100%;
            .accordian-file {
              width: 100%;

              .accordian-title {
                font-size: 14px;
              }
              .accordian-description {
                font-size: 12px;
              }
            }
          }
          .right {
            width: 100%;
          }
        }
      }
    }

    .aadi-section5 {
      padding: 0;
      .main {
        padding: 2rem 0;
        width: 90%;
        .heading {
          font-size: 20px;
          text-align: left;
        }
        .text {
          width: 100%;
          font-size: 14px;
          line-height: 21px;
          text-align: left;
        }
        .flex-container {
          flex-direction: column-reverse;
          height: auto;
          .left {
            width: 100%;
            .accordian-file {
              width: 100%;

              .accordian-title {
                font-size: 14px;
              }
              .accordian-description {
                font-size: 12px;
              }
            }
          }
          .right {
            width: 100%;
            // border-radius: 0px;
            justify-content: left;
            height: auto !important;
            border-radius: 0px !important;

            img {
              width: 330px;
            }
          }
        }
      }
    }

    .aadi-section7 {
      position: relative;
      height: auto !important;
      padding: 1rem 0;
      background-color: #212121;
      background-image: none;
      z-index: 2;

      .main {
        padding: 0;

        .heading {
          font-size: 20px;
          font-weight: bold;
          padding-top: 0px;
        }

        .badge-box {
          width: 90%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          justify-items: center;
          text-align: center;
          padding: 1rem 0 0 0;
          .image {
            img {
              width: 130px;
            }
          }
          .badge-heading {
            font-size: 30px;
            font-weight: bold;
            padding: 0.5rem 0;
          }
          .content {
            padding-top: 0.5rem;
            position: relative;
            z-index: 1;
            font-size: 14px;
          }
          .read-btn {
            margin-top: 1rem;
            display: grid;
            justify-content: center;
            position: relative;
            z-index: 1;

            button {
              background-color: #bb1ccc;
              padding: 8px;
              font-weight: 600;
              border: none;
              font-size: 12px;
              padding: 8px 16px;
              color: #ffffff;
            }
          }
        }
      }
    }

    .aadi-section8 {
      padding-bottom: 0rem;
    }

    .aadi-section9 {
      min-height: auto;

      .main {
        padding: 2rem 0;

        .heading {
          font-size: 20px;
          padding: 1rem 0;
        }

        .flex-container {
          flex-direction: column;
          .left,
          .right {
            width: 100%;

            .accordian-file {
              .accordian-title {
                font-size: 14px;
              }
              .accordian-description {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
