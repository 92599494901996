@media only screen and (min-width: 280px) and (max-width: 767px) {

  .test-animation-mobile {
    .main-container-mobile {
      .grid-container-mobile {
        .bottom-mobile {
          .grid-image {
            img {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
  // font-size 12px for all buttons in mobile version
  button {
    font-size: 12px !important;
  }
  .blog-success-section2 {
    padding: 1rem 0;
  }

  .blog-section1-main {
    display: none;
  }

  .supply-padding {
    width: 85% !important;
  }
  .supply-spacing {
    padding-top: 1rem;
  }

  .homepage-section4-main {
    background-color: black;
    padding:  4rem 1rem;
    border-radius: 20px;
    .homepage-section5 {
      border-radius: 20px;
      background-color: #212121;
      padding: 1rem 0rem;
      .main .grid-container {
        grid-template-columns: repeat(1, 100%) !important;
      }
    }
  }

  .data-led-main {
    .data-led-section5 {
      .data-led-section-grid {
        .feature-icon {
          height: auto !important;
          width: 42px !important;
        }
        .image-div {
          height: 70px !important;
          width: 70px !important;
        }
      }
    }
  }

  .data-led-main {
    .data-led-section5 {
      .data-led-section-grid {
        gap: 20px !important;
        .item-img {
          gap: 10px !important;

          .text-icon {
            font-size: 14px;
          }
        }
      }
    }
  }

  /* Styles for mobile screens */
  .newsroomPageCss {
    .secondpage_section2 {
      font-size: 14px !important;
      padding: 0.5rem !important;
    }

    .homepage-section5 {
      .grid-container {
        grid-template-columns: repeat(1, 100%) !important;

        .left {
          display: none !important;
        }
      }
    }

    .header-section > div {
      grid-template-columns: none !important;
      // background: #bb1ccc !important;
    }
    img {
      height: auto !important;
    }
    .nav-header-fixed {
      // background-color: black !important;
      a {
        line-height: normal !important;
        padding-top: 10px !important;
      }
    }
    .header_section_1 {
      flex-direction: row !important;
      justify-content: flex-start !important;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      align-items: flex-end !important;

      .firstText {
        font-size: 20px !important;
        line-height: 34px;
      }
    }
    .feature-icon {
      width: 45px !important;
    }
    .customer-section1-text {
      font-size: 13px !important;
      line-height: normal !important;
    }
    .feature-box {
      grid-gap: 0px !important;
    }
    .section1-flex-icon {
      display: grid !important;
      gap: 5% !important;
      grid-template-columns: repeat(4, 20%);
    }

    .newsroom-section2-body h1 {
      font-size: 18px !important;
    }
    .newsroom-section2-body .newsroom-section2-main {
      flex-wrap: wrap !important;
      width: 324px !important;
      margin: auto !important;
    }
    .success-story-page-section-2-Header-text {
      font-size: 18px !important;
    }
    .section2-box > div {
      flex-wrap: wrap !important;
      margin-right: 0px !important;
      position: relative;
    }
    .newsroom-section3-main h1 {
      font-size: 18px !important;
    }
    .margin-box {
      width: 90% !important;
    }
    .newsroom-reuse-body .newsroom-reuse-main {
      width: 324px !important;
      margin: auto !important;
    }
    .footerMenu {
      grid-gap: 10px !important;
      font-size: 12px !important;
    }

    .newsroom-reuse-body
      .newsroom-reuse-main
      .newsroom-reuse-section
      .newsroom-reuse-box
      h2 {
      font-size: 12px !important;
    }
    .newsroom-reuse-body
      .newsroom-reuse-main
      .newsroom-reuse-section
      .newsroom-reuse-box
      h3 {
      font-size: 16px !important;
    }
    .newsroom-reuse-body
      .newsroom-reuse-main
      .newsroom-reuse-section
      .newsroom-reuse-box
      p {
      font-size: 12px !important;
      font-weight: normal !important;
      padding-bottom: 1rem !important;
    }
    .newsroom-reuse-body
      .newsroom-reuse-main
      .newsroom-reuse-section
      .news-btn-read-more
      > div
      a {
      font-size: 12px !important;
    }
    .newsroom-section2-body .newsroom-section2-main {
      grid-gap: 1.5rem !important;
    }
    .newsroom-section3-body .newsroom-section3-main {
      background-color: transparent !important;
    }
    .newsroom-section3-firstBox {
      .newsroom-reuse-box {
        background-color: rgb(33, 33, 33) !important;
        min-height: auto !important;
      }
    }
    .newsroom-section2-body {
      padding: 0px !important;
    }
    .margin-box {
      padding: 0px !important;
    }
    .newsroom-reuse-body .newsroom-reuse-main .newsroom-reuse-section {
      font-size: 12px;
      .newsroom-reuse-box {
        min-height: auto !important;
      }
    }

    .customer-section1-body {
      padding-top: 3rem !important;
      padding-bottom: 2rem !important;
      display: none !important;
    }
    .newsroom-section3-body
      .newsroom-section3-main
      .newsroom-section3-firstBox {
      padding: 31px !important;
    }
    #newsroom-section5 {
      .success-story-page-section-2-Header-text {
        padding-top: 2rem !important;
      }
      .content-box-section2a span {
        font-size: 14px !important;
      }
      .content-box-section2a {
        height: auto !important;
      }
      .reuseSectionBox .btn-read-more > div {
        padding: 1px !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
      a {
        font-size: 12px !important;
      }
    }

    .text_overFlow {
      -webkit-line-clamp: initial !important;
      display: block !important;
    }
  }
  .supplychain-main-class {
    // display: none !important;
    .customer-success-section2-mobile {
      background-color: black;
      padding: 1.5rem 0;

      .Header-text {
        color: white;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 1rem;
      }

      .first-box {
        width: 80%;
        margin: auto;
        background-color: #212121;
        margin-bottom: 1rem;
        position: relative;

        .image-div {
          padding: 1rem 1rem 0;
        }

        .box-heading {
          font-size: 16px;
          font-weight: 600;
          color: white;
          margin: 0.5rem 0 1rem;
          padding-left: 1rem;
        }

        .box-content {
          font-size: 14px;
          font-weight: 500;
          color: white;
          letter-spacing: 0.2px;
          line-height: 18px;
          padding-left: 1rem;
        }

        .read-more-icon {
          svg {
            path {
              fill: white;
            }
          }
        }
      }

      .second-box {
        position: relative;
        display: grid;
        grid-template-columns: 40% 60%;
        grid-gap: 10px;
        align-items: flex-start;
        justify-content: center;
        width: 80%;
        margin: auto;
        // background-color: #212121;
        border-bottom: 1px solid white;

        &:last-child {
          border-bottom: none;
        }
        padding: 1rem;
        margin-bottom: 1rem;

        .box-detail {
          .box-heading {
            font-size: 14px;
            font-weight: 600;
            color: white;
            margin-bottom: 12px;
          }

          .box-content {
            font-size: 12px;
            font-weight: 500;
            color: white;
            letter-spacing: 0.2px;
            line-height: 16px;
          }
        }
      }
    }
    .secondpage_section2 {
      font-size: 16px !important;
    }

    .header-section > div {
      grid-template-columns: none !important;
      // background: #bb1ccc !important;
    }
    img {
      height: auto !important;
    }
    .header_section_1 {
      flex-direction: row !important;
      justify-content: flex-start !important;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      align-items: flex-end !important;

      .firstText {
        font-size: 20px !important;
        line-height: 34px;
      }
      .secondText {
        font-size: 16px !important;
        padding-bottom: 2rem !important;
      }
    }
    .supply-spacing {
      h1 {
        font-size: 20px !important;
      }
    }
    .flexContainer {
      grid-template-columns: none !important;
    }
    .seciton2-top,
    .section2-bottom {
      grid-template-columns: none !important;
      padding-bottom: 0px !important;
    }
    .top-right,
    .bottom-left {
      display: none !important;
    }
    .heading span span {
      font-size: 20px !important;
    }
    .heading-supply-chain {
      font-size: 20px !important;
      font-weight: bold !important;
    }
    .text {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 22px !important;
    }
    .mobile-heading {
      font-size: 20px !important;
    }
    .homepage-section5 {
      .left {
        display: none !important;
      }
      display: block !important;
      .react-multi-carousel-dot-list {
        display: none !important;
      }
    }
    .main .grid-container {
      display: block !important;
    }
    .box {
      h1 {
        padding-bottom: 10px;
      }
    }
  }
  .Blogs-Thought {
    padding-bottom: 2rem !important;
  }
  .success-story-page-section-5 {
    padding-top: 1.5rem 0;
  }

  .case-studies-pages-cls {
    .customer-success-section2-mobile {
      background-color: black;
      padding: 1.5rem 0;

      .Header-text {
        color: white;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 1rem;
      }

      .first-box {
        width: 80%;
        margin: auto;
        background-color: #212121;
        margin-bottom: 1rem;
        position: relative;

        .image-div {
          padding: 1rem 1rem 0;
        }

        .box-heading {
          font-size: 16px;
          font-weight: 600;
          color: white;
          margin: 0.5rem 0 1rem;
          padding-left: 1rem;
        }

        .box-content {
          font-size: 14px;
          font-weight: 500;
          color: white;
          letter-spacing: 0.2px;
          line-height: 18px;
          padding-left: 1rem;
        }

        .read-more-icon {
          svg {
            path {
              fill: white;
            }
          }
        }
      }

      .second-box {
        position: relative;
        display: grid;
        grid-template-columns: 40% 60%;
        grid-gap: 10px;
        align-items: flex-start;
        justify-content: center;
        width: 80%;
        margin: auto;
        // background-color: #212121;
        border-bottom: 1px solid white;

        &:last-child {
          border-bottom: none;
        }
        padding: 1rem;
        margin-bottom: 1rem;

        .box-detail {
          .box-heading {
            font-size: 14px;
            font-weight: 600;
            color: white;
            margin-bottom: 12px;
          }

          .box-content {
            font-size: 12px;
            font-weight: 500;
            color: white;
            letter-spacing: 0.2px;
            line-height: 16px;
          }
        }
      }
    }

    .test-animation-mobile {
      height: calc(100vh - 83px) !important;
      .main-container-mobile {
        .grid-container-mobile {
          .bottom-mobile {
            .grid-image {
              img {
                width: 100% !important;
              }
            }
          }
        }
      }
    }

    .secondpage_section2 {
      font-size: 14px !important;
      padding: 0.5rem !important;
    }

    .header-section > div {
      grid-template-columns: none !important;
      // background: #bb1ccc !important;
    }
    .nav-header-fixed {
      // background-color: black !important;
      a {
        line-height: normal !important;
        padding-top: 10px !important;
      }
      .logo {
        padding: 0px !important;
      }
    }
    img {
      height: auto !important;
    }
    .reuseSectionBox {
      margin: auto !important;
    }

    .header_section_1 {
      flex-direction: row !important;
      justify-content: flex-start !important;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      align-items: flex-end !important;

      .firstText {
        font-size: 20px !important;
        line-height: 34px;
      }
      .secondText {
        font-size: 16px !important;
        padding-bottom: 2rem !important;
      }
    }
    .feature-icon {
      width: 45px !important;
    }
    .customer-section1-text {
      font-size: 13px !important;
      line-height: normal !important;
    }
    .feature-box {
      grid-gap: 0px !important;
    }
    .section1-flex-icon {
      display: grid !important;
      gap: 5% !important;
      grid-template-columns: repeat(3, 28%);
    }
    .customer-section1-body {
      padding-top: 3rem !important;
      padding-bottom: 2rem !important;
      display: none !important;
    }
    .success-story-page-section-2-Header-text {
      font-size: 18px !important;
    }
    .reuseSectionBox .content-box-section2 span {
      font-size: 16px !important;
    }
    .reuseSectionBox {
      a {
        font-size: 12px !important;
      }
    }
    .reuseSectionBox .btn-read-more > div {
      padding: 2px !important;
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
    .success-story-page-section-3 .success-story-page-section-3-Header-text,
    .success-story-page-section-4-Header-text {
      font-size: 18px !important;
    }
    .success-story-page-section-3 .success-story-page-section-3-grid {
      grid-template-columns: none !important;
    }
    .success-story-page-section-3 .success-story-page-section-3-margin {
      width: 80% !important;
      background-color: transparent !important;
    }
    .success-story-page-section-3
      .success-story-page-section-3-grid
      .success-story-page-section-3-grid-items-text,
    .success-story-page-section-4-grid-items-text {
      background-color: #212121 !important;
      font-size: 18px !important;
    }
    .btn-read-more {
      a {
        font-size: 12px !important;
      }
    }
    .success-story-page-section-3
      .success-story-page-section-3-grid
      .btn-read-more
      > div {
      padding: 1px !important;
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
    .seeMore {
      font-size: 16px !important;
      padding-bottom: 0px !important;
    }
    .success-story-page-section-5 .success-story-page-section-5-Header-text {
      font-size: 18px !important;
    }

    .success-story-page-section-5 .success-story-page-section-5-margin {
      width: 80% !important;
    }
    .customer-section2-body {
      padding-bottom: 0px !important;
    }
    #section5-customer-success-story {
      .reuseSectionBox {
        width: auto !important;
      }
    }
    .success-story-page-section-4 .success-story-page-section-4-margin {
      width: 80% !important;
    }
  }
  .Drawer-main {
    z-index: 999999999 !important;
  }

  .sub-drawer {
    z-index: 999999999 !important;

    .menu-container {
      .menu-heading {
        display: flex;
        justify-content: center;
        font-size: 20px;
      }

      .menu-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        background-color: #932599;
        opacity: 80%;
        padding: 12px 20px;
        margin: 1.2rem 0 1rem;

        svg {
          path {
            fill: white;
          }
        }
      }

      .menu-sub-content {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 500;
        padding: 12px 20px;
        border: 1px solid white;
        margin-bottom: 1rem;
      }
    }
  }

  .footerMenu {
    span {
      a {
        font-size: 12px !important;
      }
    }
  }
  .footerMenu {
    grid-gap: 10px !important;
    font-size: 12px !important;
    margin-left: 60px;
    justify-content: left;
  }
  .functions-subtext {
    a {
      color: white !important;
    }
  }
  #nav-id {
    padding-top: 0px !important;
  }
  .linkedinCss {
    top: 22px !important;
    left: 30px !important;
  }
  .scroll-btn,
  .scroll-btn-down {
    display: none !important;
  }

  .copyRights {
    font-size: 10px !important;
  }

  .blogspage-cls {
    .secondpage_section2 {
      font-size: 14px !important;
      padding: 0.5rem !important;
    }

    .homepage-section5 {
      .grid-container {
        grid-template-columns: repeat(1, 100%) !important;

        .left {
          display: none !important;
        }
      }
    }
    .header-section > div {
      grid-template-columns: none !important;
      // background: #bb1ccc !important;
    }
    img {
      height: auto !important;
    }
    .nav-header-fixed {
      // background-color: black !important;
      a {
        line-height: normal !important;
        padding-top: 10px !important;
      }
      .logo {
        padding: 0px !important;
      }
    }

    .header_section_1 {
      flex-direction: row !important;
      justify-content: flex-start !important;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      align-items: flex-end !important;

      .firstText {
        font-size: 20px !important;
        line-height: 34px;
      }
      .secondText {
        font-size: 16px !important;
        padding-bottom: 2rem !important;
      }
    }
    .feature-icon {
      width: 45px !important;
    }
    .customer-section1-text {
      font-size: 13px !important;
      line-height: normal !important;
    }
    .feature-box {
      grid-gap: 0px !important;
    }
    .section1-flex-icon {
      display: grid !important;
      gap: 5% !important;
      grid-template-columns: repeat(4, 20%);
    }
    .reuseSectionBox {
      width: 324px !important;
      margin: auto !important;
      place-content: none !important;
    }
    .customer-section1-body {
      padding-top: 3rem !important;
      padding-bottom: 2rem !important;
      display: none !important;
    }
    .success-story-page-section-2-Header-text {
      font-size: 18px !important;
    }
    .reuseSectionBox .content-box-section2 span {
      font-size: 16px !important;
    }
    .reuseSectionBox {
      a {
        font-size: 12px !important;
      }
    }
    .reuseSectionBox .btn-read-more > div {
      padding: 2px !important;
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
    .success-story-page-section-3 .success-story-page-section-3-Header-text,
    .success-story-page-section-4-Header-text {
      font-size: 18px !important;
    }
    .success-story-page-section-3 .success-story-page-section-3-grid {
      grid-template-columns: none !important;
    }
    .success-story-page-section-3 .success-story-page-section-3-margin {
      width: 80% !important;
      background-color: transparent !important;
    }
    .success-story-page-section-3
      .success-story-page-section-3-grid
      .success-story-page-section-3-grid-items-text,
    .success-story-page-section-4-grid-items-text {
      background-color: #212121 !important;
      font-size: 18px !important;
    }
    .btn-read-more {
      a {
        font-size: 12px !important;
      }
    }
    .success-story-page-section-3
      .success-story-page-section-3-grid
      .btn-read-more
      > div {
      font-size: 12px !important;
    }
    .seeMore {
      font-size: 16px !important;
      padding-bottom: 0px !important;
    }
    .success-story-page-section-5 .success-story-page-section-5-Header-text {
      font-size: 18px !important;
    }

    .success-story-page-section-5 .success-story-page-section-5-margin {
      width: 90% !important;
    }
    .customer-section2-body {
      padding: 1rem 0 !important;
    }
    #section5-customer-success-story {
      .reuseSectionBox {
        width: 324px !important;
      }
    }
    .success-story-page-section-4 .success-story-page-section-4-margin {
      width: 80% !important;
    }
    .success-story-page-section-3
      .success-story-page-section-3-grid
      .success-story-page-section-3-grid-items-texta {
      background-color: #212121 !important;
      font-size: 16px !important;
      height: auto !important;
    }
    .section2-box > div {
      display: grid !important;
    }
    .content-box-section2a {
      height: auto !important;
      span {
        font-size: 16px !important;
      }
    }
    .success-story-page-section-2-Header-text {
      padding-top: 1rem !important;
    }
  }
  .MuiTypography-h6 {
    font-size: 14px !important;
  }
  .section4TypographyWrapper {
    gap: 20px !important;
  }
  .MuiTypography-h4 {
    font-size: 16px !important;
  }

  .homepage-mobile {
    .section2-homepage-main .homepage-body .mainContainer .box {
      text-align: left !important;
      padding: 10px !important;
    }
    .bottomContainer {
      margin-top: 1rem !important;
    }

    .secondpage_section2 {
      span {
        width: 90% !important;
        margin: auto !important;
        display: block !important;
      }
    }

    .landingPage {
      .h2-cls-subHeader {
        font-size: 20px !important;
      }
      .h2-cls-Header {
        font-size: 28px !important;
      }
    }

    .secondpage_section2 {
      font-size: 12px !important;
    }
    .section2-homepage-main .homepage-body .mainContainer .counting-number {
      font-size: 14px !important;
    }
    .section2-homepage-main .homepage-body .mainContainer .text {
      font-size: 12px !important;
    }
    .section2-homepage-main .homepage-body .mainContainer {
      width: 90% !important;
    }
    .topContainer {
      grid-gap: 12px !important;
      grid-template-columns: repeat(3, 33%) !important;
      text-align: center !important;
    }
    .bottomContainer {
      grid-gap: 1rem !important;
      grid-template-columns: repeat(3, 32%) !important;
      text-align: center !important;
    }
    .homepage-sections-padding {
      width: 90% !important;
    }
    .box-border > div {
      display: grid !important;
      justify-content: center !important;
    }
    .typewriter-container {
      width: auto !important;
    }
    .homepage-section3 .main .container {
      grid-template-columns: none !important;
    }
    .homepage-section3 .main .container .left img {
      display: none !important;
    }
    .homepage-section3 .main .container .left {
      height: auto !important;
    }
    .homepage-section3 {
      height: auto !important;
      padding: 2rem 0 !important;

      .awardSections {
        display: grid !important;
        align-items: center !important;
      }
    }

    .homepage-section3 div h1 {
      font-size: 20px !important;
    }
    .section3RightScreen {
      height: 500px !important;
    }
    .MuiTypography-h1 {
      font-size: 14px !important;
    }

    .homepage-section4 .main .services {
      flex-wrap: wrap !important;
    }

    #homepage-section5 {
      .left {
        display: none !important;
      }
    }
    // .homepage-section4 .main .grid-container {
    //   grid-template-columns: none !important;
    //   padding: 1rem 0 !important;
    // }

    #homepage-section4 {
      .left {
        display: none !important;
      }
    }
    .section2-homepage-main {
      display: block !important;
      height: auto !important;
      padding: 5rem 0;
    }

    .homepage-section4 .main .grid-container {
      padding: 1rem 0;
     // width: 100%;//
      span {
        font-size: 14px !important;
        line-height: 22px !important;
        letter-spacing: 0.2px !important;
        text-align: left !important;
      }
    }

    .homepage-section4 {
      h1 {
        font-size: 20px !important;
      }
    }

    .homepage-section4 {
      height: auto !important;
      padding: 4rem 0;
      h2 {
        font-size: 20px !important;
       // text-align: left !important;//
      }
    }
    .homepage-section4 .main .services .box .content div span {
      font-size: 16px !important;
    }

    .homepage-section4 .main .services .box .content div span a {
      font-size: 16px !important;
    }

    .margin-box {
      width: 90% !important;
      .know-more-btn {
        button {
          font-size: 12px !important;
        }
      }
    }
    .marginTeams {
      .main-heading {
        font-size: 20px !important;
      }
      .sub-heading {
        width: 100% !important;
        font-size: 14px !important;
        font-weight: normal !important;
      }
    }

    .homepage-section5 {
      .left {
        display: none !important;
      }
      display: block !important;
      .react-multi-carousel-dot-list {
        display: none !important;
      }
    }

    .ourTeamsMaincls {
      height: auto !important ;
      padding: 5rem 0 !important;

      .contaner {
        width: 100% !important;
      }
    }

    //Animations Start

    .animationImages {
      width: 250px !important;
    }

    .box1,
    .box3,
    .box5,
    .box7 {
      position: absolute;
      position: absolute;
      top: 68%;
      left: 7%;
      transform: translateY(-50%);
      opacity: 0;
      text-transform: uppercase;
    }

    .box2 {
      top: 50%;
      left: 30%;
      opacity: 0;
      position: absolute;
      width: auto !important;
    }

    .box4 {
      top: 0%;
      right: 13%;
      opacity: 0;
      position: absolute;
      width: auto !important;
    }
    .box8 {
      top: 50%;
      left: 0%;
      opacity: 0;
      position: absolute;
      width: auto !important;
    }

    .box6 {
      bottom: 0%;
      right: 12%;
      opacity: 0;
      position: absolute;
      width: auto !important;
    }

    .box10 {
      top: 37%;
      right: 17%;
      opacity: 0;
      position: absolute;
      width: auto !important;
    }

    .box2 {
      animation: box2 9.5s ease-in-out 0s infinite;
    }

    .box4 {
      animation: box4 9.5s ease-in-out 4.5s infinite;
    }
    .box6 {
      animation: box6 9.5s ease-in-out 4.5s infinite;
    }
    .box8 {
      animation: box8 9.5s ease-in-out 2s infinite;
    }

    .box10 {
      animation: box10 9.5s ease-in-out 6.5s infinite;
    }

    .box1 {
      animation: box1 9.5s ease-in-out 0s infinite;
    }

    .box3 {
      animation: box3 9.5s ease-in-out 2s infinite;
    }

    .box5 {
      animation: box5 9.5s ease-in-out 4.5s infinite;
    }

    .box7 {
      animation: box7 9.5s ease-in-out 6.5s infinite;
    }

    /* images animation start from here*/

    @keyframes box2 {
      0% {
        left: 30%;
        opacity: 1;
      }
      // 10% {
      //   opacity: 1;
      //   left: 10%;
      // }

      20%,
      100% {
        top: 50%;
        left: -34%;
        opacity: 0;
      }
    }

    @keyframes box4 {
      0% {
        top: 0%;
        right: 13%;
        opacity: 0.7;
      }

      10% {
        opacity: 1;
      }

      30%,
      100% {
        top: 30%;
        right: 13%;
        opacity: 0;
      }
    }

    @keyframes box6 {
      0% {
        bottom: 0%;
        right: 12%;
        opacity: 0.7;
      }
      10% {
        opacity: 1;
      }

      30%,
      100% {
        bottom: 42%;
        right: 12%;
        opacity: 0;
      }
    }

    @keyframes box8 {
      0% {
        top: 50%;
        left: 0%;
        opacity: 0;
      }
      10% {
        top: 50%;
        opacity: 0.7;
      }
      10% {
        opacity: 1;
      }

      20%,
      100% {
        top: 50%;
        left: 30%;
        opacity: 0;
      }
    }

    @keyframes box10 {
      0% {
        top: 37%;
        right: 17%;
        opacity: 0.5;
      }

      20% {
        opacity: 1;
      }

      30%,
      100% {
        top: 6%;
        right: 17%;
        opacity: 0;
      }
    }

    @keyframes box1 {
      0% {
        /* top: 50%; */
        opacity: 0.2;
      }
      5% {
        opacity: 0.5;
      }
      10% {
        opacity: 1;
      }

      20%,
      100% {
        /* top: 20%; */
        opacity: 0;
      }
    }

    @keyframes box3 {
      0% {
        /* top: 50%; */
        /* right: 20%; */
        opacity: 0;
      }
      10% {
        /* top: 50%; */
        opacity: 0.7;
      }
      10% {
        opacity: 1;
      }

      20%,
      100% {
        /* top: 50%; */
        /* right: 0%; */
        opacity: 0;
      }
    }

    @keyframes box5 {
      0% {
        /* bottom: 0%; */
        /* right: 10%; */
        opacity: 0;
      }
      10% {
        /* right: 10%; */
        opacity: 1;
      }
      10% {
        opacity: 1;
      }

      20% {
        /* right: 10%; */
        /* bottom: 20%; */
        opacity: 1;
      }
      30%,
      100% {
        /* right: 10%; */
        /* bottom: 20%; */
        opacity: 0;
      }
    }

    @keyframes box7 {
      0% {
        /* top: 50%; */
        /* right: 10%; */
        opacity: 0;
      }
      10% {
        /* right: 10%; */
        opacity: 0.3;
      }
      10% {
        opacity: 0.7;
      }
      20% {
        opacity: 1;
      }

      30%,
      100% {
        /* right: 10%; */
        /* top: 20%; */
        opacity: 0;
      }
    }

    //end

    .buttonWrapper {
      font-size: 12px !important;
      top: 72% !important;
      padding: 7px 13px 7px 13px !important;
    }

    .logo {
      padding-top: 10px !important;
      width: 100px !important;
      height: auto !important;
    }
  }

  .aays-logo {
    height: 38px !important;
  }
  .top-header svg {
    font-size: 24px !important;
  }
  .top-header button {
    padding-top: 0px !important;
  }
  .logo {
    width: 100px !important;
  }
  .right-option {
    margin-right: -10px !important;
    svg {
      font-size: 30px !important;
    }
  }
  .newsroomPageCss .nav-header-fixed a {
    padding-top: 10px !important;
  }
  .dasktopview {
    display: block !important;
  }
  .mobileView {
    display: none !important;
  }

  .closeIons {
    font-size: 20px !important;
  }

  .section3RightScreen {
    .section3SliderText {
      a {
        font-size: 14px !important;
        line-height: 20px !important;
      }
      p {
        line-height: 15px !important;
        font-size: 12px !important;
      }
    }
  }

  .section3RightScreen i {
    font-size: 12px !important;
  }
  .section3SliderImageWrapper {
    display: none !important;
  }
  .secetion3Slider > div {
    background-color: black !important;
    --accent-color: #bb1ccc;

    box-shadow: inset 4px 0px 0px 0px var(--accent-color);
  }

  .mobileFontSize {
    font-size: 16px !important;

    a {
      font-size: 14px !important;
    }
  }

  .ourTeamsMaincls {
    .contaner {
      height: 230px !important;
    }
  }

  .dataEngMobile, .dataArchitectMobile {
    .section2Text {
      line-height: 0.6 !important;
    }

    .secondpage_section5 {
      grid-template-columns: repeat(3, 32%) !important;
    }
    .secondpage_section5-main-header {
      padding-top: 2rem !important;
    }

    .homepage-section5 {
      .left {
        display: none !important;
      }
      display: block !important;
      .react-multi-carousel-dot-list {
        display: none !important;
      }
    }

    .header-section > div {
      grid-template-columns: none !important;
      // background: #bb1ccc !important;
    }
    img {
      height: auto !important;
    }
    .Page2Section4-image {
      display: none !important;
    }
    .Page2Section4-main {
      padding-bottom: 1rem !important;
      padding-top: 1rem !important;
    }
    .secondpage_section {
      display: none !important;
    }
    .subHeading {
      font-size: 14px !important;
      display: block !important;
      line-height: 22px !important;
    }
    .Page2Section4-body {
      padding: inherit !important;
    }
    .margin-box {
      width: 90% !important;
    }
    .heading .mobile-heading {
    }
    .Page2Section4-text {
      width: 100% !important;
      margin: auto !important;
      display: block !important;
      span {
        font-size: 20px !important;

        span {
          font-size: 20px !important;
        }
      }
    }
    .Page2Section4-top {
      gap: 0px !important;
    }
    .Page2Section4-bottom {
      gap: 2rem !important;
    }
    .Page2Section4-top {
      margin-bottom: 0rem !important;
    }
    .secondpage_section5-main {
      padding: 1rem 0 2rem 0 !important;

      .margin-box {
        width: 100% !important;
      }
    }
    .secondpage_section5-main-header {
      padding: 0px !important;
      padding-bottom: 1rem !important;
      padding-top: 1rem !important;
    }
    .secondpage_section5-main-header {
      font-size: 20px !important;
      width: 100% !important;
    }
    .section2Text span {
      font-size: 14px !important;
      line-height: 20px !important;
    }
    .nav-header-fixed {
      // background-color: black !important;
      a {
        line-height: normal !important;
        padding-top: 10px !important;
      }
    }
    .header_section_1 {
      flex-direction: row !important;
      justify-content: flex-start !important;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      align-items: flex-end !important;

      .firstText {
        font-size: 20px !important;
        line-height: 34px;
      }
    }
  }

  .contactUs {
    .secondpage_section2 {
      font-size: 14px !important;
      padding: 0.5rem !important;
    }

    .header-section > div {
      grid-template-columns: none !important;
      // background: #bb1ccc !important;
    }
    img {
      height: auto !important;
    }
    .header_section_1 {
      flex-direction: row !important;
      justify-content: flex-start !important;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      align-items: flex-end !important;

      .firstText {
        font-size: 20px !important;
        line-height: 34px;
      }
      .secondText {
        font-size: 16px !important;
        padding-bottom: 2rem !important;
      }
    }
    .contactUs-section1 {
      h1 {
        font-size: 20px !important;
      }
    }

    .contact-padding,
    .contact-flexContainer {
      padding-bottom: 0px !important;
      padding-top: 6rem !important;
    }

    #contact-form {
      div {
        border: none !important;
        display: flex !important;
        grid-gap: 10px !important;
        padding-top: 1rem !important;
        padding-left: 5px !important;
        padding-right: 0px !important;

        .form__field {
          width: 100% !important;
        }
      }
    }
    .section2-main
      .background-box
      .location-background
      .locations
      .locations-flexContainer
      .container2 {
      border: none !important;
      border-top: 1px solid white !important;
      border-bottom: 1px solid white !important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
    .heading {
      font-size: 20px !important;
    }
    .content {
      font-size: 18px !important;
    }

    .contact-flexLeft {
      .icon {
        img {
          width: 40px !important;
        }
      }
      .title {
        font-size: 20px !important;
      }
      .content {
        font-size: 16px !important;
      }
    }
    .section2-main
      .background-box
      .location-background
      .locations
      .locations-flexContainer {
      grid-template-columns: none !important;
      grid-gap: 10px !important;
    }
  }

  .privacyPolicy {
    .article__title {
      font-size: 20px !important;
    }

    .hideForMObiletable {
      display: none !important;
    }
    .article {
      margin-bottom: 10px !important;
    }

    .header-section > div {
      grid-template-columns: none !important;
      // background: #bb1ccc !important;
    }
    img {
      height: auto !important;
    }
    .header_section_1 {
      flex-direction: row !important;
      justify-content: flex-start !important;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      align-items: flex-end !important;

      .firstText {
        font-size: 20px !important;
        line-height: 34px;
      }
      .secondText {
        font-size: 16px !important;
        padding-bottom: 2rem !important;
      }
    }
  }

  // #homePage {
  //   img {
  //     height: auto !important;
  //   }
  // }

  .GenAi-body {
    .header-section > div {
      grid-template-columns: none !important;
      // background: #bb1ccc !important;
    }
    img {
      height: auto !important;
    }
    .middle-image {
      width: 100% !important;
    }

    .GenAi-body .GenAi-main .blogImg {
      height: 26% !important;
    }
    .main-heading {
      font-size: 20px !important;
    }
    .main-headss{
      font-size: 15px !important;
    }
    .flex-containersi{
      flex-direction: column;
      padding-bottom: 3rem;
     }
     .leftssi{
        width: 100% !important;
     }
     .rightssi{
         width: 100% !important;
         justify-content: flex-start !important;
     }
     .accordian-filessi{
       height: 90%;
       width: 100%;
     }
  }

  .pageNotFound {
    .header-section > div {
      grid-template-columns: none !important;
      // background: #bb1ccc !important;
    }
    img {
      height: auto !important;
    }
  }

  .ai-innovation-main {
    .margin-box {
      width: 92% !important;
    }

    .header-section > div {
      grid-template-columns: none !important;
      // background: #bb1ccc !important;
    }
    img {
      height: auto !important;
    }
    .lastFlip-card {
      display: none !important;
    }
    .ai-flip-list {
      font-size: 12px !important;
    }
    .nav-header-fixed {
      // background-color: black !important;
      a {
        line-height: normal !important;
        padding-top: 10px !important;
      }
    }
    .header_section_1 {
      flex-direction: row !important;
      justify-content: flex-start !important;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      align-items: flex-end !important;

      .firstText {
        font-size: 20px !important;
        line-height: 34px;
      }
    }
    .section2Text {
      font-size: 14px !important;
      width: 100% !important;
      text-align: center;
    }

    .heading-supply-chain {
      font-size: 20px !important;
      padding-top: 0rem !important;
    }
    .imgAlignCenter {
      padding-bottom: 0 !important;
      img {
        width: 100% !important;
      }
    }
    .GridLayoutNew,
    .middle-item {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    .flip-card {
      width: auto !important;
    }

    .heading-supply-chain {
      width: 100% !important;
    }
    .flip-card {
      height: 200px !important;
    }
    .ai-section2-new {
      height: auto !important;

      .margin-80 {
        width: 90% !important;

        .heading {
          padding-top: 2rem !important ;
        }
      }
    }
    .homepage-section5 {
      .grid-container {
        grid-template-columns: repeat(1, 100%) !important;

        .left {
          display: none !important;
        }
      }
    }
    .imgAlignCenter {
      img {
        width: 100% !important;
      }
    }
  }

  .data-led-main {
    .header-section > div {
      grid-template-columns: none !important;
    }
    .ai-flip-list {
      font-size: 12px !important;
    }

    .nav-header-fixed {
      a {
        line-height: normal !important;
        padding-top: 10px !important;
      }
    }
    .header_section_1 {
      flex-direction: row !important;
      justify-content: flex-start !important;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      align-items: flex-end !important;

      .firstText {
        font-size: 20px !important;
        line-height: 34px;
      }
    }
    .section2Text {
      font-size: 12px !important;
      width: 100% !important;
    }
    .heading-supply-chain {
      font-size: 20px !important;
    }

    .data-led-section4 {
      .margin-80 {
        img {
          padding-top: 0px !important;
        }
      }
    }

    .data-led-section5 {
      height: auto !important;

      .header-text-new {
        font-size: 20px !important;
        padding-top: 2rem !important;
        display: block !important;
      }

      .header-text-new-1 {
        font-size: 20px !important;
      }
    }

    .data-led-section6 {
      height: auto !important;

      .sub-header-text-new {
        width: 90% !important;
        font-size: 16px !important;
      }

      .header-text-new-1 {
        font-size: 20px !important;
      }
    }

    .homepage-section5 {
      .grid-container {
        grid-template-columns: repeat(1, 100%) !important;

        .left {
          display: none !important;
        }
      }
    }

    .data-led-section-grid {
      display: grid !important;
      grid-template-columns: repeat(2, 50%) !important;
      padding-top: 0px !important;
    }

    .flex-box-additional-key-main {
      display: none !important;

      .flex-box-additional-key {
        display: initial !important;
      }
    }

    .ConsumerGoods {
      display: initial !important;
    }

    .data-led-ConsumerGoods-text1 {
      font-size: 20px !important;
    }
    .data-led-ConsumerGoods-text2 {
      font-size: 18px !important;
    }

    .GridLayoutNew,
    .middle-item {
      grid-template-columns: repeat(1, 1fr) !important;
      padding-top: 0px !important;
    }
    .data-led-section3 {
      display: block !important;
      .margin-80 {
        width: 90% !important;
      }
    }
    .flip-card {
      width: auto !important;
    }

    .heading-supply-chain {
      width: 100% !important;
    }
    .flip-card {
      height: 200px !important;
    }
    .data-led-section3 {
      height: auto !important;
    }
  }

  .Finance-main {
    .customer-success-section2-mobile {
      background-color: black;
      padding: 1.5rem 0;

      .Header-text {
        color: white;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 1rem;
      }

      .first-box {
        width: 80%;
        margin: auto;
        background-color: #212121;
        margin-bottom: 1rem;
        position: relative;

        .image-div {
          padding: 1rem 1rem 0;
        }

        .box-heading {
          font-size: 16px;
          font-weight: 600;
          color: white;
          margin: 0.5rem 0 1rem;
          padding-left: 1rem;
        }

        .box-content {
          font-size: 14px;
          font-weight: 500;
          color: white;
          letter-spacing: 0.2px;
          line-height: 18px;
          padding-left: 1rem;
        }

        .read-more-icon {
          svg {
            path {
              fill: white;
            }
          }
        }
      }

      .second-box {
        position: relative;
        display: grid;
        grid-template-columns: 40% 60%;
        grid-gap: 10px;
        align-items: flex-start;
        justify-content: center;
        width: 80%;
        margin: auto;
        // background-color: #212121;
        border-bottom: 1px solid white;

        &:last-child {
          border-bottom: none;
        }
        padding: 1rem;
        margin-bottom: 1rem;

        .box-detail {
          .box-heading {
            font-size: 14px;
            font-weight: 600;
            color: white;
            margin-bottom: 12px;
          }

          .box-content {
            font-size: 12px;
            font-weight: 500;
            color: white;
            letter-spacing: 0.2px;
            line-height: 16px;
          }
        }
      }
    }

    .secondpage_section2 {
      font-size: 16px !important;
    }

    .margin-box {
      width: 90% !important;
    }

    .text {
      width: 100% !important;
      font-size: 14px !important;
      line-height: 22px !important;
      padding: 1rem 0 !important;
    }

    .heading-supply-chain {
      font-size: 20px !important;
    }
    .header-section > div {
      grid-template-columns: none !important;
      // background: #bb1ccc !important;
    }

    .header_section_1 {
      flex-direction: row !important;
      justify-content: flex-start !important;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      align-items: flex-end !important;

      .firstText {
        font-size: 20px !important;
        line-height: 34px;
      }
    }
    .Finance-section1 {
      display: none !important;
      height: auto !important;
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
      .finance-page-container {
        grid-template-columns: none !important;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      .left {
        display: none !important;
      }
      .right {
        text-align: left !important;
        .heading {
          .heading-supply-chain {
            font-size: 20px !important;
          }
        }
      }
    }

    .Finance-section2-new {
      height: auto !important;
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;

      .heading {
        .heading-supply-chain {
          // text-align: left !important;
          width: 100%;
          margin: auto;
          display: block;
          text-align: left !important;
        }
        .text {
          text-align: left !important;
        }
      }

      .imgAlignCenter {
        display: none !important;
      }
      .finance-page-container {
        grid-template-columns: none !important;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      .left {
        .heading {
          .heading-supply-chain {
            font-size: 20px !important;
          }
        }
      }
      .right {
        text-align: left !important;
        display: none !important;
      }
    }

    .Finance-section3-new {
      height: auto !important;
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;

      .heading {
        .heading-supply-chain {
          // text-align: left !important;
          width: 100%;
          margin: auto;
          display: block;
          text-align: left !important;
        }
        .text {
          text-align: left !important;
        }
      }

      .finance-page-container {
        grid-template-columns: none !important;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      .left {
        .heading {
          .heading-supply-chain {
            font-size: 20px !important;
          }
        }
      }
      .right {
        text-align: left !important;
        display: none !important;
      }
    }

    .Finance-section4-new {
      height: auto !important;
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;

      .heading {
        .heading-supply-chain {
          // text-align: left !important;
          width: 100%;
          margin: auto;
          display: block;
          text-align: left !important;
        }
        .text {
          text-align: left !important;
        }
      }

      .finance-page-container {
        grid-template-columns: none !important;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      .left {
        .heading {
          .heading-supply-chain {
            font-size: 20px !important;
          }
        }
      }
      .right {
        text-align: left !important;
        display: none !important;
      }
    }

    .Finance-section2 {
      height: auto !important;
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
      .finance-page-container {
        grid-template-columns: none !important;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      .left {
        .heading {
          .heading-supply-chain {
            font-size: 20px !important;
          }
        }
      }
      .right {
        text-align: left !important;
        display: none !important;
      }
    }
    .Finance-section3 {
      .finance-page-container {
        grid-template-columns: none !important;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      .right {
        display: none !important;
      }
      .left {
        text-align: left !important;
        .heading {
          .heading-supply-chain {
            font-size: 20px !important;
          }
        }
      }
    }

    .Finance-section4 {
      h1 {
        font-size: 20px !important;
      }

      .flexContainer {
        grid-template-columns: none !important;
        display: flex !important;
        justify-content: center;
        flex-wrap: wrap;
      }
    }

    .Finance-section5 {
      .homepage-section5 {
        .grid-container {
          grid-template-columns: repeat(1, 100%) !important;

          .left {
            display: none !important;
          }
        }
      }
    }

    .Finance-section6 {
      .flexContainer {
        justify-content: center !important;
      }
    }
  }

  .data-led-main {
    .header-section > div {
      grid-template-columns: none !important;
    }

    .header_section_1 {
      flex-direction: row !important;
      justify-content: flex-start !important;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      align-items: flex-end !important;

      .firstText {
        font-size: 20px !important;
        line-height: 34px;
      }
    }

    .header-text-new {
      font-size: 20px !important;
      .data-led-ConsumerGoods-text2 {
        font-size: 20px !important;
      }
    }

    .data-led-section4 {
      display: none !important;
    }

    .data-led-section5 {
      .header-text-new {
        width: 80%;
        margin: auto;
        display: block;
      }
    }

    .data-led-section6 {
      .header-text-new-1 {
        text-align: left !important ;
        width: 90% !important;
        margin: auto !important ;
        display: block !important;
        font-size: 20px !important;
      }
      .sub-header-text-new {
        text-align: left !important;
        width: 90% !important;
        font-size: 14px !important;
        font-weight: normal !important;
      }
    }
  }

  .ai-innovation-main {
    .header-section > div {
      grid-template-columns: none !important;
    }

    .header_section_1 {
      flex-direction: row !important;
      justify-content: flex-start !important;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      align-items: flex-end !important;

      .firstText {
        font-size: 20px !important;
        line-height: 34px;
      }
    }
  }

  .automotive-main {
    .secondpage_section2 {
      font-size: 16px !important;
    }

    .heading-supply-chain {
      font-size: 20px !important;
    }
    .header-section > div {
      grid-template-columns: none !important;
      // background: #bb1ccc !important;
    }
    .header_section_1 {
      flex-direction: row !important;
      justify-content: flex-start !important;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      align-items: flex-end !important;

      .firstText {
        font-size: 22px !important;
        line-height: 32px;
      }
    }

    .homepage-section5 {
      .grid-container {
        grid-template-columns: repeat(1, 100%) !important;

        .left {
          display: none !important;
        }
      }
    }

    .automotive-section1 {
      height: auto !important;
      padding: 3rem 0;

      .margin-box {
        width: 90% !important;
      }

      .header-text {
        text-align: left !important;
        font-size: 20px !important;
      }
    }

    .automotive-section2 {
      height: auto !important;
      padding: 3rem 0 !important;

      .margin-box {
        width: 90% !important;
      }

      .header-text {
        font-size: 20px !important;
        text-align: left !important;
      }

      .text {
        text-align: left !important;
        font-size: 14px !important;
      }
    }

    .automotive-section3 {
      height: auto !important;
      padding: 3rem 0 !important;
      .margin-box {
        width: 90% !important;
      }

      .header-text {
        font-size: 20px !important;
        text-align: left !important;
      }
      .text {
        text-align: left !important;
        font-size: 14px !important;
      }

      .automotive-section3-grid {
        grid-template-columns: none !important;

        .automotive-section3-item-box {
          border-right: none !important;

          .item-header {
            font-size: 20px !important;
            text-align: left !important;
          }

          .item-content {
            text-align: left !important;
            font-size: 14px !important;
          }
        }
      }
    }
  }
  .campaning {
    header {
      background-color: black !important;
      padding: 0;
      padding-left: 20px;
      padding-right: 20px;
    }
    .logo {
      padding-top: 10px !important;
      width: 120px !important;
    }
  }

  .about-main {
    .header-section > div {
      grid-template-columns: none !important;
    }

    img {
      height: auto !important;
    }

    .nav-header-fixed {
      a {
        line-height: normal !important;
        padding-top: 10px !important;
      }
    }

    .header_section_1 {
      flex-direction: row !important;
      justify-content: flex-start !important;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      align-items: flex-end !important;

      .firstText {
        font-size: 20px !important;
        line-height: 34px;
      }

      .secondText {
        font-size: 16px !important;
        padding-bottom: 2rem !important;
      }
    }

    .secondpage_section2 {
      font-size: 16px !important;
    }

    .about-section1 {
      grid-template-columns: repeat(1, 100%) !important;
      width: 90% !important;
      .header-text {
        text-align: left !important;
        font-size: 20px !important;
        margin-bottom: 0.5rem !important;
      }

      .content {
        text-align: left;
        font-size: 14px !important;
        letter-spacing: 0.1px !important;
        line-height: 22px !important;
        font-weight: normal !important;
      }
    }

    .about-section2 {
      padding-bottom: 0 !important;
      width: 90% !important;

      .header-text {
        text-align: left !important;
        font-size: 20px !important;
      }

      .content {
        text-align: left !important;
        padding-top: 0.5rem !important;
        font-size: 14px !important;
        width: 100% !important;
        font-weight: normal !important;
        line-height: 22px !important;
      }
    }

    .about-section3 {
      padding: 0 !important;

      .homepage-mobile {
        .homepage-section4 {
          .main {
            .main-heading {
              .grid-container {
                // width: 100% !important;
                // display: none !important;
              }
            }
          }
        }
      }

      .first-section {
        display: grid;
        justify-content: center;
        align-content: center;
        align-items: center;
        grid-template-columns: none !important;
        grid-gap: 2rem !important;
        margin-bottom: 1rem !important;
        padding: 0 !important;

        .box-container {
          display: flex;
          flex-direction: column !important;
          align-items: center;
          justify-content: center !important;
          padding: 0 !important;
        }

        .first-box {
          position: unset !important;
          left: none !important;
          margin-bottom: 2rem;
          padding: 2rem !important;
        }

        .second-box {
          background-color: #212121 !important;
          padding: 2rem !important;
        }

        .third-box {
          background-color: #212121 !important;
          padding: 2rem !important;
        }

        .box-heading {
          font-size: 20px !important;
        }

        .item-text {
          font-size: 16px !important;
          text-align: center;
        }

        .header-text {
          width: 100% !important;
          font-size: 20px !important;
          padding: 0 1rem;
          text-align: left !important;
        }
      }

      .second-section {
        margin-bottom: 2rem !important;
      }

      .third-section {
        padding: 1rem !important;
        background-color: #212121;

        .third-section-box {
          background-color: black !important;
          --accent-color: #bb1ccc;

          box-shadow: inset 4px 0px 0px 0px var(--accent-color);
          padding: 1rem;
          margin: 0.8rem 0 !important;
          width: 100%;
        }
      }

      .fourth-section {
        min-height: auto !important;

        .header-text {
          color: white;
          font-size: 20px !important;
          text-align: left !important;
          padding-bottom: 0rem !important;
        }

        .fourth-section-grid {
          display: flex !important;
          flex-direction: column;
          grid-template-columns: none !important;

          .fourth-section-item-box {
            border-right: none !important;
            border-bottom: 4px solid #bb1ccc;
            padding: 24px 0 !important;
            text-align: left !important;

            .item-content {
              font-size: 14px;
              color: white;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: 0.2px;
            }

            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }

    .about-section4 {
      width: 90% !important;
      margin: auto;
      //background-color: black !important;//
      padding-bottom: 3rem !important;

      .header-text {
        width: 100% !important;
        font-size: 20px !important;
      }

      .grid-box {
        background-color: #bb1ccc;
        border-radius: 16px !important;
        padding: 1rem 1rem 0 1rem !important;
        width: 100% !important;
        margin: auto;
      }

      .grid-item {
        display: grid;
        grid-template-columns: repeat(1, 100%) !important;
        align-items: center;
        justify-content: center;
        grid-gap: 0 !important;
        margin-top: 0 !important;

        .image-div {
          padding: 0 !important;
          border-radius: 16px !important;
        }
      }

      .mobile-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 0 8px;

        .item-name {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 16px;
        }

        .item-role {
          font-size: 16px;
          font-weight: 600;
          text-align: center;
        }
      }

      .react-multi-carousel-list {
        height: auto !important;
      }
    }

    .homepage-section4-main {
      .box-heading {
        font-size: 20px !important;
      }

      .box-content {
        font-size: 16px !important;
        font-weight: lighter;
      }

      .homepage-section5 {
        .grid-container {
          grid-template-columns: repeat(1, 100%) !important;

          .left {
            display: none !important;
          }

          .react-multi-carousel-dot-list {
            display: none !important;
          }
        }
      }
    }
  }

  .career-main {
    .secondpage_section2 {
      font-size: 16px !important;
    }

    .heading-supply-chain {
      font-size: 20px !important;
    }
    .header-section > div {
      grid-template-columns: none !important;
      // background: #bb1ccc !important;
    }
    .header_section_1 {
      flex-direction: row !important;
      justify-content: flex-start !important;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      align-items: flex-end !important;

      .firstText {
        font-size: 22px !important;
        line-height: 32px;
      }
    }

    .homepage-section5 {
      .grid-container {
        grid-template-columns: repeat(1, 100%) !important;

        .left {
          display: none !important;
        }
      }
    }

    .career-body {
      .career-section1-new {
        height: auto !important;
        padding: 3rem 0;
        width: 90% !important;

        .section-box {
          display: grid !important;
          grid-template-columns: none !important;

          .grid-item1 {
            .heading {
              font-size: 20px !important;
              line-height: 24px !important;
              letter-spacing: 0.2px;
            }

            .content {
              font-size: 14px !important;
              letter-spacing: 0.2px;
              font-weight: 500;
              line-height: 20px !important;
            }
          }
        }

        .absolute-image {
          display: none !important;
        }
      }

      .career-section2 {
        height: auto !important;
        padding: 3rem 0 !important;

        .company-logos {
          grid-template-columns: repeat(3, 28%) !important;
          padding-top: 1rem !important;
          width: 100% !important;
        }

        .spanHeading {
          font-size: 20px !important;
          line-height: 20px !important;
          letter-spacing: 0.2px;
          text-align: left;
          width: 90% !important;
          margin: auto !important;
          display: block !important;
        }

        .content {
          font-size: 14px !important;
          letter-spacing: 0.2px;
          text-align: left;
          width: 90% !important;
        }

        .spanLast {
          width: 90% !important;
          text-align: left !important;
          font-size: 16px !important;
          padding: 1rem 0 !important;
          letter-spacing: 0.2px;
          margin-left: 1rem;
        }

        .background-design {
          img {
            width: 100px !important;
          }
        }
      }

      .career-section3 {
        height: auto !important;
        .container {
          padding: 0px !important;
          .heading {
            font-size: 20px !important;
            text-align: left !important;
          }
          .content {
            font-size: 18px !important;
            text-align: left !important;
          }

          .flex-container {
            .item {
              height: auto !important;
              width: 300px !important;
              overflow: hidden !important;
              img {
                height: auto !important;
                width: 300px !important;
                object-fit: fill !important;
                display: block;
              }
            }
          }
        }
      }

      .career-section4-new {
        height: auto !important;
        padding: 3rem 0rem !important;

        .section4-main {
          width: 90% !important;
          grid-template-columns: none !important;
          display: flex !important;
          flex-direction: column !important;

          .item1,
          .item2 {
            height: auto !important;
          }

          .item1 {
            .center {
              border-right: none !important;
              padding: 2rem 10px 0px 10px !important;

              .heading {
                font-size: 20px !important;
              }
            }
          }

          .item2 {
            .react-multi-carousel-list {
              // height: 50vh !important;

              // margin-top: -6px !important;
            }
          }
        }

        .background-design {
          img {
            width: 100px !important;
          }

          @keyframes move {
            0% {
              top: 0% !important;
              left: 5% !important ;
            }
            50% {
              top: 100% !important;
              left: 5% !important ;
            }
            100% {
              top: 0% !important ;
              left: 5% !important ;
            }
          }
        }
      }

      .career-section5-new {
        height: auto !important;

        .main {
          .heading {
            font-size: 20px !important;
            padding-bottom: 1rem !important;
          }

          .title {
            font-size: 20px !important;
          }

          .position {
            font-size: 16px !important;
          }

          .experience {
            font-size: 16px !important;
          }

          .react-multi-carousel-list {
            height: 32vh;
            margin-top: -6px !important;
            padding-bottom: 2rem !important;
          }
        }
      }

      .career-section6-new {
        padding: 2rem 0 !important;

        .main {
          width: 90% !important;
          padding: 2rem 0 !important;
          position: relative;

          .grid-container {
            height: auto !important;
            padding: 0 !important;
            grid-template-columns: none !important;
            display: grid !important;
            grid-template-rows: 50% 50% !important;
            height: 70vh !important;

            .image-box {
              display: flex;
              justify-content: center;
              position: relative;
              .main-image,
              .main-image1,
              .main-image2,
              .main-image3 {
                position: absolute;
                width: 70% !important;
                border-radius: 1rem;
                opacity: 0;
              }
              .main-image {
                animation: pop 16s infinite;
              }
              .main-image1 {
                animation: pop 16s 4s infinite;
              }
              .main-image2 {
                animation: pop 16s 8s infinite;
              }
              .main-image3 {
                animation: pop 16s 12s infinite;
              }
              @keyframes pop {
                0% {
                  opacity: 0;
                }
                25% {
                  opacity: 1;
                }
                40% {
                  opacity: 0;
                }
                100% {
                  opacity: 0;
                }
              }
            }

            .main-container {
              .mobile-container1,
              .mobile-container2,
              .mobile-container3,
              .mobile-container4 {
                // display: grid;
                opacity: 0;
                align-items: center;
                justify-content: center;
                text-align: center;
                position: absolute;
                padding: 1rem !important;
                .heading {
                  font-size: 20px !important;
                  font-weight: bold;
                }
                .content {
                  font-size: 18px !important;
                  padding-top: 1rem;
                }
                .tags {
                  font-size: 18px !important;
                  font-weight: bold;
                  padding-top: 1rem;
                }
              }

              .mobile-container1 {
                animation: popUp 16s infinite;
              }
              .mobile-container2 {
                animation: popUp 16s 4s infinite;
              }
              .mobile-container3 {
                animation: popUp 16s 8s infinite;
              }
              .mobile-container4 {
                animation: popUp 16s 12s infinite;
              }
              @keyframes popUp {
                0% {
                  opacity: 0;
                }
                25% {
                  opacity: 1;
                }
                40% {
                  opacity: 0;
                }
                100% {
                  opacity: 0;
                }
              }

              .social-icons {
                display: flex;
                justify-content: center;
                grid-gap: 10px;
                position: absolute;
                bottom: 0;
                left: 35%;

                img {
                  height: 45px !important;
                }
              }
            }
          }

          .container1,
          .container2,
          .container3,
          .container4 {
            opacity: 0;
            position: absolute !important;
            top: 0;
            bottom: 0;
            margin: auto;
            padding: 2rem 0 !important;
            grid-template-columns: none !important;
            align-items: center;
          }
        }
      }

      .career-section7-new {
        .main {
          .heading {
            font-size: 20px !important;
            text-align: left !important;
          }
          .content {
            width: 100% !important;
            font-size: 16px !important;
            text-align: left !important;
            line-height: 21px !important;
            letter-spacing: 0.2px;
          }

          .contact-wrapper {
            justify-content: left !important;
          }
        }
      }
    }
  }

  .aaysbroucher {
    .marginAaysbroucher {
      width: 80% !important;
    }
  }

  //mobile media query ends here
}

.brand {
  padding-top: 10px !important;
}

// ipad view starts from here...

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .blog-section1-body {
    gap: 0rem !important;
  }

  .test-animation-mobile .main-container-mobile .grid-container-mobile .bottom-mobile .main-bottom {
    padding-top: 8rem;
  }

  //   .success-story-page-section-2-grid, .success-story-page-section-4-grid{
  //     grid-gap: 2px !important;
  //   }
  //  .customer-success-section3 , .success-story-page-section-4-grid{
  //     grid-gap: 2px !important;
  //   }
  //   .success-story-page-section-3-grid{
  //     grid-gap: 5px;
  //   }

  .dataeng-section2-new-css {
    gap: 1.5rem;
  }

  .Drawer-main {
    z-index: 999999999 !important;
  }
  .aaysbroucher {
    .marginAaysbroucher {
      width: 80% !important;
    }
  }

  .sub-drawer {
    z-index: 999999999 !important;

    .menu-container {
      .menu-heading {
        display: flex;
        justify-content: center;
        font-size: 24px;
      }

      .menu-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 24px;
        font-weight: 600;
        background-color: #bb1ccc;
        opacity: 80%;
        padding: 16px 20px;
        margin: 1.5rem 0;

        svg {
          path {
            fill: white;
          }
        }
      }

      .menu-sub-content {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        font-weight: 500;
        padding: 20px;
        border: 0.8px solid white;
        margin-bottom: 1.5rem;
      }
    }
  }

  .footerMenu {
    margin-left: 50px;
  }

  .data-led-section3 {
    .ConsumerGoods {
      grid-template-columns: none !important;
    }
  }

  .data-led-main {
    .GridLayoutNew {
      margin: auto !important;
    }

    .flip-card {
      min-width: 200px !important;
      font-size: 20px !important;
    }
  }

  .homepage-section4-main .homepage-section5 .main .grid-container {
    grid-template-columns: repeat(1, 100%) !important;
  }

  .homepage-section5 {
    .grid-container {
      grid-template-columns: repeat(1, 100%) !important;

      .left {
        display: none !important;
      }
    }

    .react-multi-carousel-list {
      height: 40vh !important;
    }

    .box {
      h2 {
        font-size: 26px !important;
      }

      p {
        font-size: 20px !important;
      }
    }
  }

  .data-led-main {
    .section2 .section2Text {
      width: 90% !important;
    }

    .test-animation-mobile {
      height: calc(100vh - 100px) !important;
    }

    @media only screen and (min-width: 1024px) {
      .test-animation-mobile {
        height: calc(100vh - 77px) !important;
      }
    }

    .data-led-section4 {
      padding: 2rem 0;
      height: auto !important;

      .margin-80 {
        padding-top: 0 !important;
      }
    }

    .data-led-section5 {
      height: 75vh !important;

      .margin-80 {
        width: 90% !important;
      }
      .data-led-section-grid {
        .image-div {
          width: 100px !important;
          height: 100px !important;
        }

        .feature-icon {
          width: 65px !important;
          height: 65px !important;
        }
      }
    }
  }

  .section2-homepage-main {
    height: auto !important;
    padding: 10rem 0;
  }

  .homepage-section3 {
    height: auto !important;

    .main .container {
      grid-template-columns: none !important;
    }

    .main .container .left {
      height: 0 !important;
    }

    .main .container .left img {
      display: none;
    }

    .section3RightScreen i {
      font-size: 12px !important;
    }
    .section3SliderImageWrapper {
      display: none !important;
    }
    .secetion3Slider > div {
      background-color: black !important;
      --accent-color: #bb1ccc;

      box-shadow: inset 4px 0px 0px 0px var(--accent-color);
    }
  }

  .homepage-section4-main {
    padding-top: 14rem ;
    padding-bottom: 8rem;

    .homepage-section5 {
      width: 80%;
      margin: auto;
      background-color: #212121;
      position: relative;
      z-index: 1;
      color: white;
      padding: 3rem 0;
      border-radius: 4rem;

      .box h2 {
        text-align: center !important;
      }

      .main .grid-container .right .box p {
        text-align: center !important;
      }
    }
  }

  .automotive-main {
    .automotive-section1 {
      height: auto !important;
      padding: 4rem 0;
    }

    .automotive-section2 {
      height: auto !important;
      padding: 4rem 0;
    }

    .automotive-section3 {
      height: auto !important;
      padding: 4rem 0;

      .header-text {
        text-align: left !important;
      }

      .text {
        text-align: left !important;
      }

      .automotive-section3-grid {
        grid-template-columns: none !important;
      }

      .automotive-section3-item-box {
        border: none !important;
        .item-header {
          text-align: left !important;
        }
        .item-content {
          text-align: left !important;
        }
      }
    }
  }

  .Finance-main {
    .Finance-section1 {
      height: auto !important;
      padding: 4rem 0;
    }

    .Finance-section2-new {
      height: auto !important;
      padding: 4rem 0 !important;
    }

    .Finance-section3-new {
      height: auto !important;
      padding: 4rem 0 !important;
    }

    .Finance-section4-new {
      height: auto !important;
      padding: 4rem 0 !important;
    }

    .Finance-section4 {
      .flexContainer {
        grid-template-columns: repeat(2, 45%) !important;
      }
    }
  }

  .ai-innovation-main {
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .test-animation-mobile {
        height: calc(100vh - 77px) !important;
      }
    }

    .ai-section2-new {
      height: auto !important;
      padding: 4rem 0;
      .margin-80 {
        width: 100% !important;
      }

      .heading .heading-supply-chain {
        width: 100% !important;
      }

      .GridLayoutNew {
        width: 80%;
        margin: auto;
        grid-template-columns: repeat(2, 1fr) !important;

        .flip-card {
          min-width: 220px !important;
        }
        .middle-item .flip-card {
          min-width: 200px !important;
        }
      }
    }
  }

  .career-body {
    .career-section1-new {
      height: auto !important;
      padding: 3rem 0;
      width: 90% !important;

      .section-box {
        display: grid !important;
        grid-template-columns: none !important;

        .grid-item1 {
          .heading {
            font-size: 20px !important;
            line-height: 24px !important;
            letter-spacing: 0.2px;
          }

          .content {
            font-size: 16px !important;
            letter-spacing: 0.2px;
            font-weight: 500;
            line-height: 20px !important;
          }
        }
      }

      .absolute-image {
        display: none !important;
      }
    }

    .career-section2 {
      height: auto !important;
      padding: 3rem 0 !important;

      .background-design {
        display: none !important;
      }

      .company-logos {
        grid-template-columns: repeat(3, 30%) !important;
        width: 80% !important;
        padding-top: 1rem !important;
      }

      .spanHeading {
        font-size: 20px !important;
        line-height: 20px !important;
        letter-spacing: 0.2px;
        text-align: left;
        width: 80% !important;
        margin: auto !important;
        display: block !important;
      }

      .content {
        font-size: 16px !important;
        letter-spacing: 0.2px;
        text-align: left;
        width: 80% !important;
        padding-bottom: 1rem !important;
      }

      .spanLast {
        width: 100% !important;
        text-align: center !important;
        font-size: 16px !important;
        padding: 1rem 0 !important;
        letter-spacing: 0.2px;
      }

      .background-design {
        img {
          width: 100px !important;
        }
      }
    }

    .career-section3 {
      height: auto !important;
      .container {
        padding: 0px !important;
        .heading {
          font-size: 20px !important;
          text-align: left !important;
        }
        .content {
          font-size: 18px !important;
          text-align: left !important;
        }

        .flex-container {
          .item {
            height: auto !important;
            width: 300px !important;
            overflow: hidden !important;
            img {
              height: auto !important;
              width: 300px !important;
              object-fit: fill !important;
              display: block;
            }
          }
        }
      }
    }

    .career-section4-new {
      height: auto !important;
      padding: 6rem 0rem !important;

      .section4-main {
        width: 90% !important;
        grid-template-columns: none !important;
        display: flex !important;
        flex-direction: column !important;

        .item1,
        .item2 {
          height: auto !important;
        }

        .item1 {
          .center {
            border-right: none !important;
            padding: 2rem 10px 0px 10px !important;
            .heading {
              font-size: 20px !important;
            }
          }
        }

        .item2 {
          .react-multi-carousel-list {
            height: 30vh !important;

            // margin-top: -6px !important;
          }
        }
      }

      .background-design {
        img {
          width: 100px !important;
        }

        @keyframes move {
          0% {
            top: 0% !important;
            left: 5% !important ;
          }
          50% {
            top: 100% !important;
            left: 5% !important ;
          }
          100% {
            top: 0% !important ;
            left: 5% !important ;
          }
        }
      }
    }

    .career-section5-new {
      height: auto !important;

      .main {
        .heading {
          font-size: 20px !important;
          padding-bottom: 1rem !important;
        }

        .title {
          font-size: 20px !important;
        }

        .position {
          font-size: 16px !important;
        }

        .experience {
          font-size: 16px !important;
        }

        .react-multi-carousel-list {
          height: 32vh;
          margin-top: -6px !important;
          padding-bottom: 2rem !important;
        }
      }
    }

    .career-section6-new {
      .main {
        .grid-container {
          height: 30vh !important;

          .item1 {
            .social-icons {
              left: 5% !important;
            }
          }

          .item2 {
            .container1,
            .container2,
            .container3,
            .container4 {
              .heading {
                font-size: 20px !important;
              }
            }
          }
        }
      }
    }

    .career-section7-new {
      .main {
        .heading {
          font-size: 20px !important;
          text-align: left !important;
        }
        .content {
          width: 100% !important;
          font-size: 16px !important;
          text-align: left !important;
          line-height: 21px !important;
          letter-spacing: 0.2px;
        }

        .contact-wrapper {
          justify-content: left !important;
        }
      }
    }

    .career-section3-new {
      .grid-box {
        padding: 2rem;
        grid-gap: 10px !important;

        .content {
          font-size: 16px !important;
          padding: 10px !important;
        }

        .heading {
          padding-bottom: 30px !important;
          padding-top: 30px !important;
          font-size: 24px !important;
        }
      }
    }
  }

  .career-main {
    .footerMenu {
      // grid-gap: 10px !important;
    }

    .test-animation-mobile .top-mobile img {
      height: 75% !important;
    }
  }

  .scroll-btn,
  .scroll-btn-down {
    display: none !important;
  }

  .test-animation-mobile {
    .main-container-mobile {
      .grid-container-mobile {
        height: 96vh !important;
      }
    }

    .top-mobile {
      img {
        position: absolute;
        width: 70% !important;
        object-fit: fill;
        animation: top 3s 1;
        top: 180px;
      }
      @keyframes top {
        0% {
          top: 0px;
        }
        100% {
          top: 200px !important;
        }
      }
    }
    .bottom-mobile {
      text-align: left !important;
      position: relative;
      .main-bottom {
        .heading-mobile {
          font-size: 35px !important;
          font-weight: bold;
        }
        .subHeading-mobile {
          font-size: 20px !important;
          margin-top: 1rem !important;
        }
      }

      .grid-image {
        img {
          width: 100% !important;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 600px !important;
        }
      }
    }
  }

  .supplychain-main-class {
    .flexContainer {
      grid-template-columns: repeat(2, 45%) !important;
      justify-items: center;
    }

    .section2-body {
      .seciton2-top {
        grid-template-columns: none !important;
      }
      .section2-bottom {
        grid-template-columns: none !important;
      }
    }
  }

  .success-story-page-section-3 {
    .success-story-page-section-3-grid
      .success-story-page-section-3-grid-items-texta {
      font-size: 18px !important;
    }
  }

  #section5-blog-success-story .new-cls-blogs {
    flex-wrap: wrap;
  }

  .customer-section1-main {
    .section2-new-css {
      grid-template-columns: repeat(3, 34%);
    }
  }

  .about-main {
    .about-section1 {
      width: 90% !important;
    }

    .about-section2 {
      .content {
        width: 90% !important;
      }

      .section2-image-div img {
        padding: 2rem !important;
      }
    }
  }

  .homepage-sections-padding {
    width: 90% !important;
  }

  .buttonWrapper {
    z-index: 9999;
  }

  .btn-read-more {
    margin-top: 3rem !important;
  }

  .secondpage_section2 {
    font-size: 20px !important;
  }

  .dataEngMobile {
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .test-animation-mobile {
        height: calc(100vh - 77px) !important;
      }
    }
  }

  .case-studies-pages-cls {
    .test-animation-mobile {
      height: calc(100vh - 85px) !important;
    }
  }

  .blogspage-cls {
    .test-animation-mobile {
      height: calc(100vh - 82px) !important;
    }
  }

  .newsroomPageCss {
    .test-animation-mobile {
      height: calc(100vh - 82px) !important;
    }
  }

  .contactUs {
    .test-animation-mobile {
      height: calc(100vh - 100px) !important;
    }

    @media only screen and (min-width: 1024px) {
      .test-animation-mobile {
        height: calc(100vh - 76px) !important;
      }
    }
  }
}
.case-studies-pages-cls  .smobile{
  background-color: white !important;
}
.case-studies-pages-cls .customer-success-section2-mobile .textheader{
  color: black!important;
}


.case-studies-pages-cls .customer-success-section2-mobile .second-box .box-detail .headbox{
 color:black !important;
}

.case-studies-pages-cls .customer-success-section2-mobile .second-box .box-detail .contebox {
color: black !important;
}