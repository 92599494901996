.supply-padding {
  display: block;
  width: 80%;
  margin: auto;
}
.supply-body {
  background-color: black;
  color: white;
}

.supply-spacing {
  color: white;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.section1-body {
  background-color: #212121;
  position: relative;

  h1 {
    text-align: center;
    padding-bottom: 2rem;
    font-size: 30px;
  }

  .flexContainer {
    display: grid;
    grid-template-columns: repeat(4, 24%);
    justify-content: center;
    grid-gap: 1rem;

    .read-more-icon {
      svg {
        path {
          fill: white;
        }  
      }
  
      &:hover {
        svg {
          path {
            fill: #bb1ccc;
          }  
        }
      }
    }
  }
}

.section2-body {
  background-color: black;

  .section2-top {
    margin-bottom: 7rem;
  }

  .seciton2-top {
    padding: 3rem 0;
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 3rem;
    align-items: center;
    align-content: center;
    justify-content: center;

    .top-left {
      color: white;

      .heading {
        font-size: 30px;
      }
      .text {
        font-size: 18px;
        letter-spacing: 0.2px;
        line-height: 24px;
      }
    }
  }

  .section2-bottom {
    display: grid;
    padding: 3rem 0 5rem 0;
    grid-template-columns: 50% 50%;
    gap: 3rem;
    align-items: center;
    align-content: center;

    .bottom-right {

      .heading {
        font-size: 30px;
      }
      .text {
        font-size: 18px;
        letter-spacing: 0.2px;
        line-height: 24px;
      }
    }
  }
}

.supplychain-main-class {
  img {
    max-width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    height: auto;
  }
  

  .layoutGrid{
    background-position: center;
  }

  .supply-spacing {
    h1 {
      padding-bottom: 4rem;
      padding-top: 2rem;
    }
    span {
      padding-bottom: 10px;
      display: block;
    }
  }
}
.section2-body {
  img {
    width: 100% !important;
  }
}

.heading-supply-chain {
  font-size: 30px;
  font-weight: bold;
}

.text {
  font-weight: normal;
}
