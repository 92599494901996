.newBlog-body {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  justify-content: center;

  .newBlog-margin {
    padding: 7rem 0 0rem 0;
    .newBlog-main {
      .back-button {
        display: flex;
        width: 80%;
        margin: auto;
            padding: 0rem 4rem 1rem;

            .back{
              display: flex;
              .content{
                font-size: 14px;
              }
            }
      }
      .firstSection {
        display: flex;
        justify-content: center;
        .main {
          width: 80%;
          min-height: auto;
          padding: 0 4rem 0rem;

          .heading {
            color: black;
            font-size: 30px;
            font-weight: bold;
          }

          .date-read-container {
            .date-container {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              align-content: center;
              font-size: 12px;
              padding: 10px 5px;
              color: black;
              gap: 20px;
              .read {
                display: flex;
                align-items: center;
                align-content: center;
                gap: 5px;
              }
            }
          }

          .box-form {
            height: 400px !important;
            width: 100%;
            // background-color: white;
            margin-top: 1rem;
            display: flex;

            .left {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 70%;
              background-color: black;
              background-image: url("../../../assets/img/genai/network-01.png");
              background-repeat: no-repeat;

              // img{
              //     height: 100%;
              //     width: 100%;
              //     object-fit: contain;

              // }
              .badge {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;

                img {
                  height: 200px;
                  // width: 100px;
                }
                .content {
                  text-align: center;
                  color: black;
                  font-weight: bold;
                  font-size: 30px;
                }
              }
            }
            .right {
              width: 30%;
              background-color: #bb1ccc;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;

              .genai-form {
                // height: 90%;
                // background-color: palegoldenrod;

                width: 90%;
                .aays-logo {
                  //   margin-bottom: 2rem;
                  img {
                    width: 100px;
                  }
                }
                .form {
                  //   margin-top: 30px;
                  #fullName,
                  #email {
                    width: 100%;
                    height: 40px;
                    border: none;
                    outline: none;
                    margin-top: 10px;
                    padding: 10px 10px;
                    border-radius: 10px;
                    font-family: Montserrat;
                  }
                  .same-line {
                    display: flex;
                    justify-content: space-between;
                    // gap: 10%;
                    margin-top: 10px;

                    #companyName,
                    #designation {
                      width: 48%;
                      padding: 10px 10px;
                      border-radius: 10px;
                      height: 40px;
                      border: none;
                      outline: none;
                      font-family: Montserrat;
                    }
                  }
                  .button {
                    text-align: center;
                    margin-top: 10px;
                    button {
                      padding: 10px 25px;
                      border: none;
                      outline: none;
                      border-radius: 10px;
                      color: #75758b;
                      font-family: Montserrat;
                    }
                    :hover {
                      color: white;
                      background-color: black;
                    }
                  }
                }
              }
            }
          }
          .best-firm-background {
            background-image: url("../../../assets/img/Workplace\ Leader.rectangle.black.jpg");
            background-repeat: no-repeat;
            background-size: contain;
          }
          .FT-background {
            background-image: url("../../../assets/img/Aays_Financial\ Times_Statista.rectangle.jpg");
            background-repeat: no-repeat;
            background-size: contain;
          }
          .investInAI-background {
            background-image: url("../../../assets/img/Aays_Gen\ AI\ Investment\ Strategies.rectangle.jpg");
            background-repeat: no-repeat;
            background-size: contain;
          }
          .genAiPriority-background {
            background-image: url("../../../assets/img/Aays_Gen\ AI\ Use\ Cases.Rectangle.jpg");
            background-repeat: no-repeat;
            background-size: contain;
          }
          .genAiPriorityai-background{
            background-image: url("../../../assets/img/leverage-caseai.jpg") ;
            background-repeat: no-repeat;
            background-size: contain;
          }
          .roleofai-background{
            background-image: url("../../../assets/img/Blog\ -\ AI\ and\ Inventory\ Management\ -\ Aays.png");
            background-repeat: no-repeat;
            background-size: contain;
          }

          .content1 {
            font-size: 18px;
            color: black;
            padding: 1rem 0;

            .text1 {
              padding-bottom: 1rem;
              font-weight: 500;
              line-height: 1.5;
            }
          }
        }
      }
      .secondSection {
        min-height: auto;
        background-color: white;
        display: flex;
        justify-content: center;

        .main {
          width: 80%;
          padding: 0rem 4rem;

          color: black;

          .heading {
            font-size: 30px;
            font-weight: bold;
            padding-top: 2rem;
            span {
              color: black;
            }
          }

          .container {
            padding: 0;
            padding-top: 2rem;
            display: flex;
            justify-content: center;
            height: 80%;

            .left {
              width: 45%;
              padding-right: 2rem;
              padding-top: 2rem;
              display: flex;
              justify-content: space-between;
              flex-direction: column;

              .content {
                .content1,
                .content2 {
                  padding-top: 20px;
                  font-size: 18px;
                  font-weight: 500;
                  line-height: 1.5;
                }
              }
              .lastLine {
                span {
                  a {
                    color: #bb1ccc;
                  }
                }
              }
            }
            .right-img {
              width: 55%;
              object-fit: contain;
              // background-color: white;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }
      .thirdSection {
        display: flex;
        justify-content: center;
        background-color: white;
        color: black;
        .main {
          width: 80%;
          padding: 4rem 4rem;

          .heading {
            font-size: 30px;
            font-weight: bold;
           // font-weight: 500;
            line-height: 1.5;
            
          }
          .content {
            padding-top: 1rem;
            font-size: 18px;
          }
          .text {
            color: #bb1ccc;
            margin: 1rem 0;
            font-size: 18px;
          }

          .lastPoint {
            margin-left: 60px;
            font-style: italic;
            margin-top: 3rem;
            font-weight: 500;
            line-height: 1.5;

            span {
              a {
                color: #bb1ccc;
              }
            }
          }
        }
      }
      .quotes {
        background-color: white;
        display: flex;

        .main {
          padding-left: 4rem;
          width: 80%;
          min-height: auto;
          margin: auto;
          color: black;
          // display: flex;
          // flex-direction: column;
          // align-items: center;
          // gap: 5rem;

          .heading {
            font-size: 30px;
            padding-top: 2rem;
            font-weight: bold;
          }
          .sheading {
            font-size: 20px;
            padding-top: 3rem;
            font-weight: bold;
            border-bottom: 2px solid #bb1ccc;
            display: inline-block;
           
          }
          .subHeading {
            font-size: 18px;
            padding-top: 1rem;
          }

          .content {
            padding-bottom: 3rem;
            color: black;
            font-weight: 500;
            line-height: 1.5;
            .content1,
            .content2 {
              padding-top: 20px;
              font-size: 18px;
              font-weight: 500;
              line-height: 1.5;
              .link {
                color: #bb1ccc;
              }
            }
            .content-last {
              padding-top: 20px;
              padding-bottom: 2rem;
              font-size: 18px;
              font-weight: 500;
              line-height: 1.5;
            }
            .image {
              padding-top: 2rem;
              // padding-bottom: 2rem;
              width: 100%;
              img {
                width: 100%;
                height: auto;
              }
            }
            .bulletPoints {
              padding: 1rem 0;
              ul {
                li {
                  font-size: 18px;
                  padding-top: 1rem;
                  b{
                    color: black !important;
                  }
                }
               
              }
            }
          }

          .container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .quote-box {
              .heading {
                font-size: 22px;
                font-weight: 700;
                text-align: center;
              }
             
              .content {
                padding-top: 1rem;
                text-align: center;
                display: block;
                margin: auto;
                width: 80%;
                font-style: italic;
                font-size: 20px;
                span {
                  font-size: 25px;
                }
              }
            }
          }
        }
      }
    }
  }
  .flex-containersi{
    display: flex;
  }
  .leftssi{
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 50%;
  }
  .rightssi{
    align-items: center;
    display: flex;
    justify-content: flex-end;
    width: 50%;
  }
  .accordian-filessi{
    height: 90%;
    width: 95%;
  }
  .mainss-headss{
    color: black;
    font-size: 20px;
    font-weight: 600;
    padding-top: 1rem;
  }
  
  }

