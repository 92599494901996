.about-section5 {
  width: 80%;
  margin: auto;
  //padding: 3rem 0;//
  padding-top: 14rem;
  padding-bottom: 10rem;

  .grid-box-container {
    display: grid;
    grid-template-columns: 45% 55%;
    align-items: center;
    justify-content: center;
    background-color: #212121;
    border-radius: 4rem;
    padding: 2rem;
    position: relative;
    margin-top: 2rem;

    .left-container {
      display: flex;
    }

    .background-design {
      img {
        position: absolute;
        top: 10%;
        left: 2%;
        width: 120px;
        z-index: 0;
        animation-name: down;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }

      @keyframes down {
        0% {
          top: 0%;
          right: 10%;
        }
        50% {
          top: 10%;
          right: 10%;
        }
        100% {
          top: 0%;
          right: 10%;
        }
      }
    }

    .left {
      border-right: 4px solid white;
      padding: 5rem 4rem;

      .heading-text {
        color: white;
        font-size: 40px;
        font-weight: 700;
        text-align: left;
      }
    }

    .right {
      padding: 2rem 2rem;

      .about-customer-success {
        display: flex;
        align-items: center;
      }

      .box-heading {
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        color: #bb1ccc;
        text-align: center;
        padding-top: 46px;
      }

      .box-content {
        font-size: 18px;
        color: #ffffff;
        // font-weight: 500;
        text-align: center;
        padding: 1rem 2rem 2rem 2rem;
      }

      .read-more-btn {
        display: grid;
        justify-content: center;
      }

      .read-more-btn > button {
        background-color: #bb1ccc;
        padding: 8px;
        font-weight: 600;
        border: none;
        font-size: 12px;
        padding: 8px 16px;
        color: #ffffff;
      }

      .react-multi-carousel-list {
        height: 340px !important;
      }

      .react-multi-carousel-item {
        display: flex;
        align-items: center;
      }
    }
  }
}

.GenAi-body {
  img {
    max-width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    height: auto;
  }

  .blogImg {
    width: 100%;
    padding-bottom: 10px;
  }
  .imgContent {
    display: grid;
    grid-gap: 20px;
  }
}

.Finance-main {
  .Finance-page-main {
    img {
      max-width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      height: auto;
    }

    background-color: black;
    color: white;
  }

  .Finance-section1 {
    height: 90vh;
    display: grid;
    align-items: center;
    align-content: center;
    background-color: #212121;
    position: relative;

    .margin-box {
      width: 75% !important;
    }

    .finance-page-container {
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 3rem;
      align-items: center;
      align-content: center;
      justify-content: center;

      .heading-supply-chain {
        padding-bottom: 10px;
      }

      .right {
        color: white;
        text-align: left;
        display: grid;
        grid-gap: 10px;

        .heading {
          font-size: 30px;
        }
        .text {
          font-size: 18px;
        }
      }
    }
  }

  .Finance-section2-new {
    height: 90vh;
    display: grid;
    align-items: center;
    align-content: center;
    padding-top: 0rem;
    padding-bottom: 0rem;

    .imgAlignCenter {
      width: 55%;
      padding-top: 1rem;
      margin: auto;
      display: block;
    }

    .heading {
      .heading-supply-chain {
        text-align: center;
      }

      .text {
        font-size: 18px;
        text-align: center;
        padding: 2rem 0;
        width: 80%;
        margin: auto;
        letter-spacing: 0.2px;
        line-height: 24px;
      }
    }
  }

  .Finance-section3-new {
    background-color: #212121;
    height: 90vh;
    display: grid;
    align-items: center;
    align-content: center;
    padding-top: 0rem;
    padding-bottom: 0rem;
    position: relative;

    .imgAlignCenter {
      width: 100%;
      padding-top: 1rem;
      margin: auto;
      display: block;
    }

    .heading {
      .heading-supply-chain {
        text-align: center;
      }
      .text {
        font-size: 18px;
        text-align: center;
        padding: 2rem 0;
        width: 90%;
        margin: auto;
        letter-spacing: 0.2px;
        line-height: 24px;
      }
    }
  }

  .Finance-section4-new {
    height: 90vh;
    display: grid;
    align-items: center;
    align-content: center;
    padding-top: 0rem;
    padding-bottom: 0rem;

    .imgAlignCenter {
      width: 100%;
      padding-top: 1rem;
      margin: auto;
      display: block;
    }

    .heading {
      .heading-supply-chain {
        text-align: center;
      }
      .text {
        font-size: 18px;
        text-align: center;
        padding: 2rem 0;
        width: 80%;
        margin: auto;
        letter-spacing: 0.2px;
        line-height: 24px;
      }
    }
  }
  .Finance-section2 {
    background-color: #bb1ccc;
    .finance-page-container {
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 3rem;
      align-items: center;
      align-content: center;
      justify-content: center;

      .heading-supply-chain {
        padding-bottom: 10px;
      }

      .left {
        color: white;
        text-align: left;

        .heading {
          font-size: 30px;
        }
        .text {
          font-size: 18px;
        }
      }
    }
  }

  .Finance-section3 {
    .finance-page-container {
      display: grid;
      // justify-content: space-around;
      // flex-wrap: wrap;
      grid-template-columns: 50% 50%;
      gap: 3rem;
      align-items: center;
      align-content: center;
      justify-content: center;

      

      .heading-supply-chain {
        padding-bottom: 10px;
      }

      .left {
        color: white;
        text-align: left;

        .heading {
          font-size: 30px;
        }
        .text {
          font-size: 18px;
        }
      }
    }
  }

  .Finance-section4 {
    background-color: #212121;
    position: relative;

    h1 {
      text-align: center;
      padding-bottom: 2rem;
      font-size: 30px;
    }

    .flexContainer {
      display: grid;
      grid-template-columns: repeat(4, 24%);
      justify-content: center;
      grid-gap: 1rem;
      justify-items: center;

      .read-more-icon {
        svg {
          path {
            fill: white;
          }
        }

        &:hover {
          svg {
            path {
              fill: #bb1ccc;
            }
          }
        }
      }
    }
  }

  .Finance-section6 {
    .more {
      font-size: 30px;
      text-align: center;
      display: block;
      padding-top: 20px;
      font-weight: 600;
    }

    .flexContainer {
      display: flex;
      justify-content: center;
      grid-gap: 20px;
      flex-wrap: wrap;
    }

    .box {
      width: 270px;
      height: 295px;
      border: 3px solid #bb1ccc;
      border-radius: 10px;
      overflow: hidden;

      .top {
        height: 220px;
        background-color: #000000;
      }
      .bottom {
        height: 75px;
        background-color: #bb1ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 24px;
          text-align: center;
        }
      }
    }
  }
}

.dataEngMobile {
  .test-animation .main-container .grid-container .right img {
    height: 450px;
  }
}

.data-led-main {
  .test-animation {
    height: calc(100vh - 76px) !important;
  }
  .test-animation .main-container .grid-container .right img {
    height: 450px;
  }

  $grid-columns: 2;

  .grid-container {
    grid-template-columns: 55% 45% !important;
  }

  .layoutGrid {
    background-position: center !important;
  }
  .section2 {
    .section2Text {
      font-size: 19px;
      width: 75%;
      margin: auto;
      display: block;
    }
  }

  .margin-80 {
    display: block;
    margin: auto;
    width: 80%;
  }

  .header-text-new {
    padding-bottom: 2rem;
    padding-top: 4rem;
    font-size: 30px;
    color: white;
    text-align: center;
    font-weight: 700;
  }
  .header-text-new-1 {
    padding-bottom: 0rem;
    padding-top: 4rem;
    font-size: 30px;
    color: white;
    text-align: center;
    font-weight: 700;
  }
  .sub-header-text-new {
    padding-bottom: 2rem;
    padding-top: 1rem;
    font-size: 18px;
    color: white;
    text-align: center;
    font-weight: 500;
  }

  .data-led-section3 {
    background-color: #212121 !important;
    height: 90vh;
    display: grid;
    align-items: center;
    align-content: center;
    position: relative;

    .GridLayoutNew {
      justify-content: center;
      padding-top: 3rem;
      padding-bottom: 3rem;
      display: grid;
      grid-template-columns: repeat($grid-columns, 1fr);
      justify-content: center;
      gap: 20px; // Adjust the gap according to your preference
      .flip-card {
        text-align: center;
        color: white;
        display: grid;
        height: 400px;
        border-radius: 6px;
        font-size: 24px;
        font-weight: 700;
        max-width: 100%;
        min-width: 250px;
      }

      .flip-card-food {
        background-color: #000000;
      }

      .middle-item {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px; // Adjust the gap according to your preference
        .flip-card {
          height: auto;
          min-width: 230px;
        }
      }
    }

    .flip-card {
      background-color: transparent;
      width: 100%;
      height: 100%;
      perspective: 1000px;
      border-radius: 6px;
      cursor: pointer;
    }

    .flip-card-inner {
      position: relative;
      width: 100%;
      height: auto;
      text-align: center;
      transition: transform 0.6s;
      transform-style: preserve-3d;
    }

    .flip-card:focus {
      outline: 0;
    }

    .flip-card:hover .flip-card-inner,
    .flip-card:focus .flip-card-inner {
      transform: rotateY(180deg);
    }

    .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
    }

    .flip-card-front {
      background: transparent;
      color: white;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
      border: 2px solid #bb1ccc;
    }

    .flip-card-back {
      color: white;
      transform: rotateY(180deg);
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      font-size: 16px !important;
      border: 2px solid #bb1ccc;
      background-color: #bb1ccc !important;
    }

    .landing_page_subheader {
      font-size: 16px !important;
      padding: 5px;
      font-weight: 300 !important;
    }
  }
  .data-led-section4 {
    background-color: #000000;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;

    .grid-layout-section4 {
      display: grid;
      grid-gap: 10px;
      justify-content: center;
      align-items: center;
      align-content: center;
      grid-template-columns: repeat(2, 50%);

      .right {
        color: white;
        font-size: 18px;
        display: grid;
        grid-gap: 10px;

        .heading {
          p {
            font-size: 16px;
            padding-top: 10px;
          }
          .heading-supply-chain {
            font-size: 20px;
            color: #bb1ccc;
          }
        }
      }
    }
  }
  .data-led-section5 {
    background-color: #212121;
    height: 90vh;
    display: grid;
    align-items: center;
    align-content: center;
    position: relative;

    .header-text-new {
      padding-top: 0px;
    }
    .flex-box-additional-key-main {
      display: flex;
      justify-content: center;
      justify-items: center;
      grid-gap: 10px;
      align-items: center;
      align-content: center;
      position: relative;
      margin-top: 4rem;
    }
    .flex-box-additional-key-main::after {
      content: ""; /* You need content to use pseudo-elements like ::after */
      position: absolute !important;
      width: 70% !important;
      height: 2px !important ;
      background-color: white !important;
      left: 0;
      right: 0;
      z-index: 1;
      margin: auto;
      top: 50px;
    }

    .flex-box-additional-key {
      display: flex;
      grid-template-columns: 30% 5% 30%;
      color: white;
      justify-content: center;
      justify-items: center;
      grid-gap: 10px;
      padding-top: 2rem;
      align-items: center;
      z-index: 100;
      background-color: #212121;

      .angleLeft {
        background-color: #bb1ccc;
        align-items: center;
        display: grid;
        border-radius: 100%;
        width: 30px;
        height: 30px;
        justify-content: center;
        z-index: 10;
      }
      .DataEngagemnts {
        position: relative;
        z-index: 10;
      }

      div {
        background-color: #000000;
        padding: 10px;
        padding-left: 2rem;
        padding-right: 2rem;
        border-radius: 30px;
        font-size: 20px;
        font-weight: 700;
        position: relative;
        z-index: 10;
      }
    }

    .data-led-section-grid {
      display: grid;
      grid-template-columns: repeat(5, 16%);
      justify-content: center;
      justify-items: center;
      gap: 10px;
      padding-top: 4rem;
      padding-bottom: 2rem;

      .feature-icon {
        width: 70px;
        height: 70px;
      }
      .image-div {
        width: 120px;
        height: 120px;
        border: 2px solid #eee;
        display: grid;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        align-items: center;
        align-content: center;

        &:hover {
          background-color: #bb1ccc;
          cursor: pointer;
          transition: 2s;
        }
      }
      .text-icon {
        width: 90%;
        display: block;
        margin: auto;
      }
      .item-img {
        display: grid;
        justify-content: center;
        justify-items: center;
        gap: 20px;
        align-items: flex-start;
        align-content: baseline;
        span {
          color: white;
          text-align: center;
          display: grid;
          justify-content: center;
        }
      }
    }
  }
  .data-led-section6 {
    background-color: #000000;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    img {
      width: 100%;
      max-width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      height: auto;
    }
    .success-story-page-section-4-grid {
      grid-template-columns: repeat(3, 24%);
      grid-gap: 20px;
      position: relative;
      padding-top: 2rem;
      padding-bottom: 2rem;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .success-story-page-section-4-grid-items {
        display: grid;
        justify-content: center;
        margin-right: 5px;
        width: 280px;
      }

      .success-story-page-section-4-grid-items-text {
        background-color: #212121;
        color: white;
        text-align: center;
        padding: 10px;
        min-height: 100px;
        align-items: center;
        display: grid;
        font-size: 18px;

        &:hover {
          background-color: #bb1ccc;
        }
      }
      .btn-read-more {
        div {
          background-color: #bb1ccc;
          padding: 10px;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
}

.ai-innovation-main {
  .test-animation .main-container .grid-container .right img {
    height: 450px;
  }

  .layoutGrid {
    background-position: center !important;
    grid-template-columns: 50% 50% !important;
  }

  .margin-80 {
    display: block;
    margin: auto;
    width: 80%;
  }

  .landing_page_subheader {
    font-size: 16px !important;
    padding: 5px;
    font-weight: 300 !important;
  }
  .header-text-new {
    padding-bottom: 2rem;
    padding-top: 4rem;
    font-size: 30px;
    color: white;
    text-align: center;
    font-weight: 700;
  }
  .header-text-new-1 {
    padding-bottom: 0rem;
    padding-top: 4rem;
    font-size: 30px;
    color: white;
    text-align: center;
    font-weight: 700;
  }
  .sub-header-text-new {
    padding-bottom: 2rem;
    padding-top: 1rem;
    font-size: 18px;
    color: white;
    text-align: center;
    font-weight: 500;
  }

  .ai-section1-new {
    background-color: #212121;
    height: auto;
    display: grid;
    align-items: center;
    align-content: center;
    padding-top: 2rem;
    padding-bottom: 3rem;
    position: relative;

    .imgAlignCenter {
      padding-top: 3rem;
      padding-bottom: 2rem;
    }

    .heading {
      .heading-supply-chain {
        font-size: 30px;
        color: white;
        text-align: center;
        padding-top: 2rem;
        margin: auto;
        display: block;
      }
    }
  }

  .ai-section2-new {
    background-color: #000000;
    display: grid;
    align-items: center;
    align-content: center;
    height: 90vh;

    .flip-box-header-text {
      font-size: 18px;
      display: block;
      margin: auto;
      width: 90%;
    }
    .margin-80 {
      display: block;
      margin: auto;
      width: 70%;
    }
    .flip-card-front {
      img {
        width: 70px !important;
      }
    }

    .GridLayoutNew {
      justify-content: center;
      padding-top: 3rem;
      padding-bottom: 3rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: center;
      gap: 20px; // Adjust the gap according to your preference
      .flip-card {
        text-align: center;
        color: white;
        display: grid;
        // align-items: center;
        // align-content: center;
        height: 400px;
        border-radius: 6px;
        font-size: 24px;
        font-weight: 700;
        max-width: 100%;
        min-width: 250px;
      }

      .flip-card-food {
        background-color: #000000;
      }

      .middle-item {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px; // Adjust the gap according to your preference
        .flip-card {
          height: auto;
          min-width: 230px;
        }
      }
    }

    .heading {
      p {
        font-size: 16px;
        padding-top: 10px;
      }
      .heading-supply-chain {
        font-size: 30px;
        color: white;
        text-align: center;
        padding-top: 2rem;
        width: 70%;
        margin: auto;
        display: block;
      }
    }
    .ai-flip-list {
      display: grid;
      justify-content: flex-start;
      text-align: left;
      padding-left: 1rem;
      grid-gap: 10px;
      font-weight: normal;

      span {
        position: relative;
        &:before {
          content: "\2022"; /* Unicode character for bullet point */
          color: #ffffff; /* Bullet point color */
          font-size: 50px; /* Adjust the size as needed */
          position: absolute;
          left: -1rem; /* Adjust the distance from the text */
          top: -25px;
        }
      }
    }

    .flip-card {
      background-color: transparent;
      width: 100%;
      height: 100%;
      perspective: 1000px;
      border-radius: 6px;
      cursor: pointer;
    }

    .flip-card-inner {
      position: relative;
      width: 100%;
      height: auto;
      text-align: center;
      transition: transform 0.6s;
      transform-style: preserve-3d;
    }

    .flip-card:focus {
      outline: 0;
    }

    .flip-card:hover .flip-card-inner,
    .flip-card:focus .flip-card-inner {
      transform: rotateY(180deg);
    }

    .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
    }

    .flip-card-front {
      background: transparent;
      color: white;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
      border: 2px solid #bb1ccc;
    }

    .flip-card-back {
      color: white;
      transform: rotateY(180deg);
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      font-size: 16px !important;
      border: 2px solid #bb1ccc;
      background-color: #bb1ccc !important;
    }
  }
}

.career-main {
  .test-animation .main-container .grid-container .right img {
    height: 500px;
  }

  background-color: black;
  color: white;

  .layoutGrid {
    grid-template-columns: 50% 50% !important;
  }

  .padding-both {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .margin-both {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .career-body {
    display: block;
    margin: auto;
    // width: 80%;

    .career-section1-new {
      display: flex;
      margin: auto;
      width: 70%;
      // padding: 6rem 0;
      height: 90vh;
      align-items: center;
      position: relative;
      overflow: hidden;

      .section-box {
        background-color: #bb1ccc;
        border-radius: 20px;
        display: grid;
        grid-template-columns: repeat(2, 50%);

        .grid-item1 {
          padding: 2rem;
          .heading {
            line-height: 40px;
            font-size: 30px;
            font-weight: bold;
          }

          .content {
            padding-top: 1.5rem;
            font-size: 18px;
          }

          .button-wrapper {
            padding: 8px 10px;
            background-color: black;
            display: block;
            margin-top: 1rem;
            color: white;
            font-weight: bold;
            outline: none;
            border: none;
            font-size: 16px;
          }
        }
      }
      .absolute-image {
        height: 500px;
        position: absolute;
        right: 5%;
        bottom: 15%;
      }
    }

    .career-section2 {
      padding: 6rem 0;
      width: 100% !important;
      position: relative;
      background-color: #212121;
      z-index: 2;
      display: grid;
      align-items: center;
      height: 90vh;
      overflow: hidden;
      z-index: 999;

      .company-logos {
        grid-template-columns: repeat(9, 10%);
        display: grid;
        grid-gap: 10px;
        justify-content: center;
        width: 90%;

        .company-logos-items {
          height: 100px;
          border: 2px solid #bb1ccc;
          color: white;
          display: grid;
          justify-content: center;
          align-items: center;
          justify-items: center;
          align-content: center;
          background-color: white;

          img {
            width: 100%;
            max-width: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            height: auto;
            // display: none;
          }
        }
      }

      .spanHeading {
        font-size: 30px;
        line-height: 40px;
        font-weight: bold;
      }

      .content {
        width: 60%;
        display: block;
        margin: auto;
        font-size: 18px;
        padding-top: 1rem;
      }

      .spanLast {
        width: 100%;
        display: block;
        font-size: 24px;
        padding-top: 5rem;
        font-weight: bold;
      }

      .background-design {
        img {
          width: 150px;
          position: absolute;
          top: 0%;
          right: 10%;
          z-index: 0;
          animation-name: down;
          animation-duration: 10s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }

        @keyframes down {
          0% {
            top: 0%;
            right: 10%;
          }
          50% {
            top: 10%;
            right: 10%;
          }
          100% {
            top: 0%;
            right: 10%;
          }
        }
      }
    }

    .career-section2-new {
      padding: 6rem 0;
      width: 100%;
      position: relative;
      background-color: #212121;
      height: 90vh;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;

      .grid-container {
        width: 85%;
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;
        justify-content: center;
        margin: auto;

        .left {
          padding: 2rem;

          .heading {
            font-size: 30px;
            line-height: 40px;
            font-weight: bold;
          }

          .content {
            margin: auto;
            font-size: 18px;
            padding-top: 1rem;
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .text {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #bb1ccc;
            font-size: 18px;
            font-weight: 500;
            margin: auto;
            width: 70%;
            text-align: center;
            margin-bottom: 1rem;
          }

          .company-logos {
            display: grid;
            grid-template-columns: repeat(3, 28%);
            grid-gap: 10px;
            justify-content: center;
            align-items: center;
            width: 100%;

            .company-logos-items {
              height: 100px;
              border: 2px solid white;
              color: white;
              display: grid;
              justify-content: center;
              align-items: center;
              justify-items: center;
              background-color: white;
            }
          }
        }
      }
    }

    .career-section3 {
      width: 80%;
      display: block;
      margin: auto;
      height: 90vh;

      .container {
        .heading {
          font-size: 30px;
          font-weight: bold;
        }

        .content {
          font-size: 18px;
          padding-top: 1rem;
        }

        .flex-container {
          display: flex;
          justify-content: center;
          padding-top: 3rem;

          .item {
            height: 350px;
            border: 5px solid#bb1ccc;
            border-radius: 20px;
            overflow: hidden;
            width: auto;

            img {
              height: 350px;
              width: auto;
              object-fit: fill;
              filter: grayscale(1);
            }
          }
        }
      }
    }

    .career-section3-new {
      padding: 2rem 0;
      position: relative;
      width: 100%;
      height: 90vh;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;

      .grid-box {
        display: grid;
        grid-template-columns: repeat(3, 33%);
        grid-gap: 4px;
        justify-content: center;
        align-items: center;
        width: 100%;

        .heading {
          height: 100%;
          font-size: 35px;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .content {
          height: 100%;
          font-size: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2rem 4rem;
        }

        .image-box {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }
      }
    }

    .career-section4-new {
      background-color: #212121;
      // padding: 6rem 0;
      position: relative;
      height: 90vh;
      display: flex;
      align-items: center;

      .section4-main {
        position: relative;
        background-color: black;
        border-radius: 20px;
        width: 70%;
        margin: auto;
        display: grid;
        grid-template-columns: 45% 55%;
        padding: 3rem 0;
        .item1,
        .item2 {
          height: auto !important;
          padding: 1rem 0;
        }

        .item1 {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .center {
            border-right: 5px solid white;
            padding: 3rem 20px;

            .heading {
              text-align: center;
              font-size: 30px;
              font-weight: bold;
            }

            p {
              text-align: center;
              font-size: 18px !important;
              padding-top: 2rem;
            }
          }
        }
        .item2 {
          .react-multi-carousel-list {
            height: 43vh;
          }
        }
        .twoitem{
          .react-multi-carousel-list {
            height: auto!important;
          }
      }
      }
      .background-design {
        img {
          width: 150px;
          position: absolute;
          top: 0%;
          left: 5%;
          animation-name: move;
          animation-duration: 10s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
        @keyframes move {
          0% {
            top: 0%;
            left: 0%;
          }
          50% {
            top: 10%;
            left: 0%;
          }
          100% {
            top: 0%;
            left: 0%;
          }
        }
      }
    }

    .career-section5-new {
      background-color: black;
      height: 90vh;
      display: flex;
      align-items: center;

      .main {
        width: 80%;
        display: block;
        margin: auto;

        .heading {
          font-size: 30px;
          font-weight: bold;
          text-align: center;
          padding-bottom: 1rem;
        }

        .react-multi-carousel-list {
          height: 48vh !important;
        }
      }
    }

    .career-section6-new {
      background-color: #212121;
      padding: 6rem 0;
      position: relative;

      .main {
        width: 80%;
        display: block;
        margin: auto;
        background-color: #000000;
        border-radius: 20px;
        // height: 70vh;
        position: relative;

        .grid-container {
          height: 70vh;
          display: grid;
          grid-template-columns: 50% 50%;
          position: relative;
          padding: 2rem 0;

          .item1 {
            position: relative;
            display: flex;
            align-items: center;

            .social-icons {
              display: flex;
              flex-direction: column;
              grid-gap: 10px;
              position: absolute;
              left: 10%;

              img {
                height: 35px;
              }
            }

            .main-image,
            .main-image1,
            .main-image2,
            .main-image3 {
              position: absolute;
              width: 65%;
              right: 10%;
              border-radius: 1rem;
              opacity: 0;
            }
            .main-image {
              animation: pop 16s infinite;
            }
            .main-image1 {
              animation: pop 16s 4s infinite;
            }
            .main-image2 {
              animation: pop 16s 8s infinite;
            }
            .main-image3 {
              animation: pop 16s 12s infinite;
            }
            @keyframes pop {
              0% {
                opacity: 0;
              }
              25% {
                opacity: 1;
              }
              40% {
                opacity: 0;
              }
              100% {
                opacity: 0;
              }
            }
          }

          .item2 {
            position: relative;
            display: grid;
            align-items: center;
            border-left: 4px solid white;
            .container1,
            .container2,
            .container3,
            .container4 {
              text-align: center;
              position: absolute;
              padding: 2rem;
              opacity: 0;

              .heading {
                font-size: 30px;
                font-weight: bold;
              }
              .content {
                font-size: 18px;
                padding-top: 1rem;
              }
              .tags {
                font-size: 18px;
                font-weight: bold;
                padding-top: 1rem;
              }
            }
            .container1 {
              animation: popUp 16s infinite;
            }
            .container2 {
              animation: popUp 16s 4s infinite;
            }
            .container3 {
              animation: popUp 16s 8s infinite;
            }
            .container4 {
              animation: popUp 16s 12s infinite;
            }
            @keyframes popUp {
              0% {
                opacity: 0;
              }
              25% {
                opacity: 1;
              }
              40% {
                opacity: 0;
              }
              100% {
                opacity: 0;
              }
            }
          }
        }
      }
    }

    .career-section7-new {
      background-color: #000000;
      color: white;

      .main {
        width: 80%;
        margin: auto;
        display: block;

        .heading {
          font-size: 30px;
          font-weight: bold;
        }

        .content {
          width: 80%;
          display: block;
          margin: auto;
          font-size: 18px;
          padding-top: 2rem;
        }

        .contact-wrapper {
          display: flex;
          justify-content: center;
          position: relative;

          .contact-us-btn {
            outline: none;
            border: none;
            padding: 5px 10px;
            background-color: #bb1ccc;
            color: white;
            font-size: 20px;
            margin-top: 2rem;
            cursor: pointer;
            font-family: "Montserrat";
            font-weight: bold;
          }
          .applyJobsBtncls {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.automotive-main {
  .test-animation .main-container .grid-container .right img {
    height: 420px;
  }

  .text_overFlow {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    height: auto;
    overflow: hidden;
  }

  .layoutGrid {
    grid-template-columns: 55% 45% !important;
    background-position-y: center;
  }

  .automotive-section1 {
    height: 90vh;
    display: grid;
    align-items: center;
    align-content: center;
    background-color: #000000;

    // .margin-box {
    //   width: 75% !important;
    // }

    .header-text {
      color: white;
      font-size: 30px;
      text-align: center;
      padding-bottom: 4rem;
      font-weight: 700;
    }

    .finance-page-container {
      display: grid;
      // justify-content: space-around;
      // flex-wrap: wrap;
      grid-template-columns: 50% 50%;
      gap: 3rem;
      align-items: center;
      align-content: center;
      justify-content: center;

      .heading-supply-chain {
        padding-bottom: 10px;
      }

      .right {
        color: white;
        text-align: left;
        display: grid;
        grid-gap: 10px;

        .heading {
          font-size: 30px;
        }
        .text {
          font-size: 18px;
        }
      }
    }
  }

  .automotive-section2 {
    height: 95vh;
    display: grid;
    align-items: center;
    align-content: center;
    background-color: #212121;
    position: relative;

    .margin-box {
      width: 75%;
    }

    .header-text {
      color: white;
      font-size: 30px;
      text-align: center;
      padding-bottom: 1rem;
      font-weight: 700;
    }

    .text {
      font-size: 18px;
      color: white;
      text-align: center;
      padding-bottom: 3rem;
    }

    .finance-page-container {
      display: grid;
      // justify-content: space-around;
      // flex-wrap: wrap;
      grid-template-columns: 50% 50%;
      gap: 3rem;
      align-items: center;
      align-content: center;
      justify-content: center;

      .heading-supply-chain {
        padding-bottom: 10px;
      }

      .right {
        color: white;
        text-align: left;
        display: grid;
        grid-gap: 10px;

        .heading {
          font-size: 30px;
        }
        .text {
          font-size: 18px;
        }
      }
    }
  }

  .automotive-section3 {
    height: 90vh;
    display: grid;
    align-items: center;
    align-content: center;
    background-color: #bb1ccc;
    position: relative;

    .margin-box {
      width: 80%;
    }

    .header-text {
      color: white;
      font-size: 30px;
      text-align: center;
      padding-bottom: 1rem;
      font-weight: 700;
    }
    .text {
      font-size: 18px;
      color: white;
      text-align: center;
      padding-bottom: 3rem;
    }

    .automotive-section3-grid {
      display: grid;
      grid-template-columns: repeat(4, 24%);
      grid-gap: 10px;
      justify-content: center;
      text-align: center;
      align-items: flex-start;

      .automotive-section3-item-box {
        display: grid;
        gap: 10px;
        border-right: 5px solid #212121;
        padding: 5px;
        padding-left: 15px;
        padding-right: 15px;

        &:last-child {
          border-right: none;
        }
        .item-header {
          font-size: 24px;
          font-weight: 700;
          color: white;
        }
        .item-content {
          color: white;
          font-size: 18px;
        }
      }
    }
  }
}

.about-main {
  .test-animation .main-container .grid-container .right img {
    height: 550px;
  }

  background-color: black;
  color: white;

  .padding-both {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .about-section1 {
    margin: auto;
    width: 80%;
    display: grid;
    justify-content: center;
    align-content: center;
    align-items: center;
    grid-template-columns: repeat(2, 45%);
    grid-gap: 2rem;

    .header-text {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 1rem;
    }

    .content {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.2px;
      line-height: 26px;
    }
  }

  .about-section2 {
    background-color: #212121;
    margin: auto;
    width: 80%;
    position: relative;

    .header-text {
      font-size: 30px;
      color: white;
      text-align: center;
      font-weight: 700;
    }

    .content {
      padding-top: 1rem;
      font-size: 18px;
      color: white;
      text-align: center;
      font-weight: 500;
      letter-spacing: 0.2px;
      line-height: 24px;
    }

    .section2-image-div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .about-section3 {
    .first-section {
      margin: auto;
      width: 80%;
      display: grid;
      justify-content: center;
      align-content: center;
      align-items: center;
      grid-template-columns: 60% 30%;
      grid-gap: 4rem;
      margin-bottom: 2rem;
      padding: 2rem 0;

      .box-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 4rem;
      }

      .right-box {
        display: flex;
        flex-direction: column;
        gap: 2rem;
      }

      .first-box {
        width: 100%;
        background-color: #212121;
        padding: 2rem 4rem;
        border-radius: 24px;
        white-space: nowrap;
        position: relative;
        left: 8%;
      }

      .second-box {
        width: 100%;
        padding: 2rem 4rem;
        border-radius: 24px;
        background-color: #171616;
        white-space: nowrap;
      }

      .third-box {
        width: 100%;
        background-color: #171616;
        padding: 2rem 4rem;
        white-space: nowrap;
        border-radius: 24px;
      }

      .box-heading {
        font-size: 30px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        margin-bottom: 12px;
      }

      .item-text {
        font-size: 18px;
        font-weight: 500;
        padding: 1rem 0 1rem 1rem;
        cursor: pointer;
      }

      .box-item {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .header-text {
        width: 80%;
        font-size: 30px;
        color: white;
        text-align: center;
        font-weight: 700;
        margin: auto;
      }
    }

    .second-section {
      margin-bottom: 4rem;
      position: relative;
    }

    .third-section {
      margin: auto;
      width: 90%;
      padding: 0rem 4rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
      position: relative;

      .third-section-box {
        display: flex;
        margin: 2rem 0;
      }

      .image-div {
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 4px;
        border-radius: 4px;
        border: 1px solid white;
      }

      .content-box {
        display: grid;
        align-items: center;
        align-content: center;
        color: white;
        margin-left: 12px;

        a {
          font-size: 14px !important;
          line-height: 20px !important;
        }
        p {
          line-height: 15px !important;
          font-size: 12px !important;
        }
      }
    }

    .fourth-section {
      margin: auto;
      width: 90%;
      min-height: 40vh;

      .header-text {
        color: white;
        font-size: 30px;
        text-align: center;
        padding-bottom: 2rem;
        font-weight: 700;
      }
      .text {
        font-size: 18px;
        color: white;
        text-align: center;
        padding-bottom: 3rem;
      }

      .fourth-section-grid {
        display: grid;
        grid-template-columns: repeat(3, 33%);
        justify-content: center;
        text-align: center;

        .fourth-section-item-box {
          border-right: 4px solid #bb1ccc;
          padding: 24px;

          .item-content {
            color: white;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.2px;
          }

          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }

  .about-section4 {
    background-color: #212121;
    margin: auto;
    width: 100%;
    padding-bottom: 5rem;

    .grid-box {
      border-radius: 30px;
      padding: 1.5rem;
      width: 80%;
      margin: auto;
    }

    .header-text {
      width: 80%;
      font-size: 30px;
      color: white;
      text-align: center;
      font-weight: 700;
      margin: auto;
      margin-bottom: 2rem;
    }

    .grid-item {
      display: grid;
      grid-template-columns: 30% 65%;
      align-items: start;
      grid-gap: 2rem;
      margin-top: 1rem;
      padding: 1rem;

      .grid-item-content {
        margin-left: 1.5rem;
        padding-top: 1rem;
      }

      .grid-item-name {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 16px;
      }

      .grid-item-role {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 16px;
      }

      .image-div {
        width: 100%;
        display: flex;
        align-items: center;
        box-shadow: 14px 14px 0px #bb1ccc;
      }

      .content {
        font-size: 18px;
        font-family: helvetica !important;
        line-height: 26px;
        letter-spacing: 0.2px;
      }
    }

    @media only screen and (min-width: 1025px) {
      .react-multi-carousel-list {
       // height: 440px !important;//
        display: flex;
        align-items: start;
      }
}

  }
}

.campaning {
  #nav-id {
    background-color: #000000 !important;
  }
}

// This css for Animation testing purpose.
.test-animation {
  .main-container {
    display: flex;
    z-index: 1;
    position: relative;

    .grid-container {
      display: grid;
      height: 100vh;
      grid-template-columns: 60% 40%;
      width: 100%;
      justify-content: center;
      justify-items: center;
      padding-left: 2rem;
      padding-right: 2rem;

      .left {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        height: 100%;
        width: 100%;

        // margin-bottom: 50px;
        .header_section_1 {
          width: 100%;
          display: grid;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          justify-items: center;
          color: white;
          animation-name: left;
          animation-duration: 3s;
          animation-iteration-count: 1;
          opacity: 1;
          padding-left: 5rem;
          align-content: center;

          @keyframes left {
            0% {
              opacity: 0;
            }
            50% {
              opacity: 0.3;
            }
            80% {
              opacity: 0.5;
            }
            100% {
              opacity: 1;
            }
          }

          .firstText {
            font-size: 35px;
            font-weight: bold;
            margin: 0 !important;
            text-align: left;
            line-height: 46px;
          }

          .secondText {
            font-size: 22px;
            margin-top: 5px !important;
            margin-bottom: 0 !important;
          }
        }
      }

      .right {
        height: 100%;
        display: flex;
        align-items: center;
        align-content: center;
        width: 100%;
        justify-items: center;
        justify-content: center;
        position: relative;

        img {
          position: absolute;
          width: 100%;
          height: auto;
          object-fit: fill;
          right: 100px;
          animation-name: right;
          animation-duration: 3s;
          animation-iteration-count: 1;

          @keyframes right {
            0% {
              right: 0px;
            }
            100% {
              right: 100px;
            }
          }
        }
      }
    }
  }

  .bottom {
    height: 400px;

    img {
      position: absolute;
      bottom: 0px;
      left: 0%;
      height: 100%;
      width: 100%;
      animation: bottom 3s 1;
      object-fit: cover;

      @keyframes bottom {
        0% {
          bottom: -150px;
        }
        100% {
          bottom: 0px;
        }
      }
    }
  }
}

.newsroomPageCss {
  .test-animation .main-container .grid-container .right img {
    height: 500px;
  }

  .secondpage_section2 {
    font-size: 19px;
    padding: 1.1rem 1rem;
  }
}

.case-studies-pages-cls {
  .test-animation .main-container .grid-container .right img {
    height: 500px;
  }

  .secondpage_section2 {
    font-size: 18px;
    padding: 1.2rem 0;
  }
}

.blogspage-cls {
  .secondpage_section2 {
    font-size: 20px;
    padding: 1.1rem 0;
  }
}

.contactUs {
  .secondpage_section2 {
    font-size: 18px;
    padding: 1.2rem 0;
  }
}

// all button cursor pointer
button {
  cursor: pointer;
}

#ModelPopupAIM{
.MuiPaper-root{
  max-width: 500px !important;
  box-shadow: inset 0 -10px 0 0 #bb1ccc;
  border-bottom-left-radius:0px;
  border-bottom-right-radius:0px;


}
}
.back-button {
  display: flex;
  width: 100%;
  margin: auto;
      padding: 1rem 0rem 1rem;

      .back{
        display: flex;
        .content{
          font-size: 14px;
        }
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 1050px) {
      .career-main .header_section_1 {
        flex-direction: row !important;
        justify-content: flex-start !important;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        align-items: flex-end !important;
    }
    
}
    

    .contentheading{
      margin-bottom:6px;
    }
    @media (max-width: 767px){
      .openPosition-box .position-main .position-body .heading2-list .heading{
        font-size: 20px;
      }
    }
  //   .dotclass{
  //     font-size: 24px;  /* adjust as needed */
  // line-height: 1;   /* or a number that fits */
  // vertical-align: middl

  //   }
  .headinfont{
    font-size:40px !important;
    font-weight: 500;
  }
  .continerss{
  color: black !important;
  }