:root {
    --white-100: hsl(206, 5%, 100%);
    --white-200: hsl(206, 5%, 90%);
    --white-300: hsl(206, 5%, 80%);
    --white-400: hsl(206, 5%, 65%);
    --white-500: hsl(206, 5%, 45%);
 
    --black-100: hsl(210, 21%, 12%);
    --black-200: hsl(210, 21%, 9%);
    --black-300: hsl(210, 21%, 6%);
    --black-400: hsl(210, 21%, 3%);
    --black-500: hsl(210, 21%, 1%);
 
    --night-100: hsl(217, 27%, 20%);
    --night-200: hsl(217, 27%, 16%);
    --night-300: hsl(217, 27%, 12%);
    --night-400: hsl(215, 27%, 8%);
    --night-500: hsl(215, 27%, 4%);
 
    --pink-100: hsl(326, 85%, 90%);
    --pink-200: hsl(327, 87%, 80%);
    --pink-300: hsl(329, 86%, 70%);
    --pink-400: rgba(187,28,204);
    --pink-500: hsl(333, 71%, 50%);
    --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
       0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
       0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
       0 4px 6px -2px rgba(0, 0, 0, 0.05);
 }
 
 *,
 *::before,
 *::after {
    // padding: 0;
    margin: 0;
    box-sizing: border-box;
    // list-style: none;
    // list-style-type: none;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
 }
 


b,a{
   color: white;
   cursor: pointer;
}
 
 @keyframes slideLeft {
    0% {
       opacity: 0;
       transform: translateX(100%);
    }
    100% {
       opacity: 1;
       transform: translateX(0%);
    }
 }
 
 @keyframes slideRight {
    0% {
       opacity: 1;
       transform: translateX(0%);
    }
    100% {
       opacity: 0;
       transform: translateX(100%);
    }
 }
 
 // Elements Section
 
 
 .container {
    height: auto;
    margin-inline: auto;
    padding: 2.5rem;

 }
 
 .centered {
    text-align: center;
    vertical-align: middle;
    margin-bottom: 1rem;
 }
 
 .btn {
    display: inline-block;
    font-size: inherit;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    user-select: none;
    border-radius: 0.25rem;
    text-transform: capitalize;
    transition: all 0.3s ease;
 
    &-primary {
       padding: 0.75rem 1.5rem;
       color: var(--white-100);
       background-color: var(--pink-500);
       box-shadow: var(--shadow-medium);
    }
 
    &-darken {
       padding: 0.75rem 1.5rem;
       color: var(--white-100);
       background-color: var(--night-300);
       box-shadow: var(--shadow-medium);
    }
 }
 
 // Header Section
 .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 100;
    margin: 0 auto;
    background-color: transparent;
   //  box-shadow: var(--shadow-medium);
 
 }
 
 .brand {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.5;
    color: var(--pink-500);
    text-transform: uppercase;
    order: 1;
 
    .darkmode & {
       color: var(--pink-300);
    }
 }
 
 .navbar {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 4rem;
    margin: 0 auto;
 
    &-left {
       display: flex;
       align-items: center;
       flex: 0 0 17%;
    }
 
    &-center {
       display: flex;
    }
 
    &-right {
       display: flex;
       flex: 0 0 17%;
       align-items: center;
       justify-content: flex-end;
       column-gap: 1.5rem;
    }
 
    // Media Query Breakpoint
    @media only screen and (max-width: 899px) {
       &-left,
       &-right {
          flex: 0 0 auto;
       }
 
       &-right {
          align-items: center;
       }
 
       &-center {
          flex: 0 0 100%;
          order: 3;
          align-items: center;
       }
    }
 }
 
 .menu {
    // Media Query Breakpoint
    @media only screen and (max-width: 899px) {
       position: fixed;
       top: 0;
       left: 0;
       width: 75%;
       height: 100%;
       z-index: 100;
       overflow: hidden;
       transform: translate(-100%);
       background-color: black;
       transition: all 0.4s ease-in-out;
 
       &.is-active {
          transform: translate(0%);
       }
 
       
    }
 }
 
 .menu {
    &-header {
       display: none;
       box-shadow: var(--shadow-medium);
    }
 
    &-item {
       display: inline-block;
       line-height: 1.5;
       padding-right: 1.25rem;
       position: relative;
    }
 
    &-link {
       font-size: inherit;
       font-weight: 700;
       line-height: inherit;
       display: flex;
       align-items: center;
       justify-content: center;
       cursor: pointer;
       color: white;
       text-transform: capitalize;
       transition: all 0.25s ease;
       
       position: relative; // Ensure relative positioning for the ::after pseudo-element

       > i.bx {
          font-size: 1.35rem;
          margin-left: 0.25rem;
          transform: rotate(90deg);
       }
 
       .darkmode & {
          color: var(--white-100);
       }
       &:hover {
        // Add your hover styles here
      //   color: white;
        &::after {
            content: '';
            width: 50px;
            height: 2px;
            background-color: #bb1ccc; /* Adjust the color as needed */
            top: 30px;
            display: grid; // Ensure it's a block element
            position: absolute;
            bottom: 0;
            transform: translateX(0%);
            left: 15%;
        }
        // Add any other styles you want on hover
    }
    }
 
    &-icon {
       display: flex;
       align-items: center;
       justify-content: center;
       margin-top: 6px;
       font-size: 1.45rem;
       color: var(--black-300);
       transition: all 0.25s ease;
 
       .darkmode & {
          color: var(--white-100);
       }
    }
 
    // Media Query Breakpoint
    @media only screen and (min-width: 899px) {
       &-dropdown:hover {
          > .submenu {
             opacity: 1;
             visibility: visible;
             margin-top: 1rem;

            
          }
       }
    }
 
    // Media Query Breakpoint
    @media only screen and (max-width: 899px) {
       &-header {
          position: relative;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 4rem;
          z-index: 110;
          visibility: hidden;
          background: transparent;
 
          &.is-active {
             visibility: visible;
             background-color: black;
             > .menu-arrow {
                display: block;
             }
 
             .darkmode & {
                background-color: var(--night-100);
             }
          }
       }
 
       &-arrow {
          display: none;
          cursor: pointer;
          width: 3rem;
          height: 4rem;
          text-align: center;
 
          > i.bx {
             font-size: 1.5rem;
             line-height: 4rem;
             color: var(--black-300);
             transition: all 0.25s ease;
 
             .darkmode & {
                color: var(--white-100);
             }
          }
       }
 
       &-title {
          font-size: inherit;
          font-weight: 500;
          line-height: inherit;
          text-transform: capitalize;
          color: var(--black-300);
 
          .darkmode & {
             color: var(--white-100);
          }
       }
 
       &-inner {
          height: 100%;
          margin-top: -3rem;
          overflow-y: auto;
          overflow-x: hidden;
       }
 
       &-item {
          display: block;
          line-height: 1;
          padding-inline: 0;
       }
 
       &-link {
          justify-content: space-between;
          height: 2.5rem;
          line-height: 2.5rem;
          padding-inline: 1.25rem;
 
          > i.bx {
             margin-left: 0.5rem;
             transform: rotate(0deg);
          }
       }
    }
 }
 
 .submenu {
    position: absolute;
    width: 100%;
    height: auto;
    margin-top: 1.75rem;
    padding: 1rem 2rem;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    border-radius: 0.25rem;
   //  border-top: 2px solid var(--pink-400);
    background-color: #212121;
    box-shadow: var(--shadow-medium);
    transition: all 0.25s ease-in-out;

   

   
 
    .darkmode & {
       border-top: 2px solid var(--pink-300);
       background-color: var(--night-300);
    }
 
    &-inner {
       flex: 0 0 25%;
       padding: 0 1rem;
    }
 
    &-title {
       font-size: inherit;
       font-weight: 700;
       line-height: 1;
       padding: 0.75rem 0;
       color: white;
       text-transform: uppercase;
       transition: all 0.3s ease;
 
       .darkmode & {
          color: var(--pink-300);
       }
    }
 
    &-item {
       display: block;
       line-height: 1;
       margin: 0 auto;
    }
 
    &-link {
       display: inline-block;
       font-size: inherit;
       font-weight: 500;
       line-height: inherit;
       padding: 0.75rem 0;
       white-space: nowrap;
       text-transform: capitalize;
       color: white;
       transition: all 0.25s ease-in-out;
 
       .darkmode & {
          color: var(--white-100);
       }
    }
 
    &-image {
       display: block;
       width: 100%;
       height: auto;
       margin-block: 0.5rem;
       object-fit: cover;
    }
 
    // Media Query Breakpoint
    @media only screen and (max-width: 901px) {
       position: absolute;
       display: none;
       top: 0;
       left: 0;
       width: 100%;
       height: 100%;
       max-width: none;
       min-width: auto;
       margin: 0;
       padding: 1rem;
       padding-top: 4rem;
       opacity: 1;
       overflow-y: auto;
       visibility: visible;
       box-shadow: none;
       transform: translateX(0%);
 
       &.is-active {
          display: block;
       }
 
       &-inner {
          flex: 0 0 100%;
          padding: 0rem;
       }
 
       &-list {
          margin-bottom: 1rem;
       }
 
       &-link {
          display: block;
       }
 
       &-image {
          margin-top: 0;
       }
    }
 }
 .submenu-title::after {
    content: '';
    display: block;
    width: 50px;
    height: 2px;
    background-color: #bb1ccc; /* Adjust the color as needed */
    margin-top: 10px; /* Adjust the spacing as needed */
}
 .submenu-list {
    display: grid;
    justify-content: flex-start;
    justify-items: flex-start;
    background-color: transparent;
    text-align: left !important; /* Ensure left alignment */
    grid-gap: 1px;
    width: 100%;
    margin: 0; /* Reset margin to ensure proper alignment */

    a{
        display: grid;
        justify-content: flex-start;
        justify-items: flex-start;
    
    }
  
    /* Additional styles if needed */
  }
  
  .submenu-inner {
    text-align: left;
  }
  
 .submenu-inner{
    text-align: left;
    min-width: 300px;
    max-width: 300px;
 }
 .megamenu {
    left: 50px;
    width: 100%;
    height: auto;
    margin: 0 auto;
    transform: translateX(-50%);
 
    &-column-1 {
       left: 65%;
       max-width: 25rem;
       width: 100%;
       height: auto;
    }
 
    &-column-4 {
       display: flex;
       height: auto;
       margin: 0 auto;
       width: auto;
    }
    &-column-5 {
        display: grid;
        height: auto;
        margin: 0 auto;
        width: auto;

      

        .megamenu{
         left: 50px !important;


        }

        .submenu-inner {
            padding: 0px !important;
        }

        .submenu-link{
            // font-weight: 700 !important;
        }

        .submenu-inner{

         max-width: 150px !important;
         min-width: 150px !important;

        }
        .submenu-title{
            width: auto !important;
            min-width: 130px !important;
            max-width: 160px !important;
            text-align: left !important;
            font-weight: 700 !important;
            padding: 0px !important;

   
        }
       

        .submenu-title:hover{

        color: #bb1ccc !important;
        font-weight: 700 !important;
        cursor: pointer !important;
          
        }

        .submenu-title::after {
            content: "";
            display: block;
            width: 10px;
            height: 0px !important;
            background-color: red !important;
            margin-top: 10px;
        }
     }
 
    // Media Query Breakpoint
    @media only screen and (max-width: 899px) {
       position: absolute;
       display: none;
       top: 0;
       left: 0;
       width: 100%;
       height: 100%;
       max-width: none;
       min-width: auto;
       margin: 0;
       padding: 1rem;
       padding-top: 4rem;
       opacity: 1;
       overflow-y: auto;
       visibility: visible;
       transform: translateX(0%);
       box-shadow: none;
    }
 }
 
 .switch {
    position: relative;
    display: block;
    cursor: pointer;
    user-select: none;
    margin-right: 0.5rem;
 
    &-light,
    &-dark {
       position: absolute;
       top: 50%;
       left: 50%;
       transform-origin: center;
       transform: translate(-50%, -50%);
       transition: all 0.3s ease-in;
    }
 
    &-light {
       font-size: 1.3rem;
       visibility: visible;
       color: var(--black-300);
 
       .darkmode & {
          font-size: 0rem;
          visibility: hidden;
       }
    }
 
    &-dark {
       font-size: 0rem;
       visibility: hidden;
       color: var(--white-100);
 
       .darkmode & {
          font-size: 1.3rem;
          visibility: visible;
       }
    }
 }
 
 .overlay {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.7);
    transition: all 0.45s ease-in-out;
 
    // Media Query Breakpoint
    @media only screen and (max-width: 899px) {
       &.is-active {
          opacity: 1;
          visibility: visible;
       }
    }
 }
 
 .burger {
    position: relative;
    display: block;
    cursor: pointer;
    width: 1.6rem;
    height: 1rem;
    margin-right: 1rem;
    opacity: 0;
    visibility: hidden;
    background: transparent;
 
    // Media Query Breakpoint
    @media only screen and (max-width: 899px) {
       opacity: 1;
       visibility: visible;
    }
 
    &-line {
       position: absolute;
       display: block;
       left: 0;
       width: 100%;
       height: 2px;
       opacity: 1;
       border-radius: 1rem;
       background:white;
 
       .darkmode & {
          background: var(--white-100);
       }
 
       &:nth-child(1) {
          top: 0px;
       }
       &:nth-child(2) {
          top: 0.5rem;
          width: 70%;
       }
       &:nth-child(3) {
          top: 1rem;
       }
    }
 }


 


 // Your existing styles...

// Media Query for Small Screens (up to 767px)

.menu-link:hover,
.menu-item:hover .menu-link {
    // any other styles you want on hover
    &::after {
      content: '';
      width: 40px;
      height: 2px;
      background-color: white; /* Adjust the color as needed */
      top: 30px;
      display: grid; // Ensure it's a block element
      position: absolute;
      bottom: 0;
      left: 1%; /* Set left to 50% */
      transform: translateX(10%); /* Move it back by half of its width */
        margin: auto;
        transition: 5s;
  }
}

.active-home-cls{
   position: relative;
   &::after {
      content: '';
      width: 30px;
      height: 2px;
      background-color: white; /* Adjust the color as needed */
      top: 30px;
      display: grid; // Ensure it's a block element
      position: absolute;
      bottom: 0;
      left: 1%; /* Set left to 50% */
      transform: translateX(10%); /* Move it back by half of its width */
        margin: auto;
  }
  &:hover{
   &::after {
      content: '';
      width: 30px;
      height: 2px;
      background-color: white; /* Adjust the color as needed */
      top: 30px;
      display: grid; // Ensure it's a block element
      position: absolute;
      bottom: 0;
      left: 1%; /* Set left to 50% */
      transform: translateX(10%); /* Move it back by half of its width */
        margin: auto;
  }
  }
 }

 .active-solution-cls{
   position: relative;
   &::after {
      content: '';
      width: 30px;
      height: 2px;
      background-color: white; /* Adjust the color as needed */
      top: 30px;
      display: grid; // Ensure it's a block element
      position: absolute;
      bottom: 0;
      left: 2%; /* Set left to 50% */
      transform: translateX(10%); /* Move it back by half of its width */
        margin: auto;
  }
  &:hover{
   &::after {
      content: '';
      width: 30px;
      height: 2px;
      background-color: white; /* Adjust the color as needed */
      top: 30px;
      display: grid; // Ensure it's a block element
      position: absolute;
      bottom: 0;
      left: 0%; /* Set left to 50% */
      transform: translateX(10%); /* Move it back by half of its width */
        margin: auto;
  }
  }
 }

 .active-resorces-cls{
   position: relative;
   &::after {
      content: '';
      width: 30px;
      height: 2px;
      background-color: white; /* Adjust the color as needed */
      top: 30px;
      display: grid; // Ensure it's a block element
      position: absolute;
      bottom: 0;
      left: 2%; /* Set left to 50% */
      transform: translateX(10%); /* Move it back by half of its width */
        margin: auto;
  }
  &:hover{
   &::after {
      content: '';
      width: 30px;
      height: 2px;
      background-color: white; /* Adjust the color as needed */
      top: 30px;
      display: grid; // Ensure it's a block element
      position: absolute;
      bottom: 0;
      left: 0%; /* Set left to 50% */
      transform: translateX(10%); /* Move it back by half of its width */
        margin: auto;
  }
  }
 }

 .active-application-cls{
   position: relative;
   &::after {
      content: '';
      width: 30px;
      height: 2px;
      background-color: white; /* Adjust the color as needed */
      top: 30px;
      display: grid; // Ensure it's a block element
      position: absolute;
      bottom: 0;
      left: 2%; /* Set left to 50% */
      transform: translateX(10%); /* Move it back by half of its width */
        margin: auto;
  }
  &:hover{
   &::after {
      content: '';
      width: 30px;
      height: 2px;
      background-color: white; /* Adjust the color as needed */
      top: 30px;
      display: grid; // Ensure it's a block element
      position: absolute;
      bottom: 0;
      left: 0%; /* Set left to 50% */
      transform: translateX(10%); /* Move it back by half of its width */
        margin: auto;
  }
  }
 }


