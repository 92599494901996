


.popup-content {
  display: grid;
  grid-template-columns: 44% 60%; //4060
  justify-content: center;
  align-items: center;
  gap: 21px; 
}
.popup-image {
  display: flex;
  justify-content: space-around;; 
  align-items: center; 
}
.css-ypiqx9-MuiDialogContent-root{
  overflow: hidden;
}

.popup-text {
  font-weight: 500;
  font-size: 16px;
  color: white;
  //padding-left: 13px; 
}

.button-options {
  display: flex;
  justify-content: center;
  margin-top: 38px; 
 
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.react-multiple-carousel__arrow {
  background-color: transparent!important; 

}
.react-multiple-carousel__arrow--left {
  left: calc(-2% + 1px);
 
}
.react-multiple-carousel__arrow::before{
  color: #F000F2;
}
.react-multiple-carousel__arrow--right {
  right: calc(2% + -30px);
}
.headinmain{
  text-align: center;
  margin-bottom: 26px;
  font-size: 24px;
  color: white;
  border-bottom: 2px solid gray;
  padding-bottom: 5px;
}