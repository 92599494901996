@media only screen and (min-width: 280px) and (max-width: 767px) {
  .newBlog-body {
    background-color: white;
    display: block;

    .newBlog-margin {
      padding: 8rem 0 0 0;
      .newBlog-main {
        // padding: 0 2rem;
        .back-button {
          width: 90%;
          margin: auto;
          padding: 1rem 0rem;

          .back {
            display: flex;
            .content {
              font-size: 12px;
            }
          }
        }

        .firstSection {
          .main {
            width: 100%;
            padding: 0;
            justify-content: center;
            min-height: auto;
            .heading {
              font-size: 20px;
              width: 90%;
              display: block;
              margin: auto;
              color: black;
              // text-align: center;
            }

            .date-read-container {
              display: block;
              margin: auto;
              width: 90%;

              .date-container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                align-content: center;
                font-size: 12px;
                padding: 10px 5px;
                color: white;
                gap: 20px;
                .read {
                  display: flex;
                  align-items: center;
                  align-content: center;
                  gap: 5px;
                }
              }
            }

            .best-firm-background {
              background-image: url("../../../assets/img/Workplace\ Leader.rectangle.black.jpg");
              background-repeat: no-repeat;
              background-size: contain;
              display: block !important;
              width: 90%;
              margin: auto;
              height: 140px !important;
            }
            .FT-background {
              background-image: url("../../../assets/img/Aays_Financial\ Times_Statista.rectangle.jpg");
              background-repeat: no-repeat;
              background-size: contain;
              display: block !important;
              width: 90%;
              margin: auto;
              height: 140px !important;
            }
            .investInAI-background {
              background-image: url("../../../assets/img/Aays_Gen\ AI\ Investment\ Strategies.rectangle.jpg");
              background-repeat: no-repeat;
              background-size: contain;
              display: block !important;
              width: 90%;
              margin: auto;
              height: 140px !important;
            }
            .genAiPriority-background {
              background-image: url("../../../assets/img/Aays_Gen\ AI\ Use\ Cases.Rectangle.jpg");
              background-repeat: no-repeat;
              background-size: contain;
              display: block !important;
              width: 90%;
              margin: auto;
              height: 140px !important;
            }
            .genAiPriorityai-background{
              background-image: url("../../../assets/img/leverage-caseai.jpg") ;
              background-repeat: no-repeat;
              background-size: contain;
              display: block !important;
              width: 90%;
              margin: auto;
              height: 140px !important;
            }
            .box-form {
              display: flex;
              flex-direction: column;
              // height: 140px ;
              // height: auto;
              .left {
                width: 100%;
                .badge {
                  padding: 4rem 0;
                  .content {
                    font-size: 20px;
                  }
                }
              }
            }
            .content1 {
              font-size: 16px;
              text-align: left;
              display: block;
              margin: auto;
              width: 90%;
            }
          }
        }
        .secondSection {
          height: auto;
          .main {
            padding: 0;
            width: 90%;
            margin: auto;
            display: block;
            padding-bottom: 60px;

            .heading {
              font-size: 20px;
            }
            .container {
              flex-direction: column;
              padding-top: 0;
              .left {
                width: 100% !important;
                padding: 0;
                .content {
                  .content1,
                  .content2 {
                    font-size: 16px;
                  }
                  .content2 {
                    padding-bottom: 20px;
                  }
                }
              }
              .right-img {
                width: 100% !important;
                padding-top: 10px;
              }
            }
            .lastLine {
              padding-top: 10px;
              span {
                a {
                  color: #bb1ccc;
                }
              }
            }
          }
        }
        .thirdSection {
          height: auto;
          .main {
            width: 90%;
            padding: 1rem 0;

            .heading {
              font-size: 20px;
            }
            .content {
              font-size: 16px;
            }
            .lastPoint {
              font-size: 16px;
              margin: 0;
            }
          }
        }
        .right {
          // display: none;
          width: 100%;
          background-color: #bb1ccc;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          .genai-form {
            width: 90%;
            padding: 2rem 0;

            .aays-logo {
              display: none;
            }
            .form {
              #fullName,
              #email {
                width: 100%;
                height: 40px;
                border: none;
                outline: none;
                margin-top: 10px;
                padding: 10px 10px;
                border-radius: 10px;
                font-family: Montserrat;
              }
              .same-line {
                display: flex;
                flex-direction: column;
                margin-top: 10px;

                #companyName,
                #designation {
                  width: 100%;
                  padding: 10px 10px;
                  border-radius: 10px;
                  height: 40px;
                  border: none;
                  outline: none;
                  font-family: Montserrat;
                }

                #designation {
                  margin-top: 10px;
                }
              }
              .button {
                text-align: center;
                margin-top: 10px;
                button {
                  padding: 10px 25px;
                  border: none;
                  outline: none;
                  border-radius: 10px;
                  color: #75758b;
                  font-family: Montserrat;
                }
                :hover {
                  color: white;
                  background-color: black;
                }
              }
            }
          }
        }
        .quotes {
          width: 90%;
          margin: auto;
          display: block;

          .main {
            min-height: auto;
            padding: 0;
            width: 100%;

            .heading {
              font-size: 20px;
            }
            .subHeading {
              font-size: 16px;
            }
            .sheading {
              font-size: 18px;
              padding-top: 4rem;
              font-weight: bold;
              
            }
            .content {
              padding-bottom: 0;
              .content1,
              .content2 {
                font-size: 16px;
              }
              .bulletPoints {
                ul {
                  li {
                    font-size: 16px;
                  }
                }
              }
              .content-last {
                font-size: 16px;
              }
            }
            .container {
              padding: 2.5rem 0;
              gap: 2rem;
              .quote-box {
                .heading {
                  font-size: 20px;
                }
                .content {
                  font-size: 16px;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
    .flex-containersi{
      flex-direction: column;
      padding-bottom: 3rem;
     }
     .leftssi{
        width: 100%;
     }
     .rightssi{
         width: 100%;
     }
     .accordian-filessi{
       height: 90%;
       width: 100%;
     }
  }

  
}

@media only screen and (min-width: 767px) and (max-width: 924px) {
  .newBlog-body {
    background-color: white;
    display: block;
    min-height: auto;
    padding-bottom: 2rem;

    .newBlog-margin {
      padding: 8rem 0 0 0;
      .newBlog-main {
        // padding: 0 2rem;
        .back-button {
          width: 90%;
          margin: auto;
          padding: 1rem 0rem;

          .back {
            display: flex;
            .content {
              font-size: 12px;
            }
          }
        }

        .firstSection {
          .main {
            width: 100%;
            padding: 0;
            justify-content: center;
            min-height: auto !important;
            .heading {
              font-size: 20px;
              width: 90%;
              display: block;
              margin: auto;
              color: black;
              // text-align: center;
            }

            .date-read-container {
              display: block;
              margin: auto;
              width: 90%;

              .date-container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                align-content: center;
                font-size: 12px;
                padding: 10px 5px;
                color: white;
                gap: 20px;
                .read {
                  display: flex;
                  align-items: center;
                  align-content: center;
                  gap: 5px;
                }
              }
            }
            .best-firm-background {
              background-image: url("../../../assets/img/Workplace\ Leader.rectangle.black.jpg");
              background-repeat: no-repeat;
              background-size: contain;
              display: block;
              margin: auto;
              width: 90%;
              height: 300px !important;
            }
            .FT-background {
              background-image: url("../../../assets/img/Aays_Financial\ Times_Statista.rectangle.jpg");
              background-repeat: no-repeat;
              background-size: contain;
              display: block;
              margin: auto;
              width: 90%;
              height: 300px !important;
            }
            .investInAI-background {
              background-image: url("../../../assets/img/Aays_Gen\ AI\ Investment\ Strategies.rectangle.jpg");
              background-repeat: no-repeat;
              background-size: contain;
              display: block;
              margin: auto;
              width: 90%;
              height: 300px !important;
            }
            .genAiPriority-background {
              background-image: url("../../../assets/img/Aays_Gen\ AI\ Use\ Cases.Rectangle.jpg");
              background-repeat: no-repeat;
              background-size: contain;
              display: block;
              margin: auto;
              width: 90%;
              height: 300px !important;
            }
            .box-form {
              display: flex;
              flex-direction: column;

              // height: 300px  !important;
              .left {
                width: 100%;
                .badge {
                  padding: 4rem 0;
                  .content {
                    font-size: 20px;
                  }
                }
              }
            }
            .content1 {
              font-size: 16px;
              text-align: left;
              display: block;
              margin: auto;
              width: 90%;
            }
          }
        }
        .secondSection {
          height: auto;
          .main {
            padding: 0;
            width: 90%;
            margin: auto;
            display: block;
            padding-bottom: 60px;

            .heading {
              font-size: 20px;
            }
            .container {
              flex-direction: column;
              padding-top: 0;
              .left {
                width: 100% !important;
                padding: 0;
                .content {
                  .content1,
                  .content2 {
                    font-size: 16px;
                  }
                  .content2 {
                    padding-bottom: 20px;
                  }
                }
              }
              .right-img {
                width: 70% !important;
                padding-top: 10px;
              }
            }
            .lastLine {
              padding-top: 10px;
              span {
                a {
                  color: #bb1ccc;
                }
              }
            }
          }
        }
        .thirdSection {
          height: auto;
          .main {
            width: 90%;
            padding: 1rem 0;

            .heading {
              font-size: 20px;
            }
            .content {
              font-size: 16px;
            }
            .lastPoint {
              font-size: 16px;
              margin: 0;
            }
          }
        }
        .right {
          // display: none;
          width: 100%;
          background-color: #bb1ccc;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          .genai-form {
            width: 60%;
            padding: 2rem 0;

            .aays-logo {
              display: none;
            }
            .form {
              #fullName,
              #email {
                width: 100%;
                height: 40px;
                border: none;
                outline: none;
                margin-top: 10px;
                padding: 10px 10px;
                border-radius: 10px;
                font-family: Montserrat;
              }
              .same-line {
                display: flex;
                flex-direction: column;
                margin-top: 10px;

                #companyName,
                #designation {
                  width: 100%;
                  padding: 10px 10px;
                  border-radius: 10px;
                  height: 40px;
                  border: none;
                  outline: none;
                  font-family: Montserrat;
                }

                #designation {
                  margin-top: 10px;
                }
              }
              .button {
                text-align: center;
                margin-top: 10px;
                button {
                  padding: 10px 25px;
                  border: none;
                  outline: none;
                  border-radius: 10px;
                  color: #75758b;
                  font-family: Montserrat;
                }
                :hover {
                  color: white;
                  background-color: black;
                }
              }
            }
          }
        }
        .quotes {
          width: 90%;
          margin: auto;
          display: block;

          .main {
            min-height: auto;
            padding: 0;
            width: 100%;
            .heading {
              font-size: 20px;
            }
            .subHeading {
              font-size: 16px;
            }
            .content {
              padding-bottom: 0;
              .content1,
              .content2 {
                font-size: 16px;
              }
              .bulletPoints {
                ul {
                  li {
                    font-size: 16px;
                  }
                }
              }

              .content-last {
                font-size: 16px;
              }
            }
            .container {
              padding: 2.5rem 0;
              gap: 2rem;
              .quote-box {
                .heading {
                  font-size: 20px;
                }
                .content {
                  font-size: 16px;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
    .flex-containersi{
      flex-direction: column;
      padding-bottom: 3rem;
     }
     .leftssi{
        width: 100%;
     }
     .rightssi{
         width: 100%;
     }
     .accordian-filessi{
       height: 90%;
       width: 100%;
     }
  }
  
}
