case-study-remove-border {
  border-right: none !important ;
}

.back-button {
  width: fit-content;
  height: fit-content;
  // margin: 1rem 0 0 6rem;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;

  .content {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin: 0 6px;
    cursor: pointer;
    color: white;
    display: flex;

    &:hover {
      text-decoration: underline;
      color: #bb1ccc !important;
    }
  }
}

.customer-success-more-section1 {
  padding: 2rem 0;
  color: white;

  .heading {
    margin: 0;
    color: white;
  }

  .sub-heading {
    font-size: 24px;
    color: grey;
    font-weight: bold;
    margin: 0 !important;
    padding-bottom: 12px;
  }
  img {
    height: 400px;
  }

  .text {
    color: grey;
    font-weight: 600;
  }

  .customer-more-flex-container {
    display: flex;
    justify-content: space-between;
    grid-gap: 3rem;
    align-items: center;
    align-content: center;

    .customer-more-left {
      // h1{
      //     color: #bb1ccc;
      //     margin-bottom: 0;
      // }
      p {
        margin: 0;
      }
    }

    .customer-more-right {
      .textfield {
        background-color: white;
        border-left: solid 10px #bb1ccc;
      }

      h1 {
        margin-bottom: 5px;
      }

      form {
        .grid-container1 {
          display: grid;
          grid-gap: 10px;
          margin-bottom: 10px;
        }

        .grid-container2 {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 10px;
          margin-bottom: 20px;
        }
      }

      button {
        display: block;
        margin: auto;
        padding: 10px;
        font-size: 20px;
        background-color: #bb1ccc;
        color: white;
        outline: none;
        border: none;
        cursor: pointer;
      }
    }
  }
}

.page-width {
  width: 80%;
  margin: auto;
  display: block;

  .indi-case-studies {
    .indi-case-studies-section1 {
      display: block;
      margin: auto;
      width: 90%;
      padding-top: 6rem;
      padding-bottom: 2rem;

      .main {
        display: grid;
        align-items: center;
        align-content: center;

        .main-heading {
          color: white;
          font-size: 30px;
          line-height: 40px;
          font-weight: 700;
          width: 100%;
          margin-bottom: 0px;
        }
      }
    }
    .indi-case-study-section2 {
      height: auto;
      margin-bottom: 0px;

      .section2-main {
        display: block;
        margin: auto;
        width: 90%;
        padding-top: 0rem;
        padding-bottom: 2rem;

        .section2-body {
          display: flex;
          flex-wrap: wrap;
          grid-gap: 1rem;
          width: 100%;
          flex: 1;
          justify-content: space-between;

          .section2-content-main {
            width: 60%;
            display: grid;
            align-items: baseline;
            align-content: baseline;
            grid-gap: 1rem;

            span {
              font-size: 18px;
              width: 100%;
              color: white;
            }

            .grid-container1 {
              display: grid;
              grid-gap: 2rem;
              grid-template-columns: repeat(2, 45%);
              padding: 2rem;
              padding-bottom: 1rem;
              padding-left: 0px;
              padding-top: 1rem;
              justify-content: center;

              .left-container,
              .right-container {
           
                display: flex;
                flex-direction: column;
                width: 100%;
                grid-gap: 10px;

                .heading {
                  color: #bb1ccc;
                  font-size: 24px;
                  font-weight: 600;
                }
                .content {
                  width: 100%;
                  color: white;
                  font-size: 18px;
                }
              }
            }

            .form-heading {
              font-size: 18px;
              font-weight: 700;
              color: #ffffff;
            }
            .form-data-main {
              background-color: transparent;

              .main {
                height: 100%;
                background-color: transparent;
                display: grid;
                grid-gap: 10px;

                .form-data {
                  margin: auto;

                  .form-field {
                    display: flex;
                    grid-gap: 2rem;
                    width: 100%;

                    .form-input-boxes {
                      position: relative;
                      width: 45%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .case-studies-mobile-big-texta {
      color: #bb1ccc;
    }

    .case-studies-mobile-header,
    .case-studies-mobile-big-text {
      color: #ffffff !important;
    }

    .btn-landing {
      border: 2px solid #bb1ccc !important;
      color: #bb1ccc !important;
    }

    .input-container-landing {
      display: -ms-flexbox; /* IE10 */
      display: flex;
      width: 100%;
      margin-bottom: 15px;
    }

    .input-field-landing {
      width: 100%;
      padding: 14px;
      outline: none;
      border: 2px solid #bb1ccc;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .input-container-landing::after {
      content: "";
      position: absolute;
      left: 0;
      background: #bb1ccc;
      width: 5px;
      height: 45.6px;
      right: 0;
    }

    .icon-landing {
      padding: 10px;
      background: #bb1ccc;
      color: white;
      min-width: 50px;
      text-align: center;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    .input-field-landing:focus {
      border: 2px solid #253978;
    }

    /* Set a style for the submit button */
    .btn-landing {
      background-color: #253978;
      color: white;
      padding: 15px 20px;
      border: none;
      cursor: pointer;
      width: 100%;
      opacity: 0.9;
      border-radius: 6px;
    }

    .btn-landing:hover {
      opacity: 1;
    }
  }
}

.btn-read-more {
  display: grid;
  justify-content: center;
  padding-top: 1rem;
  margin-bottom: 1rem;
}
.btn-read-more > button {
  background-color: #bb1ccc;
  padding: 8px;
  // border-radius: 6px;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  color: white;
}

// individual customer success story mobile version

@media only screen and (min-width: 280px) and (max-width: 767px) {
  .page-width {
    width: 100% !important;
  }
  .case-studies-header_img {
    display: none;
  }

  .grid-container1 {
    padding: 0 !important;
    grid-gap: 1rem !important;
  }

  .form-input-boxes{
    width: 50% !important;
  }
  .form-field{
    grid-gap: 10px !important;
  }

  .form-heading{
    margin-top: 2rem;
  }

  .section2-content-main{
    width: 100% !important;

    span{
      font-size: 16px !important;
    }
  }


  .indi-case-studies {
    .indi-case-studies-section1 {
      padding-bottom: 1rem !important;
      .main {
        .main-heading {
          font-size: 20px !important;
          line-height: 25px !important;
          text-align: left !important;
        }
      }
    }
    .heading {
      font-size: 20px !important;
    }
    .content{
      padding: 2px !important;
      font-size: 12px !important;
    }

    .back-button {
      .content{
        font-size: 14px !important;
      }
    }
  }
}
