.nav-header-fixed {
  position: fixed !important;
  z-index: 999 !important;
  padding: 1rem;
  background: transparent !important;
  box-shadow: none !important;
  width: 100%;
}

.section3RightScreen {
  i {
    font-size: 14px !important;
  }
}

.logo {
  height: 40px;
}

.nav-header-add {
  position: fixed !important;
  z-index: 999 !important;
  background: black !important;
  box-shadow: grey 0px 25px 20px -30px !important;
  width: 100%;
}

.landingPage {
  height: 100vh;
  overflow: hidden !important;
  background-image: url("../../assets/img/banersmage.jpg");
  background-position: center;
  background-size: cover;
  color: white;
}

#tsparticles {
  position: absolute !important;
}

.buttonWrapper {
  display: flex;
  background-color: #bb1ccc;
  color: white;
  font-size: 16px;
  margin-top: 40px;
  padding: 10px 20px 10px 20px;
  align-items: center;
  align-content: center;
  grid-gap: 5px;
  position: absolute;
  top: 60%;
  left: 7%;
  transform: translateY(-50%);
  border: none;
  outline: none;
  cursor: pointer;
}

.animationImages {
  width: 580px;
}

.typewriter-container {
  width: 600px;
  display: block;
  overflow: hidden;
  position: relative;
  text-transform: none !important;
}

.box1,
.box3,
.box5,
.box7 {
  position: absolute;
  position: absolute;
  top: 50%;
  left: 7%;
  transform: translateY(-50%);
  opacity: 0;
  text-transform: uppercase;
}
.font-family{
  font-family: Montserrat!important;
}

.box2 {
  position: absolute;
  width: 500px;
  top: 50%;
  right: 10%;
  opacity: 0;
  transform: translateY(-50%);
}

.box4,
.box8 {
  /* height: 200px; */
  width: 500px;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  opacity: 0;
}

.box6 {
  position: absolute;
  width: 500px;
  bottom: 0%;
  right: 20%;
  opacity: 0;
}

.box10 {
  position: absolute;
  top: 50%;
  right: 30%;
  opacity: 0;
  transform: translateY(-50%);
}

.box1 {
  animation: box1 9.5s ease-in-out 0s infinite;
}

.box3 {
  animation: box3 9.5s ease-in-out 2s infinite;
}

.box5 {
  animation: box5 9.5s ease-in-out 4.5s infinite;
}

.box7 {
  animation: box7 9.5s ease-in-out 6.5s infinite;
}

.box2 {
  animation: box2 9.5s ease-in-out 0s infinite;
}

.box4 {
  animation: box4 9.5s ease-in-out 4.5s infinite;
}
.box6 {
  animation: box6 9.5s ease-in-out 4.5s infinite;
}
.box8 {
  animation: box8 9.5s ease-in-out 2s infinite;
}

.box10 {
  animation: box10 9.5s ease-in-out 6.5s infinite;
}

@keyframes box1 {
  0% {
    /* top: 50%; */
    opacity: 0.2;
  }
  5% {
    opacity: 0.5;
  }
  10% {
    opacity: 1;
  }

  20%,
  100% {
    /* top: 20%; */
    opacity: 0;
  }
}

@keyframes box3 {
  0% {
    /* top: 50%; */
    /* right: 20%; */
    opacity: 0;
  }
  10% {
    /* top: 50%; */
    opacity: 0.7;
  }
  10% {
    opacity: 1;
  }

  20%,
  100% {
    /* top: 50%; */
    /* right: 0%; */
    opacity: 0;
  }
}

@keyframes box5 {
  0% {
    /* bottom: 0%; */
    /* right: 10%; */
    opacity: 0;
  }
  10% {
    /* right: 10%; */
    opacity: 1;
  }
  10% {
    opacity: 1;
  }

  20% {
    /* right: 10%; */
    /* bottom: 20%; */
    opacity: 1;
  }
  30%,
  100% {
    /* right: 10%; */
    /* bottom: 20%; */
    opacity: 0;
  }
}

@keyframes box7 {
  0% {
    /* top: 50%; */
    /* right: 10%; */
    opacity: 0;
  }
  10% {
    /* right: 10%; */
    opacity: 0.3;
  }
  10% {
    opacity: 0.7;
  }
  20% {
    opacity: 1;
  }

  30%,
  100% {
    /* right: 10%; */
    /* top: 20%; */
    opacity: 0;
  }
}

/* images animation start from here*/

@keyframes box2 {
  0% {
    right: 10%;
    opacity: 0.5;
  }
  10% {
    opacity: 1;
  }

  20%,
  100% {
    top: 50%;
    right: 30%;
    opacity: 0;
  }
}

@keyframes box4 {
  0% {
    top: 10%;
    right: 10%;
    opacity: 0;
  }

  10% {
    right: 10%;
    opacity: 1;
  }

  20% {
    right: 10%;
    top: 40%;
    opacity: 1;
  }
  30%,
  100% {
    right: 10%;
    top: 40%;
    opacity: 0;
  }
}

@keyframes box6 {
  0% {
    bottom: 0%;
    right: 10%;
    opacity: 0;
  }
  10% {
    right: 10%;
    opacity: 1;
  }
  10% {
    opacity: 1;
  }

  20% {
    right: 10%;
    bottom: 20%;
    opacity: 1;
  }
  30%,
  100% {
    right: 10%;
    bottom: 20%;
    opacity: 0;
  }
}

@keyframes box8 {
  0% {
    top: 50%;
    right: 30%;
    opacity: 0;
  }
  10% {
    top: 50%;
    opacity: 0.7;
  }
  10% {
    opacity: 1;
  }

  20%,
  100% {
    top: 50%;
    right: 10%;
    opacity: 0;
  }
}

@keyframes box10 {
  0% {
    top: 50%;
    right: 0%;
    opacity: 0;
  }
  10% {
    right: 0%;
    opacity: 0.3;
  }
  10% {
    opacity: 0.7;
  }
  20% {
    opacity: 1;
  }

  30%,
  100% {
    right: 0%;
    top: 20%;
    opacity: 0;
  }
}

.react-multi-carousel-track {
  margin-block-end: 33px !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  padding-inline-start: 0px !important;
}
.react-multi-carousel-list {
  height: auto;
  margin-top: -6px !important;
}

.section2 {
  display: flex;
  /* height: 100px; */
  z-index: 1;
  position: relative;
  background-color: #bb1ccc;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  // text-transform: uppercase;
  color: white;
}
.section2Text {
  /* font-size: 90; */
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
}

.section3 {
  background-color: #212121;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-around;
  /* margin: 0 10%; */
}

.section3LeftScreenBox {
  height: 100px;
  width: 150px;
  /* background-color: blue; */
}

.section3Text {
  margin: 0;
  /* font-size: 40px; */
}

.section3Number {
  font-size: 55px;
  margin: 0;
}
.section3Box1 {
  position: absolute;
  top: 20%;
  left: 15%;
  border-right: 2px solid #bb1ccc;
}
.section3Box2 {
  position: absolute;
  top: 10%;
  left: 30%;
  border-bottom: 2px solid #bb1ccc;
}

.section3Box3 {
  position: absolute;
  top: 40%;
  left: 15%;
  border-top: 2px solid #bb1ccc;
}
.section3Box4 {
  position: absolute;
  top: 30%;
  left: 30%;
  border-left: 2px solid #bb1ccc;
}
.section3SliderImage {
  background-color: black;
  height: 40px;
  width: 60px;
}

.section3Slider1,
.section3Slider2,
.section3Slider3,
.section3Slider4 {
  display: flex;
}

.section3SliderText {
  margin-left: 10px;
}

.LandingPageSection4 {
  height: auto;
  color: white;
  background-color: black;
  position: relative;
  z-index: 1;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.align {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: #932599;
  padding: 12px 20px;
  width: 100%;

  svg {
    path {
      fill: white;
    }
  }
}

.LandingPageSection4Wrapper {
  margin-left: 12%;
  margin-right: 12%;
}
.section4Typography {
  padding: 20px 0 10px 0;
}

.section4FlexContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px;
}

.section4Left {
  display: flex;
  align-items: center;
  width: 30%;
  /* justify-content: center; */
  /* flex-wrap: wrap; */
  /* flex: 49%; */
}

.section4Right {
  display: flex;
  align-items: center;
  width: 70%;
  justify-content: flex-end;
  /* flex-wrap: wrap; */
  /* flex: 49%; */
}
.section4Image {
  /* height: auto; */
  width: 60%;
}

.section4Button:hover {
  color: #bb1ccc;
  cursor: pointer;
}

.section4TypographyWrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-left: 30%;
}

.industry-subtext,
.functions-subtext,
.capabilities-subtext {
  width: 300px;
  /* margin-top: 2px; */
}

.highlight1 {
  color: #bb1ccc;
}

.highlight2 {
  color: #bb1ccc;
}

.highlight3 {
  color: #bb1ccc;
}
.grey {
  color: gray;
}

.scroll-btn {
  position: fixed !important;
  z-index: 999 !important;
  padding: 1rem !important;
  /* background: transparent !important; */
  box-shadow: none !important;
  bottom: 70px;
  right: 10px;
}
.scroll-btn-down {
  position: fixed !important;
  z-index: 999 !important;
  padding: 1rem !important;
  /* background: transparent !important; */
  box-shadow: none !important;
  bottom: 130px;
  right: 10px;
  display: none;
}

.arrowBtn {
  background-color: transparent;
  color: white;
  border: none;
  font-size: 28px;
  cursor: pointer;
}

.customer-success-main {
  /* height: auto; */
  color: white;
  // background-color: black;
  position: relative;
  z-index: 1;
}

.headingCustomerSuccess {
  font-size: 40px;
  text-align: center;
  margin-bottom: 0px !important;
}

.customer-success-body {
  margin: 0 10% 0 10%;
}

.customer-success-text {
  align-items: center;
  align-content: center;
  text-align: center;

  padding: 6rem 3rem;
  /* padding-bottom: 6rem; */
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.section5textBox {
  /* background-color: red; */
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 40px;
}
.casestudy {
  background-color: #bb1ccc;
  color: white;
  padding: 10px;
  border: none;
  font-weight: bold;
  margin-top: 20px;
}

.sub-heading {
  font-size: 30px;
}
.divider {
  width: 100%;
  display: grid;
  justify-content: center;
}

.divider1 {
  height: 2px;
  width: 100px;
  background-color: #bb1ccc;
}

.customer-success-body p {
  padding: 20px;
  width: 70%;
  margin: auto;
}

.react-multi-carousel-dot-list {
  padding-bottom: 0px;
}

// starting new section2 css

.homepage-sections-padding {
  width: 80%;
  display: block;
  margin: auto;
  position: relative;
}

// section 2 css
.section2-homepage-main {
  background-color: black;
 // position: relative;//
  z-index: 1;
  color: white;
  display: grid;
  height: 100vh;

  .homepage-body {
    //padding: 4rem 0;//

    .mainContainer {
      width: 100%;
      display: block;
      margin: auto;

      .counting-number {
        color: #bb1ccc;
        margin-bottom: 0px;
        padding-bottom: 5px;
        font-size: 40px;
      }

      .text {
        font-weight: bold;
      }

     // .box-border {
       //border-left: 2px solid white;
       // border-right: 2px solid white;
     // }

      .box {
        display: flex;
        justify-content: center;
        // background-color: pink;
      }

      .topContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }

      .bottomContainer {
        margin-top: 6rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        // text-align: center;
        // justify-content: space-between;
        // grid-gap: 4rem;
      }
    }
  }
}

// section 3 css

.homepage-section3 {
  background-color: #212121;
  position: relative;
  z-index: 1;
  color: white;
  height: 90vh;
  display: grid;

  padding: 3rem 0;

  .main {
    .container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .left {
        // background-color: red;
        height: 350px;
        img {
          object-fit: contain;
        }
      }
    }
  }
}

.homepage-section4 {
  background-color: black;
 // position: relative;//
  z-index: 1;
  color: white;
  padding: 3rem 0;
  height: 110vh;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;

  .main {
    .main-heading {
      text-align: center;
      h2 {
        font-size: 30px;
        margin: 0;
      }
      span {
        font-size: 24px;
      }
    }

    .grid-container {
      display: grid;
      grid-gap: 3rem;
      padding: 2rem;
      align-items: center;
      width: 80%;
      margin: auto;

      .left {
        img {
          width: 330px;
          height: 100%;
        }
      }

      span {
        font-size: 18px;
        text-align: center;
        padding-bottom: 1rem;
        line-height: 24px;
        letter-spacing: 0.2px;
      }
    }

    .services {
      display: flex;
      justify-content: center;
      grid-gap: 20px;
      position: relative;

      .box {
        height: 360px;
        width: 300px;
        border-radius: 30px;
        border: 3px solid #bb1ccc;
        display: block;
        overflow: hidden;

        .icon {
          height: 130px;
          background-color: #212121;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 50px;
            height: 50px;
          }
          h2 {
            margin: 0;
            font-size: 30px;
          }
        }

        .content {
          display: flex;
          justify-content: center;
          div {
            padding: 2rem 0;

            span {
              display: block;
              margin-bottom: 28px;
              font-weight: normal;
              font-size: 18px;
              font-family: montserrat, medium;
            }
            span:hover {
              // font-weight: 600;
              cursor: pointer;
              color: #bb1ccc;
              font-family: montserrat, medium;
            }
          }
        }
      }

      .box-Functions {
        background-color: #bb1ccc;
        .icon {
          background-color: #bb1ccc;
        }

        span:hover {
          color: white !important;
        }
      }

      .box:hover {
        border: 3px solid white;
      }
    }
  }
}

.homepage-section4-main {
  .heading {
    background-color: black;
    margin: 0;
    position: relative;
    z-index: 1;
    color: white;
    text-align: center;
    // padding-bottom: 10px;
    padding-bottom: 2rem;
  }

  .homepage-section5 {
    background-color: #3d3c3c;
    position: relative;
    z-index: 1;
    color: white;
    padding: 4rem 0;

    .main {
      h1 {
        text-align: center;
      }

      .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 45%);
        justify-content: center;
        align-items: center;
        align-content: center;
        grid-gap: 1rem;

        .left {
          object-fit: contain;
        }

        .right {
          @media only screen and (min-width: 1025px) {
            .react-multi-carousel-list {
              height: 60vh !important;
            }
          }

          .box {
            padding: 0.5rem 0;
            h1 {
              font-size: 30px;
              color: #bb1ccc;
            }
            p {
              font-size: 18px;
              // font-weight: bold;
              text-align: center;
            }
            div {
              button {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  box-shadow: 24px;
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  gap: 1.3rem;
}

.input-field-landing {
  width: 320px;
  padding: 0.7rem 1rem;
  font-size: 14px;
  outline: none;
  border: 1.8px solid #bb1ccc;
  border-radius: 4px;
  resize: none;
}

.contact-heading {
  display: flex;
  align-content: center;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.submit-btn > button {
  width: 100%;
  background-color: #bb1ccc;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 600;
  border: none;
}


.data-drive-succ{
  color: white;
    font-size: 20px;
    font-weight: 600;
    //padding-bottom: 1rem;//
    font-family: Montserrat;
    text-align: center;
}
.delivered-buisness{
  padding: 2rem;
  margin: auto;
  display: block;
  text-align: center;
}
.delivered-buisness p{
  padding-bottom: 1rem;
  font-size: 18px;
}

@media only screen and (min-width: 280px) and (max-width: 767px) {
  .data-drive-succ{
  
    font-size: 20px
      
  }

.delivered-buisness{
  font-size: 14px
}
}