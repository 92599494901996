.mainss-section{
    height: 460px;
    width: 250px;
    color: white;
   
    
    
    border: 2px solid black;
    background-color: black;
   

}
@media(min-width: 2100px) and (max-width:3000px){
    .abc{
        display: flex;
        justify-content: space-evenly;
       
    }
   .carous{
    padding-left: 130px;
    padding-right: 130px;
   }

  

}
@media(min-width: 900px) and (max-width:2000px){
    .abc{
        display: flex;
        justify-content: space-evenly;
       
    }
   .carous{
    padding-left: 90px;
    padding-right: 90px;
   }
    

}
@media(min-width: 600px) and (max-width:900px){
    .abc{
        display: flex;
        justify-content: space-evenly;
       
    }
   .carous{
    padding-left: 80px;
    padding-right: 80px;
   }

//    img{
//     height: 130px !important;
//     width: 130px !important;
//    }
    
    

}

  
  
  


.mainssimagess{
    height: 100% !important; 
    width: 100%;
   object-fit: cover; 
    border-radius: 50%;
   

    
}
.mass{
    height: 209px;
    margin-top: 29px;
}
.mainsss-imagess{
    
   margin: 0 auto;
   
  
   height: 130px !important ;
   width: 130px !important;
   //overflow: hidden;// 

  .mainssimagess{
     height: 130px !important ;
    width: 130px !important;

    }
   
  
  
   
   
    
   

}
.section-name{
    padding-left: 18px;
    padding-top: 30px;
    font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;

    

    
}
.section-role{
    padding-left: 18px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    

    

    
}

.section-border{
    margin-top: 1px;
    border: 1px solid #FD00FF;
    margin-bottom: 4px;
}
.sections-cont{
    font-size: 12px;
    padding-left: 12px;
    padding-right: 10px;
    padding-top: 10px;

}

.link-wrapper {
    position: relative;
    display: inline-block;
    top: 20px;
    left: 15px;
    cursor: pointer;
    width: 24px; 
    height: 24px; 
  }
  
  .link-wrapper img {
    display: block;
    width: 100%;
    height: auto;
    transition: opacity 0.3s ease;
  }
  
  .pink-img {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  
  .link-wrapper:hover .normal-img {
    opacity: 0;
  }
  
  .link-wrapper:hover .pink-img {
    opacity: 1;
  }

  .custom-dot-list-style {
    position: absolute;
   
    margin-bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
   
   
  }
  



 

  
 
  