.newsroom-reuse-body {
  position: relative;

  .newsroom-reuse-main {
    width: 260px;

    .newsroom-reuse-section {
      .newsroom-reuse-box {
        min-height: 389px;
        padding-bottom: 2rem;

        h2 {
          font-size: 15px;
          text-align: center;
          background-color: #bb1ccc;
          color: white;
          padding: 5px 0px;
        }
        h3 {
          font-size: 24px;
          text-align: left;
          padding: 0px 20px;
          color: white;
          padding-bottom: 10px;
          padding-top: 10px;
        }
        p {
          text-align: left;
          padding: 0px 20px 0px 20px;
          font-size: 18px;

          color: white;
        }
      }
      .news-btn-read-more {
        display: grid;
        justify-content: center;
        padding-top: 1rem;
        margin-bottom: 1rem;
      }
      .news-btn-read-more > div {
        background-color: #bb1ccc;
        font-weight: 600;
        padding: 8px;
        font-weight: 600;
        padding-left: 20px;
        padding-right: 20px;

        a {
          font-size: 17px;
        }
      }
    }
  }
}

.newsroom-section2-body {
  background-color: black;
  padding: 0 0 3rem;
  padding-top: 0px;

  h1 {
    background-color: black;
    margin: 0;
    color: white;
    text-align: center;
    padding: 2rem 0px;
    font-size: 30px;
    padding-bottom: 4rem;
  }
  .newsroom-section2-main {
    display: flex;
    justify-content: center;
    grid-gap: 4rem;
  }
}

.newsroom-section3-body {
  background-color: black;
  color: white;
  text-align: center;

  .newsroom-section3-main {
    background-color: #212121;
    h1 {
      margin: 0;
      font-size: 40px;
      padding: 2rem 0;
    }

    .newsroom-section3-firstBox {
      display: flex;
      justify-content: center;
      grid-gap: 20px;
      flex-wrap: wrap;
      padding: 0 0 2rem;
    }
  }
}

.newsroom-section4-body {
  background-color: black;
  color: white;
  text-align: center;

  .newsroom-section4-main {
    padding: 10rem;
    h1 {
      margin: 0;
      font-size: 40px;
      padding: 4rem 0;
    }
  }
}

.text_overFlow {
  display: -webkit-box;

  -webkit-box-orient: vertical;

  -webkit-line-clamp: 5;

  height: auto;

  overflow: hidden;
}

.section2-new-css {
  display: flex;
  grid-gap: 10px;
  justify-content: center;
  position: relative;
  padding: 3.5rem 0;
  gap: 3rem;

  .icon-box {
    height: 160px;
    width: 163px;
    display: grid;
    justify-content: center;
    justify-items: center;
    gap: 20px;
    align-items: flex-start;
    align-content: baseline;

    .image-div {
      color: white;
      text-align: center;
      display: grid;
      justify-content: center;
      border-radius: 100%;
      align-items: center;
      align-content: center;
      width: 120px;
      height: 120px;
      border: 2px solid #eee;

      &:hover {
        background-color: white;
        cursor: pointer;
        transition: 2s;
      }

      .feature-icon {
        width: 70px;
        height: 70px;
      }
    }
    .text {
      color: white;
      text-align: center;
      display: grid;
      justify-content: center;
      font-size: 16px;
    }
  }
}

.newsroom-section2-body {
  padding-top: 0px !important;
}

#newsroom-section5 {
  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    height: auto;
  }

  .read-more-icon {
    svg {
      path {
        fill: white;
      }
    }

    &:hover {
      svg {
        path {
          fill: #bb1ccc;
        }
      }
    }
  }

  .content-box-section2a {
    min-height: 150px;
    max-height: 150px;
    span {
      font-size: 16px;
    }
  }
}
