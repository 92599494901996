/* .landing-page {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
} */

.background-video{
   width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.hexagon-container {
  position: absolute;
  width: 170px; /* Adjust the size of the hexagon */
  height: 200px; /* Adjust the size of the hexagon */
  top: 42%;
  left: 65%;
}

.hexagon {
  position: absolute;
  width: 100%;
  padding-bottom: 115.47%; /* 2:√3 (sqrt(3)) ratio for hexagon */
  background-color: #3498db; /* Hexagon color */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  opacity: 0.2;
}

.hexagon::before {
  content: "";
  position: absolute;
  width: 100%;
  padding-bottom: 115.47%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.4) 0%,
    transparent 60%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  animation: glowing 2s infinite;
}

@keyframes glowing {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
}
