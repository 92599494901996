.bottom-banner {
  background-color: black;

  .body {
    margin: auto;
    width: 90%;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;

    .main {
      height: 180px;
      // width: 875px;
      color: white;

      .heading {
        font-size: 30px;
        line-height: 36px;
        position: relative;
        z-index: 1;
        font-weight: bold;
        text-align: center;
      }
      .content {
        font-size: 18px;
        line-height: 21px;
        margin: 2rem 0 3rem;
        position: relative;
        z-index: 1;
        text-align: center;
      }
      .button-wrapper {
        text-align: center;
        button {
          width: 190px;
          height: 49px;
          font-size: 20px;
          color: white;
          background-color: #bb1ccc;
          outline: none;
          border: none;
          position: relative;
          z-index: 1;
        }
      }
    }
  }
}

// bottom banner mobile version -->
@media only screen and (min-width: 280px) and (max-width: 767px) {
  .bottom-banner {
    .body {
      .main {
        .heading {
          font-size: 20px;
        }
        .content {
          font-size: 16px;
        }
      }
    }
  }
}

font-family {
  font-family: "Montserrat" !important ;
}

.margin-blogMore {
  display: block !important ;
  margin: auto !important;
  width: 70%;
}

.margin-box {
  display: block !important ;
  margin: auto !important;
  width: 80%;
}

.margin-more {
  display: block !important;
  margin: auto !important;
  width: 80% !important;
}

.link-page {
  text-decoration: none;
  color: white;
}

.heading {
  font-size: 40px;
}
.subHeading {
  font-size: 18px;
}

body {
  overflow-x: hidden !important;
}

// .App {
//   overflow-y: hidden;
//   overflow-x: hidden;
//   font-family: "Montserrat";
// }

html {
  scroll-behavior: smooth;
}

.backround-black {
  background-color: black;
  min-height: 100vh;
}

.sticky-buttons {
  position: sticky;
  top: 0;
  background-color: #000000; /* Adjust as needed */
  z-index: 1000; /* Ensure buttons appear above other content */
  // padding: 10px 0;
}

.button-box {
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  justify-content: space-around;
  padding: 1.5rem 0;
}

.container a {
  text-decoration: none;
  color: #000000; /* Button text color */
  transition: background-color 0.3s, color 0.3s;
  margin: 0 10px;
}

.container a:hover,
.container a.active {
  // background-color: red;
  /* Hover and active state background color */
  button {
    background-color: #bb1ccc;
    color: #ffffff; /* Hover and active state text color */
  }
}

.container button {
  width: 160px;
  height: 50px;
  // border-radius: 30px;
  font-family: "Montserrat" !important ;
  font-size: 14px;
  outline: none;
  border: none;
  font-weight: bold;
  background-color: #212121; /* Button default background color */
  color: rgb(167, 163, 163); /* Button default text color */
  cursor: pointer;
  white-space: nowrap; /* Ensure line breaks are respected */
}

.container button:hover {
  background-color: #bb1ccc; /* Button hover background color */
  color: #ffffff; /* Button hover text color */
}

.company-logos-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .career-body .career-section2 .lastspans{
    width: 100% !important;
  }
}
.joinTeam{
  text-align: center!important;
}