.footer{
    padding: 2rem;
    background-color: #212121;
    position: relative;
    display: grid;
    grid-gap: 20px;
}
.footerCountry{
    display: flex;
    grid-gap: 10px;
    font-size: 24px;
    justify-content: center;
    font-weight: 600;
    color: white;
    flex-wrap: wrap;
    flex: 49%;
}
.footerMenu{
    display: flex;
    grid-gap: 30px;
    font-size: 16px;
    justify-content: center;
    font-weight: 600;
    color: white;
    flex-wrap: wrap;
    flex: 49%;


}
.copyRights{
    display: flex;
    font-size: 14px;
    justify-content: center;
    font-weight: 600;
    color: white;
    background-color: #bb1ccc;
    padding: 1rem;
    position: relative;

}

.withBorder {
    border-right: 2px solid gray; /* Add your desired border style here */
    padding-right: 10px;
  }
  
  .footerCountry span:last-child {
    border-right: none; /* Remove the right border for the last element */
  }