.test-animation-mobile {
  .main-container-mobile {
    .grid-container-mobile {
      display: grid;
      height: 100vh;
      grid-template-rows: 60% 40%;
      position: relative;

      .top-mobile {
        display: flex;
        justify-content: center;
        position: relative;

        img {
          position: absolute;
          width: 70%;
          object-fit: fill;
          animation: top 3s 1;
          top: 200px;
        }
        @keyframes top {
          0% {
            top: 0px;
          }
          100% {
            top: 200px;
          }
        }
      }

      .bottom-mobile {
        margin-top: 0rem;
        text-align: left;
        position: relative;

        .main-bottom {
          width: 90%;
          margin: auto;
          color: white;
          opacity: 1;
          animation: fade 3s 1;          
          .heading-mobile {
            font-size: 24px;
            font-weight: bold;
          }
          .subHeading-mobile {
            font-size: 14px;
            margin-top: 0.5rem;
          }

          @keyframes fade {
            0% {
              opacity: 0;
            }
            30% {
              opacity: 0.3;
            }
            70% {
              opacity: 0.7;
            }
            100% {
              opacity: 1;
            }
          }
        }

        .grid-image {
          img {
            width: auto;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 400px !important;
          }
        }
      }
    }
  }
}
.hedingnewsection{
  font-size: 20px !important;
}