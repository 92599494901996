.section2Left{
    background-color: transparent;
    grid-template-columns: repeat(2, 50%);
    display: grid;
    justify-content: center;

}
.section3{
    grid-template-columns: repeat(2,50%);
    align-items: center;
    align-content: center;
    padding-top: 6rem;
    padding-bottom: 6rem;

}
.section2Left1{
    padding: 1rem;
    border-left: none;

}
.section3-div1{
    border-bottom: 4px solid #bb1ccc;
    height: 120px;
    align-items: center;
    align-content: center;
    display: grid;
    justify-content: start;
    grid-column: 1;


}
.section3-div1 .section3Number{
    /* color: #bb1ccc; */
    text-align: left;
}
.section3Number{
    font-size: 40px;
    font-weight: bolder;
    font-family: Arial, Helvetica, sans-serif;
    color: lightgray;
}
.section3Text{
    font-size: 28px;
    text-align: left;
    color: white;

}
.section3-div2{
    border-left: 4px solid #bb1ccc;
    height: 190px;
    display: grid;
    align-items: center;
    align-content: center;
}

.section3-div4{
    border-top: 4px solid #bb1ccc;
    border-left: 4px solid #bb1ccc;
    align-items: center;
    align-content: center;
    display: grid;
    height: 140px;


}
.section3-div3{
    align-items: baseline;
    align-content: baseline;
    display: grid;

}
.section3Number:hover{
    color: #bb1ccc;
    cursor: pointer;
}
.marginBoth{
    width: 95%;
    margin: auto;
    display: block;
}
.mainBody{
    background-color: #212121;
    z-index: 1;
    position: relative;

}
.section3SliderImageWrapper{
    height: 70px;
    width: 70px;
    background-color: black;
    display: grid;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 5px;
    border-radius: 6px;
}
.secetion3Slider >div{
    padding: 1rem;
  }
  .secetion3Slider{
    display: grid;
    grid-gap: 20px;
    align-items: start;
    align-content: start;
  }
  .section3SliderText{
    display: grid;
    align-items: center;
    align-content: center;
    color: white;

  }

  /* .section3Slider{
    height: 600px;
  } */
  





/* Add this CSS to your component's CSS file or a global stylesheet */
.secetion3Slider > div {
  /* animation: scroll 5s linear infinite;  */
  overflow: hidden;
  
}

.section3RightScreen{
    height: 350px;
    overflow: hidden;
}
.secetion3Slider{
    overflow: hidden;
}

@keyframes scroll {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}


.marginTeams{
   
    width: 80%;
    margin: auto;
    display: block;

}

.spinner-border {
    display: inline-block;
    width: 10px;
    height: 10px;
    vertical-align: text-bottom;
    border: 0.2em solid currentColor;
    border-right: 0.2em solid transparent;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
  }
  
  @keyframes spinner-border {
    to {
      transform: rotate(360deg);
    }
  }
  
.AaysRecognize{
  width: 100%;
    background-color: rgb(187, 28, 204);
    position: fixed;
    z-index: 99999;
    bottom: 0px;
    display: grid;
    align-content: center;
    align-items: center;
    height: 60px;
    box-shadow: rgba(255, 255, 255, 0.24) 0px 3px 8px

}

.AaysRecognize2{
  display: flex;
    place-content: center space-between;
    align-items: center;
    color: white;
    height: 100%;
   padding-left: 2.8rem;
   padding-right: 2.8rem;

}
.AaysRecognize3{
  display: grid;
    /* gap: 10px; */
    align-items: center;
    place-content: center flex-start;
    color: white;
    height: 100%;
}
.AaysRecognize4{
  width: 700px;
}

@media screen and (max-width: 768px) {
  .AaysRecognize {
    height: auto;
  }

  .AaysRecognize2 {
    text-align: left;
    padding: 1rem;
    font-size: 14px !important;
    grid-gap: 1rem;
  }

  .AaysRecognize3 {
    justify-content: center;
  }

  .AaysRecognize4 {
    width: 500px;
    max-width: 100%;
  }
}

.article__title, .section__title {
  font-size: 30px;
  padding-bottom: 1rem;
}

.article__desc ul{
  display: grid !important;
  grid-gap: 10px !important;

}

.mobileFontSize{
  font-size: 18px;
}

#successSvg svg{
  color: #bb1ccc !important;
}

#successSvg .MuiAlert-message{
  color: black;
}

.activeNavLink{
  /* color: #bb1ccc !important; */
  /* font-weight: bold !important; */
  /* font-family: montserrat !important; */
}

img {
  pointer-events: none !important;   /* Disables all pointer events on the image */
  -webkit-user-select: none !important;  /* Disables text selection in Webkit browsers */
  -webkit-touch-callout: none !important;  /* Disables the callout menu on iOS */
  -moz-user-select: none !important;  /* Disables text selection in Firefox */
  -ms-user-select: none !important;  /* Disables text selection in IE/Edge */
  user-select: none !important;  /* Disables text selection in standard-compliant browsers */
}


