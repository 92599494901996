.customer-section1-main {
  background-color: #212121;
  position: relative;
}

.customer-section1-text {
  font-size: 18px;
  color: white;
  font-weight: 600;
}

.section1-flex-icon {
  display: flex;
  justify-content: center;
  gap: 10%;
  flex-wrap: wrap;
}

.icon-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;

  img {
    width: 100px;
  }
}

.feature-icon {
  width: 60px;
}

.success-story-page-section-2 {
  background-color: black;
  color: white;
  padding-bottom: 2rem;

  .success-story-page-section-2-Header-text {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 4rem;
  }

  .success-story-page-section-2-grid {
    padding-top: 2rem;
    padding-bottom: 2rem;

    .success-story-page-section-2-grid-items {
      margin-right: 5px;
    }
    .success-story-page-section-2-grid-items-text {
      text-align: center;
      padding: 3rem;
      font-size: 24px;
      font-weight: 600;
      background-color: #212121;
    }
    .btn-read-more {
      display: grid;
      justify-content: center;
      padding-top: 2rem;
      margin-bottom: 10rem;
    }
    .btn-read-more > div {
      background-color: #bb1ccc;
      padding: 8px;
      // border-radius: 6px;
      font-weight: 600;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .success-story-page-section-2-margin {
    margin: auto;
    width: 80%;
    display: block;
  }
}

.success-story-page-section-3 {
  background-color: black;
  color: white;
  padding-bottom: 2rem;
  padding-top: 2rem;

  .success-story-page-section-3-Header-text {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 1.5rem;
    padding-top: 2rem;
  }

  .success-story-page-section-3-grid {
    grid-template-columns: repeat(2, 45%);
    grid-gap: 10px;
    display: grid;
    justify-content: center;
    padding-top: 2rem;

    .success-story-page-section-3-grid-items {
      display: grid;
      justify-content: center;
      img {
        object-fit: contain;
        width: 100%;
      }
    }

    .success-story-page-section-3-grid-items-texta {
      text-align: center;
      padding: 2rem 1rem;
      font-size: 24px;
      // font-weight: 500;
      background-color: black;
      max-height: 145px;
      min-height: 145px;
    }

    .success-story-page-section-3-grid-items-text {
      text-align: center;
      padding: 3rem;
      font-size: 24px;
      font-weight: 600;
      background-color: black;
      // height:   100px;
    }
    .btn-read-more {
      display: grid;
      justify-content: center;
      padding: 1rem;
    }
    .btn-read-more > div {
      background-color: #bb1ccc;
      padding: 8px;
      // border-radius: 6px;
      font-weight: 600;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .success-story-page-section-3-margin {
    margin: auto;
    width: 80%;
    display: block;
    background-color: #212121;
    position: relative;

    .content {
      width: 90%;
      margin: auto;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0.2px;
      line-height: 24px;
      margin-bottom: 1rem;
    }
  }
}

.success-story-page-section-4 {
  background-color: black;
  color: white;
  padding: 3.3rem 0 0rem;

  .success-story-page-section-4-Header-text {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 1.5rem;
    padding-top: 1rem;
  }

  .success-story-page-section-4-grid {
    grid-template-columns: repeat(3, 24%);
    grid-gap: 20px;
    position: relative;
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;

    .success-story-page-section-4-grid-items {
      display: grid;
      justify-content: center;
      margin-right: 5px;
      width: 280px;
    }
  }

  .success-story-page-section-4-grid-items-text {
    text-align: left;
    padding: 10px 6px 4px 10px;
    font-size: 18px;
    font-weight: 600;
    background-color: #212121;
    min-height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .success-story-page-section-4-margin {
    margin: auto;
    width: 80%;
    display: block;

    .content {
      width: 80%;
      margin: auto;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0.2px;
      line-height: 24px;
      margin-bottom: 1rem;
    }
  }
}

.success-story-page-section-5 {
  background-color: black;
  color: white;
  padding-bottom: 2rem;
  padding-top: 6rem;

  .success-story-page-section-5-Header-text {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 2rem;
  }

  .success-story-page-section-5-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 20px;
    padding-top: 2rem;
    padding-bottom: 2rem;

    .success-story-page-section-5-grid-items {
      display: grid;
      justify-content: center;
      margin-right: 10px;
      position: relative;

      img {
        height: 200px;
      }
    }
    .success-story-page-section-5-grid-items-text {
      text-align: center;
      padding: 3rem;
      font-size: 24px;
      font-weight: 600;
      background-color: #212121;
    }
    .btn-read-more {
      display: grid;
      justify-content: center;
      padding-top: 1rem;
      margin-bottom: 3rem;
      position: relative;
    }
    .btn-read-more > div {
      background-color: #bb1ccc;
      padding: 8px;
      font-weight: 600;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .success-story-page-section-5-margin {
    margin: auto;
    width: 80%;
    display: block;
  }
}

.customer-success-section2 {
  background-color: black;
  color: white;

  .success-story-page-section-2-grid {
    grid-gap: 40px;
    padding: 3rem 0;
    display: flex;
    justify-content: center;
  }
}

.customer-success-section3 {
  background-color: black;
  color: white;

  .reuseSectionBox {
    width: 280px;
    display: grid;

    img {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }

    .content-box-section3 {
      background-color: #212121;
      min-height: 190px;
      margin-top: 0 !important;

      span {
        text-align: left;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        margin-top: 0 !important;
        overflow: hidden;
      }
    }
    .content-box-lifeaa {
      background-color: #212121;
      min-height: 160px;
      margin-top: 0 !important;

      span {
        text-align: left;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        margin-top: 0 !important;
        overflow: hidden;
      }
    }
  }

  .success-story-page-section-3-grid {
    grid-gap: 12px;
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
  }
}

.reuseSectionBox {
  width: 265px;
  display: grid;
  img {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .content-box-section2 {
    background-color: #212121;
    min-height: 185px;
    margin-top: 0 !important;

    span {
      text-align: left;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 18px;
      margin-top: 0 !important;
      overflow: hidden;
    }
  }
}

.customer-section2-body {
  padding: 3rem 0 0rem 0 !important;
  z-index: 0;
  position: relative;

  .content {
    width: 80%;
    margin: auto;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.2px;
    line-height: 22px;
  }
}

.customer-section3-body {
  padding: 6rem 0 0rem 0 !important;
  z-index: 0;
  position: relative;

  .content {
    width: 80%;
    margin: auto;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.2px;
    line-height: 24px;
    margin-bottom: 1rem;
  }
}

.section2-box {
  display: block;
  margin: auto;
}


.react-multi-carousel-item,
.react-multi-carousel-item--active {
  display: flex !important;
  justify-content: center !important;
}

#caseStudySection4 .react-multi-carousel-list {
  height: auto !important;
}
#section2-customer-success-story .react-multi-carousel-list {
  height: auto !important;
}
#section5-customer-success-story .react-multi-carousel-list {
  height: auto !important;
}

.seeMore {
  display: grid;
  justify-content: center;
  padding-bottom: 2rem;
  font-size: 22px;
  color: #bb1ccc;
  font-weight: 600;
  cursor: pointer;
}

.success-story-page-section-2-Header-text {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 1.5rem;
}

.success-story-page-section-3-Header-text {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 1.5rem;
}

.Blogs-Thought {
  padding-bottom: 0rem;
}

.reuseSectionBox:hover {
  .reuseBoxHover {
    background-color: #bb1ccc;
  }
  
}

.content-box-section2a {
  font-size: 18px !important;
  height: auto;
  overflow: hidden;
  background-color: #212121;
  min-height: 130px;
  max-height: 130px;
  display: grid;

  span {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-weight: 500;
    font-size: 18px;
    margin-top: 0 !important;
    overflow: hidden;
  }
}

.success-stories-section2-new-css {
  display: flex;
  grid-gap: 10px;
  justify-content: center;
  position: relative;
  background-color: black;
  padding: 3.5rem 0;
  gap: 3rem;

  .success-stories-section1-text {
    font-size: 16px;
    font-weight: 500;
    color: white;
  }
  .icon-box {
    display: grid;
    justify-content: center;
    justify-items: center;
    gap: 20px;
    align-items: flex-start;
    align-content: baseline;

    .image-div {
      color: white;
      text-align: center;
      display: grid;
      justify-content: center;
      border-radius: 100%;
      align-items: center;
      align-content: center;
      width: 120px;
      height: 120px;
      border: 2px solid #eee;

      &:hover {
        background-color: #bb1ccc;
        cursor: pointer;
        transition: 2s;
      }

      .feature-icon {
        width: 70px;
        height: 70px;
      }
    }
    .text {
      color: white;
      text-align: center;
      display: grid;
      justify-content: center;
      font-size: 16px;
    }
  }
}

#section2-customer-success-story {
  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    height: auto;
  }

  .read-more-icon {
    svg {
      path {
        fill: white;
      }
    }

    &:hover {
      svg {
        path {
          fill: #bb1ccc;
        }
      }
    }
  }
}

#caseStudySection3 {
  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    height: auto;
  }

  .read-more-icon {
    svg {
      path {
        fill: white;
      }
    }

    &:hover {
      svg {
        path {
          fill: #bb1ccc;
        }
      }
    }
  }
}

#caseStudySection4 {
  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    height: auto;
  }

  .read-more-icon {
    svg {
      path {
        fill: white;
      }
    }

    &:hover {
      svg {
        path {
          fill: #bb1ccc;
        }
      }
    }
  }
}

#section5-customer-success-story {
  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    height: auto;
  }

  .read-more-icon {
    svg {
      path {
        fill: white;
      }
    }

    &:hover {
      svg {
        path {
          fill: #bb1ccc;
        }
      }
    }
  }
}

#section2-blog-success-story {
  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    height: auto;
  }

  .read-more-icon {
    svg {
      path {
        fill: white;
      }
    }

    &:hover {
      svg {
        path {
          fill: #bb1ccc;
        }
      }
    }
  }

  .content-box-section2a {
    min-height: 120px;
    max-height: 120px;
    span {
      font-size: 14px;
    }
  }

  .new-cls-blogs {
    grid-gap: 1rem;
  }
}

#section4-blog-success-story {
  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    height: auto;
  }

  .read-more-icon {
    svg {
      path {
        fill: white;
      }
    }

    &:hover {
      svg {
        path {
          fill: #bb1ccc;
        }
      }
    }
  }

  .content-box-section2a {
    min-height: 125px;
    max-height: 125px;
    span {
      font-size: 14px;
    }
  }

  .new-cls-blogs {
    grid-gap: 1rem;
  }
}

#lifeaays {
  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    height: auto;
  }

  .read-more-icon {
    svg {
      path {
        fill: white;
      }
    }

    &:hover {
      svg {
        path {
          fill: #bb1ccc;
        }
      }
    }
  }

  .content-box-section2a {
    min-height: 111px;
    max-height: 111px;
    span {
      font-size: 14px;
    }
  }

  .new-cls-blogs {
    grid-gap: 1rem;
  }
}

#aiAndGeneratives{
  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    height: auto;
  }

  .read-more-icon {
    svg {
      path {
        fill: white;
      }
    }

    &:hover {
      svg {
        path {
          fill: #bb1ccc;
        }
      }
    }
  }

  .content-box-section2a {
    min-height: 130px;
    max-height: 130px;
    span {
      font-size: 14px;
    }
  }

  .new-cls-blogs {
    grid-gap: 1rem;
  }
}

#supplychainss{
  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    height: auto;
  }

  .read-more-icon {
    svg {
      path {
        fill: white;
      }
    }

    &:hover {
      svg {
        path {
          fill: #bb1ccc;
        }
      }
    }
  }

  .content-box-section2a {
    min-height: 111px;
    max-height: 111px;
    span {
      font-size: 14px;
    }
  }

  .new-cls-blogs {
    grid-gap: 1rem;
  }
}

#section5-blog-success-story {
  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    height: auto;
  }

  .read-more-icon {
    svg {
      path {
        fill: white;
      }
    }

    &:hover {
      svg {
        path {
          fill: #bb1ccc;
        }
      }
    }
  }

  .content-box-section2a {
    min-height: 136px;
    max-height: 136px;
    span {
      font-size: 14px;
    }
  }

  .new-cls-blogs {
    grid-gap: 1rem;
  }
}
.container a.active button {
  background-color: #bb1ccc; /* Pink color for active state */
  color: #ffffff;
}



#recentpost{
  .dateprops{
    color: black;
  }
  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    height: auto;
  }

  .read-more-icon {
    svg {
      path {
        fill: white;
        
      }
    }

    &:hover {
      svg {
        path {
          fill: #000000;
        }
      }
    }
  }

  .content-box-section2a {
    min-height: 135px;
    max-height: 111px;
    span {
      font-size: 14px;
    }
  }

  // .new-cls-blogs {
  //   grid-gap: 1rem;
  // }
}


#meet {
  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    height: auto;
  }

  .read-more-icon {
    svg {
      path {
        fill: white;
      }
    }

    &:hover {
      svg {
        path {
          fill: #bb1ccc;
        }
      }
    }
  }

  .content-box-section2a {
    min-height: 111px;
    max-height: 111px;
    span {
      font-size: 14px;
    }
  }

  .new-cls-blogs {
    grid-gap: 1rem;
  }
}





