#aadi-main {
  position: relative;
  font-family: Montserrat;



  .aadi-section1 {
    height: 100vh;
    width: 100%;
    // background-color: black;
    // z-index: 9999;
    background: url("../../assets/img/Aadi/Banner\ .gif") no-repeat center;
    background-size: cover;
    position: relative;

    .main {
      padding: 40px 0 0rem 0;

      .flex-container {
        display: flex;
        justify-content: center;

        .left {
          width: 55%;
          min-height: 90vh;
          display: flex;
          justify-content: center;
          align-items: center;
          .contentBox {
            // height: 300px;
            height: auto;
            width: 509px;
            color: white;
            // padding: 1rem;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            // align-items: center;
            // background-color: #bb1ccc;

            .text {
              position: relative;
              z-index: 1;
              .content {
                position: relative;
                z-index: 1;
                .aadi-name {
                  font-size: 80px !important;
                  font-family: "Homenaje", sans-serif;
                  font-weight: 400;
                }
                font-size: 32px;
                font-family: Montserrat;
                line-height: 40px;
              }
            }
            .buttons {
              margin-top: 36px;
              width: 452px;
              display: flex;
              justify-content: space-between;

              button {
                position: relative;
                z-index: 1;
                height: 51px;
                width: 194px;
                border: 1px solid #bb1ccc;
                background: transparent;
                color: white;
                font-size: 20px;
                font-family: Montserrat;

                &:first-child {
                  background: #bb1ccc;
                }
              }
              button:hover{
                background-color: #bb1ccc;
              }
            }
          }
        }
        .right {
          width: 45%;
          height: 100%;
          // display: none;
          // background-color: pink;
          .image {
            display: none;
            // display: flex;
            justify-content: flex-end;
            align-items: center;
            img {
              width: 800px;
              // width: 400px;
            }
          }
        }
      }
    }
  }

  .aadi-section2 {
    background-color: #040404;
    height: 100vh;

    .main {
      display: block;
      margin: auto;
      width: 90%;
      .flex-container {
        display: flex;
        justify-content: center;
        .left {
          min-height: 100vh;
          position: relative;
          z-index: 1;
          width: 45%;
          display: flex;
          justify-content: center;
          // align-items: center;
          .image {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 400px;
              // height: 6px;
            }
          }
        }
        .right {
          width: 55%;
          min-height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
          // padding: 0 66px 0 0;

          .heading {
            span {
              font-size: 40px;
              font-weight: 700;
              color: #fa00ff;
              font-family: Montserrat;
            }
            font-size: 80px;
            font-family: Homenaje;
            font-weight: 400;
            color: white;
            position: relative;
            z-index: 1;
          }
          .subHeading {
            font-size: 30px;
            font-weight: 600;
            color: white;
            margin-bottom: 20px;
            margin-top: 4px;
            line-height: 36.57px;
            position: relative;
            z-index: 1;
          }
          .text {
            font-size: 18px;
            font-weight: 400;
            color: #ffffff;
            line-height: 21.94px;
            position: relative;
            z-index: 1;
          }
        }
      }
    }
  }

  .aadi-newSection {
    height: auto;
    width: 100%;
    background-color: black;
    padding-bottom: 8rem;

    // .body {
    //   width: 100%;
    //   .main {
    //     padding: 4rem 0 0rem 0;
    //     width: 80%;
    //     display: block;
    //     margin: auto;

    //     .heading {
    //       text-align: center;
    //       font-size: 30px;
    //       color: #fa00ff;
    //       font-weight: 700;
    //       line-height: 36.57px;
    //       // position: sticky;
    //       // top: 100px;
    //     }
    //     .subHeading {
    //       text-align: center;
    //       font-size: 30px;
    //       color: #ffffff;
    //       font-weight: 700;
    //       line-height: 36.57px;
    //     }
    //     .text1 {
    //       font-size: 18px;
    //       font-weight: 400;
    //       line-height: 21.94px;
    //       text-align: center;
    //       color: white;
    //       padding: 15px 0px;
    //     }
    //   }
    //   .boxes-main {
    //     width: 80%;
    //     display: block;
    //     margin: auto;
    //     padding-bottom: 4rem;

    //     .boxes {
    //       display: flex;
    //       gap: 2rem;
    //       justify-content: center;
    //       // margin-top: 51px;
    //       width: 100%;
    //     }
    //   }
    // }

    .main {
      padding: 1rem 0 1rem 0;
      width: 80%;
      display: block;
      margin: auto;

      .heading {
        text-align: center;
        font-size: 30px;
        color: #fa00ff;
        font-weight: 700;
        line-height: 36.57px;
        position: relative;
        z-index: 2;
        // position: sticky;
        // top: 100px;
      }
      .subHeading {
        text-align: center;
        font-size: 30px;
        color: #ffffff;
        font-weight: 700;
        line-height: 36.57px;
        position: relative;
        z-index: 2;
      }
      .text1 {
        font-size: 18px;
        font-weight: 400;
        line-height: 21.94px;
        text-align: center;
        color: white;
        padding: 15px 0px;
        position: relative;
        z-index: 2;
      }

      img {
        padding-top: 3rem;
        width: 100%;
      }
    }
  }

  // .sticky-buttons {
  //   position: sticky;
  //   top: 75px;
  //   z-index: 2;
  //   background-color: black;
  //   padding: 10px 0 0 0;

  //   .button-box {
  //     height: auto;
  //     width: 70%;
  //     display: block;
  //     // justify-content: center;
  //     margin: auto;

  //     .container {
  //       display: flex;
  //       justify-content: center;
  //       gap: 2rem;
  //       padding: 0;
  //       padding-bottom: 22px;

  //       a {
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;

  //         button {
  //           text-align: center;
  //           // width: 269px;
  //           // height: 72px;
  //           font-family: Montserrat;
  //           border-top: 3px solid white;
  //           font-size: 16px;
  //           // font-size: 18px;
  //           outline: none;
  //           border: none;

  //           font-weight: bold;
  //           background:transparent;
  //           color: rgb(167, 163, 163);
  //           transition: background-color 0.3s ease, color 0.3s ease; /* Add transition for smooth effect */
  //         }

  //         &:hover {
  //           button {
  //             // background-color: #bb1ccc;
  //             border-bottom: 2px solid white;
  //             font-weight: bold;
  //             color: white;
  //           }
  //         }

  //         &.active {
  //           button {
  //             // background-color: #bb1ccc;
  //             border-bottom: 2px solid white;
  //             font-weight: bold;
  //             color: white;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  .aadi-section3 {
    height: auto;
    width: 100%;
    background-color: black;
    background-image: url("../../assets/img/Aadi/Background.2-01.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 8rem;
    .main {
      margin: auto;
      display: block;
      width: 85%;
      padding: 100px 0 0 0;

      .heading {
        position: relative;
        z-index: 2;
        font-size: 30px;
        color: white;
        font-weight: bold;
        text-align: center;
        // padding-bottom: 10px;
      }

      .text {
        color: white;
        font-size: 18px;
        width: 100%;
        text-align: center;
        position: relative;
        z-index: 1;
        // border-bottom: 3px solid #f000f2;
        padding: 8px 0 45px 0;
        display: block;
        width: 90%;
        margin: auto;
      }

      .flex-container {
        display: flex;
        height: 70vh;
        width: 100%;
        justify-content: center;
        gap: 2rem;

        .left {
          width: 401px;
          height: auto;

          .accordian-file {
            width: 401px;
            padding-top: 10px;
          }
        }
        .right {
          width: 65%;
          display: flex;
          justify-content: flex-start;
          width: 682px;
          height: 498px;
          // border: 2px solid #fa00ff;
          border-radius: 43px;
          overflow: hidden;

          // img {
          //   width: 100%;
          //   border-radius: 43px;
          //   padding: 2px;
          // }
          .custom-video::-webkit-media-controls {
            display: none !important;
          }

          .custom-video::-moz-media-controls {
            display: none !important;
          }

          .custom-video {
            outline: none;
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 43px;
            aspect-ratio: 16/4;
          }
        }
      }
    }
  }
  .aadi-section4 {
    min-height: auto;
    width: 100%;
    background-color: black;
    background-image: url("../../assets/img/Aadi/Background.3-01.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 8rem;

    .main {
      margin: auto;
      display: block;
      width: 85%;
      padding: 138px 0px 0px 0px;
      .heading {
        position: relative;
        z-index: 2;
        font-size: 30px;
        color: white;
        font-weight: bold;
        text-align: center;
        // padding-bottom: 10px;
      }

      .text {
        color: white;
        font-size: 18px;
        width: 100%;
        text-align: center;
        position: relative;
        z-index: 1;
        // border-bottom: 3px solid #f000f2;
        padding: 8px 0 45px 0;
        display: block;
        width: 90%;
        margin: auto;
      }

      .flex-container {
        display: flex;
        height: 70vh;
        width: 100%;
        justify-content: center;
        gap: 2rem;

        .left {
          width: 401px;
          height: auto;

          .accordian-file {
            width: 401px;
            padding-top: 10px;
          }
        }
        .right {
          width: 65%;
          display: flex;
          justify-content: flex-start;
          width: 677px;
          height: 498px;
          border: 2px solid #fa00ff;
          border-radius: 23px;
          overflow: hidden;
          background-color: #121112;

          img {
            width: 100%;
            border-radius: 43px;
            padding: 2px;
            object-fit: contain;
          }

          // background-color: white;
          // img {
          //   width: 90%;
          //   // height: 100%;
          // }
        }
      }
    }
  }
  .aadi-section5 {
    min-height: auto;
    width: 100%;
    background-color: black;
    background-image: url("../../assets/img/Aadi/Background.2-01.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 8rem;

    .main {
      margin: auto;
      display: block;
      width: 85%;
      padding: 138px 0 0 0;

      .heading {
        position: relative;
        z-index: 2;
        font-size: 30px;
        color: white;
        font-weight: bold;
        text-align: center;
        // padding-bottom: 10px;
      }

      .text {
        color: white;
        font-size: 18px;
        width: 100%;
        text-align: center;
        position: relative;
        z-index: 1;
        // border-bottom: 3px solid #f000f2;
        padding: 8px 0 45px 0;
        display: block;
        width: 90%;
        margin: auto;
      }

      .flex-container {
        display: flex;
        height: 70vh;
        width: 100%;
        justify-content: center;
        gap: 2rem;

        .left {
          width: 401px;
          height: auto;

          .accordian-file {
            width: 401px;
            padding-top: 10px;
          }
        }
        .right {
          width: 65%;
          display: flex;
          justify-content: flex-start;
          width: 677px;
          height: 498px;
          // border: 2px solid #fa00ff;
          // border-radius:23px;

          img {
            width: 100%;
            // border-radius: 43px;
            // padding: 2px;
            // object-fit: cover;
          }
        }
      }
    }
  }

  .aadi-section7 {
    height: 100vh;
    width: 100%;
    background-color: black;
    padding-bottom: 8rem;
    background-image: url("../../assets/img/Aadi/im.png");
    background-size: contain;
    background-repeat: no-repeat;
    .main {
      padding: 2rem 0 0rem 0;
      color: white;
      .heading {
        font-size: 30px;
        text-align: center;
        padding-top: 4rem;
        position: relative;
        z-index: 1;

        .pink {
          color: #bb1ccc;
        }
        .bold {
          font-weight: bold;
        }
      }
      .flex-container {
        display: flex;
        // background-color: #212121;
        height: 80vh;

        .left,
        .right {
          width: 50%;
          // background-image: url("../../assets/img/Aadi/Badge\ Background-01.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position-x: center;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;

          .badge-box {
            width: 60%;
            .image {
              img {
                width: 130px;
              }
            }
            .badge-heading {
              font-size: 30px;
              font-weight: bold;
              padding: 0.5rem 0;
            }
            .content {
              padding-top: 2rem;
              position: relative;
              z-index: 1;
            }
            .read-btn {
              margin-top: 1rem;
              display: grid;
              justify-content: center;
              position: relative;
              z-index: 1;

              button {
                background-color: #bb1ccc;
                padding: 8px;
                font-weight: 600;
                border: none;
                font-size: 12px;
                padding: 8px 16px;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }

  .aadi-section8 {
    height: auto;
    background-color: black;
    padding-bottom: 8rem;
  }
  .aadi-section9 {
    min-height: auto;
    background-color: black;
    color: white;
    .main {
      padding: 4rem 0 1rem 0;
      // text-align: center;
      .heading {
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        position: relative;
        z-index: 1;
      }
      .content {
        text-align: center;
        display: flex;
        justify-content: center;
        padding: 1rem 0;
        .text {
          width: 60%;
          font-size: 20px;
        }
      }
      .flex-container {
        display: flex;
        // height: 70vh;
        // background-color: #212121;
        width: 100%;

        .left,
        .right {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          .accordian-file {
            width: 80%;
            height: 90%;
            // overflow-y: scroll;
            .accordian-title {
              font-weight: normal;

            }
            .accordian-description{
              font-size: 16px;
            }
          }
        }
        // .right {
        //   width: 50%;
        //   display: flex;
        //   justify-content: center;
        //   // background-color: white;
        //   img {
        //     width: 70%;
        //     // height: 100%;
        //   }
        // }
      }
    }
  }
}



.MuiModal-root{
  z-index: 999999 !important;
  }