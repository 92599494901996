.applicatio {
  display: flex;
  align-items: stretch;
  padding-top: 65px; 
  
  //  height: 500px; 
//height: 100vh;
//height: 80vh;
  
  width: 80%;
  margin: auto;

 
}
.main-mainss{
//  background-color: #212121;
background-color: black;
  color: white;
  width: 100%;
  //padding-bottom: 4rem;
  padding-top: 4rem;
  position: relative;
 
}

.images-images {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%; 
}

.imging {
  
  max-width: 70%;
 // height: 70% !important; 
  object-fit: cover; 
}
.textss {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  line-height: 1.2;
  height: 80%; 
  margin: auto;
}

.heades {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.market{
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 1.5rem;
  padding-top: 2rem;
}
.clasname{
  width: 66%;
  margin: auto;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.2px;
  line-height: 24px;
  margin-bottom: 1rem;
}

.header-para {
  font-size: 16px;
}
.btns-btn{
  background: #bb1ccc 0% 0% no-repeat padding-box;
 
 
 padding: 10px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin-top: 1rem;
  cursor: pointer;
  position: relative;
}


@media (max-width: 1000px) {
  .applicatio {
    flex-direction: column; 
    align-items: center;
    background-color: #212121;
    padding-top: 0px;
   // height: 60vh;
  }
  .clasname{
    display: none;
  }
  

  .images-images {
    margin-right: 0;
    margin-bottom: 20px; 
  }

  .imging {
    max-width: 100%; 
  }

  .textss {
    text-align: center;
  }
 
}
@media only screen and (min-width: 280px) and (max-width: 767px) {
  .main-mainss{
    padding: 1.5rem 0;
  }
  .market{
    font-size: 20px;
    font-weight: 600;
  }
}