.GenAi-body {
  background-color: white;
  padding-top: 5rem;
  padding-bottom: 1rem;

  .GenAi-main {
    padding-top: 2rem;
    padding-bottom: 3rem;
    display: block;
    // border:1px solid #201d1d38;

      .main-heading {
      color: black;
      font-size: 30px;
     padding-top: 1rem;
    }
    .main-headss{
      color: black;
      font-size: 20px;
    }
    .bullets{
      padding: 1rem 0;
      color: black;
      ul {
        li {
          font-size: 20px;
          padding-top: 1rem;
          b{
            color: black !important;
          }
        }
      }
    }
    .flex-containersi{
      display: flex;
    }
    .leftssi{
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 50%;
    }
    .leftscontainers{
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .rightssi{
      align-items: center;
      display: flex;
      justify-content: flex-end;
      width: 50%;
    }
    .accordian-filessi{
      height: 90%;
      width: 95%;
    }
   
    .heading {
        color: #bb1ccc;
        font-size: 24px;
      }

     .sub-heading{
        color: black;
        font-size: 20px;
     }

      .heading-content {
        color: black;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        // font-weight: 100;
      }
  

    .blogImg {
      padding-top: 20px;
      padding-bottom: 3rem;
     //height: 500px;
    }

    .imgContent {
      

      .middle-image {
        width: 60%;
        display: block;
        // margin: auto;

        img {
          height: auto;
        }
      }
    }
  }
}


.newclas, .cntheading{
  color: #404040 !important;
  overflow: hidden;
}
.GenAi-body .GenAi-main .newclase{
  color: #404040 !important;
}

// .GenAi-body .GenAi-main .heading-content
.heading-heading{
  font-size: 24px;
 color: black!important;
 //color: #404040 !important;
  padding-top: 2rem;
}
.dingead{
  color: black!important;
  font-size:18px !important;
  font-weight:600;
}
.liclass{
  font-weight: 500;
  line-height: 1.5;
  font-size: 14px;
}
.anchor{
  color:#1155cc
}

.flexteamaays{
  display:flex;
  //color:black !important;
  gap: 5px;
  align-items: center;
}
.imagi{
  height: 30px !important;
  width: 38px !important;
}


@media (max-width: 768px) {
  .flexteamaays{
    flex-direction: row;
  }
}


// @media (max-width: 480px) {
//   .flexteamaays {
//     display: flex;
//     flex-direction: column;
//   }

//   .cntheading {
//     display: block;
//     margin-right: 0; /* Remove margin for better alignment on small screens */
//   }
// }



@media (max-width: 480px) {
  .sixja {
    display: block;
    margin-right: 0;
  }
}
.fontweight{
  font-weight: 400!important;
  color: #404040 !important;
}
.fontweights{
  font-weight: 500;
  color: #404040 !important;

}
.hadingthree{
  font-size: 22px !important;
  color: #404040 !important;

}
.headingfour{
  font-size: 18px!important;
  color: #404040 !important;

}
.mainheaders{
  font-size: 40px!important;
}
.headings-headingss{
font-size: 28px !important;
color: #404040 !important;
font-weight: 500!important;

}
.italic-para{
  font-size:14px;
  color:'#434343';
  font-style:italic;
}
.heading-italic{
  font-size: 24px;
  color: rgb(67, 67, 67);
  font-weight: 400;
  margin-left: 3rem;
}
@media (max-width: 749px) {
  .heading-italic{
font-size: 20px;
  }
  .border-genai{
    border: none!important;
    }
    .margin-blogMore{
      width: 98%!important;
    }
    .new-box-seventy{
      width: 90% !important;
    }
    
}
.padingtop{
  margin-top: 1rem;
}
.border-genai{
border: 0.5px solid#dddddd;
padding-bottom: 2rem;
}
.GenAi-body img.link-iconss {
  height: 20px !important;
}

.mrgintop{
  margin-top: 2rem;
}