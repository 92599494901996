.ourTeamsMaincls {
  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    height: 100%;
  }

  .sub-heading {
    width: 90%;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin: auto;
    color: white;
    padding: 2rem;
  }
 .sub-heading p{
  padding-bottom: 1rem;
 }
  .know-more-btn {
    display: grid;
    justify-content: center;
    margin-top: 1.5rem;
    position: relative;
  }

  .know-more-btn > a > button {
    background-color: #bb1ccc;
    padding: 8px;
    font-weight: 600;
    border: none;
    font-size: 16px;
    padding: 8px 16px;
    color: #ffffff;
  }

  .contaner {
    display: flex;
    height: 450px;
    width: 90%;
    margin: auto;
   
    .item {
      flex: 1 1 100%;

      & + .item {
        padding-left: 10px;
      }

      &-flex {
        display: flex;
        flex-direction: column;

        img + img {
          margin-top: 10px;
        }
      }

      &-flex-img {
        height: 40%;
      }

      &-flex-img-big {
        height: 60%;
      }
    }
  }
}
