@media only screen and (min-width: 768px) and (max-width: 1024px) {
  // top icons of customer success story page
  .success-stories-section2-new-css {
    grid-template-columns: repeat(3, 28%) !important;
  }

  // career page section 2
  .career-section2-new {
    height: auto !important;
  }

  // about page Ipad Responsive view
  .about-main {
    .about-section4 {
      background-color: #212121;
      margin: auto;
      width: 100%;
      padding-bottom: 5rem;

      .grid-box {
        border-radius: 30px;
        padding: 1.5rem;
        width: 80%;
        margin: auto;
      }

      .header-text {
        width: 80%;
        font-size: 30px;
        color: white;
        text-align: center;
        font-weight: 700;
        margin: auto;
        margin-bottom: 2rem;
      }

      .grid-item {
        //   align-items: start;
        display: grid !important;
        grid-template-columns: none !important;
        grid-gap: 2rem;
        margin-top: 1rem;
        padding: 1rem;
        text-align: center;

        .grid-item-content {
          margin-left: 1.5rem;
          padding-top: 1rem;
        }

        .grid-name-content {
          display: grid;
          justify-content: center;

          .grid-item-name {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 16px;
            text-align: center;
          }

          .grid-item-role {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 16px;
            text-align: center;
          }
        }

        .image-div-container {
          display: flex;
          justify-content: center;
          .image-div {
            width: 50%;
            display: flex;
            align-items: center;
            box-shadow: 14px 14px 0px #bb1ccc;
          }
        }

        .content {
          font-size: 18px;
          font-family: helvetica !important;
          line-height: 26px;
          letter-spacing: 0.2px;
          text-align: left;
        }
      }

      @media only screen and (min-width: 1025px) {
        .react-multi-carousel-list {
          height: 440px !important;
          display: flex;
          align-items: start;
        }
      }
    }

    .about-section3 {
      .third-section {
        padding: 1rem !important;
        background-color: #212121;

        .third-section-box {
          background-color: black !important;
          --accent-color: #bb1ccc;

          box-shadow: inset 4px 0px 0px 0px var(--accent-color);
          padding: 1rem;
          margin: 0.8rem 0 !important;
          width: 100%;
        }
      }

      // .fourth-section {
      //   min-height: auto !important;
      //   margin-bottom: 2rem;

      //   .header-text {
      //     color: white;
      //     font-size: 20px !important;
      //     text-align: left !important;
      //     padding-bottom: 0rem !important;
      //   }

      //   .fourth-section-grid {
      //     display: flex !important;
      //     flex-direction: column;
      //     grid-template-columns: none !important;

      //     .fourth-section-item-box {
      //       border-right: none !important;
      //       border-bottom: 4px solid #bb1ccc;
      //       padding: 24px 0 !important;
      //       text-align: left !important;

      //       .item-content {
      //         font-size: 14px;
      //         color: white;
      //         font-weight: 500;
      //         line-height: 20px;
      //         letter-spacing: 0.2px;
      //       }

      //       &:last-child {
      //         border-bottom: none;
      //       }
      //     }
      //   }
      // }

      .fourth-section {
        width: 90% !important;
        min-height: auto !important;
        padding-bottom: 4rem;

        .fourth-section-grid .fourth-section-item-box .item-content {
          font-size: 14px !important;
        }
      }
    }
  }

  
}
