#keypoints{
    padding-bottom: 2rem;

    .top{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        font-weight: 500;
        line-height: 1.5;

        .image{
            border: 1px solid white;
            // margin-left: -20px;
            background-color: #bb1ccc;
            height: 55px;
            width: 55px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            
           img{
            height: 40px;
            // width: 30px;
            object-fit: contain;
           }
           .img1{
            height: 35px;
            object-fit: contain;
           }
        }
        .heading-line{
            gap: 5px !important;

            .heading{
                font-size: 18px !important;
            }
            .line{
                width: 200px;
                height: 2px;
                background-color: #bb1ccc;
            }
        }

    }
    .bottom{
        padding-left: 70px;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.5;
        // margin-top: px;
    }
}