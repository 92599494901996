.logo{
    height: auto;
    color: white;
    width: 130px;
   }
   .MuiAppBar-root{
    /* padding: 2rem !important; */
    position: fixed !important;
    z-index: 999 !important;
    top: 0px !important;
   }
   body{
    margin: auto !important;
   }
   #nav-id{
    padding: 0px;
    padding-top: 0px ;
   }
   
   #nav-id div {
    padding-top: 5px;
    padding-bottom: 5px;
   }
   