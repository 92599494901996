
.blog-animation-container {
    
    height: calc(100vh - 58px);
    background-image: url('/src/Components/assets/img/test-animation-background.webp');
    
    background-size: cover;
    background-position: center;    
    /* Center the content */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Ensures content stays within bounds */
    width: 100%;
    text-align: center;
}
.carousel-wrapper-container {
    width: 80%;
    position: relative;
    height: 500px; /* Adjust based on content */

}


/* Left Side - Text Section */
.text-sectiona {
    flex: 1;
    padding: 20px;
    text-align: left;
}

/* Make text centered vertically */
.text-sectiona h2 {
    font-size: 35px;
    /* margin-bottom: 10px; */
    margin-bottom: -5px;
}

.read-morebutton {
    display: inline-block;
    padding: 10px 20px;
    background: #bb1ccc;
    color: #fff;
    text-decoration: none;
    margin-top: 30px;
    cursor: pointer;
    font-size: 16px;
    font-family:  Montserrat;
    border: none;
    font-weight: bold;
}

/* Right Side - Image Section */
.image-sectionone {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  

}

.image-sectionone img {
    /* max-width: 100%; */
   height: 460px;
   width: 360px;
    border-radius: 10px;
    box-shadow: 5px 10px white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.itemText{
    color:#fff
}
.blog-animation-container .custom-dot-list-style {
    position: absolute;
    margin-bottom: -2px;
    left: 8%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
}


/* Apply styles for fade effect */


.carousel-itemsss {
    opacity: 0;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-radius: 10px;
    height: 100%;
}

.carousel-itemsss.active {
    opacity: 1;
    position: relative;
}


/* Smoothly bring back the first slide */





/* Dots Section */
.custom-dots {
    display: flex;
    justify-content: flex-start; 
    margin-top: 5rem; 
    margin-left: 1rem;
}

.dot {
    width: 10px;
    position: relative;
    height: 10px;
    margin: 0 5px;
    background-color: #ccc;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.active-dot {
    background-color: #F000F2; 
    transform: scale(1.2);
}

/* Positioning for Arrows */

/* Positioning for Arrows */
.lefticonarrow, .righticonarrow {
    position: relative; 
    bottom: -25x; 
    font-size: 35px; 
    color: #000; 
    z-index: 10; 
    cursor: pointer;
    color:#fff;
}

/* Adjust left arrow position */
.lefticonarrow {
    right: -27rem;
}

/* Adjust right arrow position */
.righticonarrow {
    right: -28rem; 
}

/* Make sure they are visible and fixed across screen sizes */




.btmbnner{
    height: 400px;
}
.btmbnner img {
    animation: bottom 3s 1;
    bottom: 0;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    width: 100%;
}

@media screen and (max-width: 762px) {
    .lefticonarrow, .righticonarrow {
        display: none;
    }

    .blogspage-cls .image-sectionone img {
       /* height: 300px !important;  Reduce height on mobile */
       
        max-width: 75% !important;
    }

    .carousel-wrapper-container {
        width: 95%; /* Adjust width for better fit */
    }

    .text-sectiona h2 {
        font-size: 16px; /* Smaller text for better fit */
        text-align: center;
    }

    .read-morebutton {
        font-size: 14px;
        padding: 8px 16px;
        margin-top: 2rem;
    }

    .custom-dots {
        justify-content: center;
        margin-top: 2rem;
        z-index: 2; /* Higher than the background */
    position: relative; /* Ensure z-index works */
    }

    .carousel-itemsss {
        flex-direction: column;
        text-align: center;
    }
   
    
    .image-sectionone {
        order: -1; ;
    }
    .text-sectiona {
       
        text-align: center;
    }
    
    
}
@media screen and (min-width: 762px) and (max-width: 1080px) {
    .text-sectiona h2 {
        font-size: 24px; /* Smaller text for better fit */
        /* text-align: center; */
    }
   
}
