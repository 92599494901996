.blogMore-body{
  background-color: black;
  .blogMoreLayout-main {
    padding-top: 10rem;
    padding-bottom: 3rem;
    display: block;
    // background-color: #000000;
    .heading{
      color: white;
    }
    .textHeading{
      color: #bb1ccc;
    }
    .subHeadingText{
      color: white;
  
    }
    .heading-content-points{
      font-size: 22px;
    }
    
    .flexRight {
      p{
      color: white;
  
    }
    .blog-date-time{
      span{
        color: white !important;
      }
    }
  }
  
  
    h1 {
      font-size: 30px;
    }
  
    p{
      margin: 0;
      // font-weight: 600;
    }
  
    .blogFlexContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      grid-gap: 10px;
  
      img {
        width: 50px;
        border-radius: 100%;
      }
  
      .flexRight {
        p {
          margin: 0;
          font-weight: 600;
        }
        .blog-date-time {
          display: flex;
          grid-gap: 10px;
          span {
            font-size: 14px;
            color: gray;
            font-weight: 600;
          }
          div {
            display: flex;
            grid-gap: 2px;
  
            align-items: center;
            align-content: center;
  
            .timeIcon {
              font-size: 15px;
              color: gray;
            }
          }
        }
      }
    }
  
    .blogImg{
      padding-top: 20px;
      height: 500px;
    }
  
    .imgContent{
      h6{
          font-size: 15px;
      }
    }
  }
}
